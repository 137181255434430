import React, { useState, useEffect } from "react";
import styles from "./DetailedBreakDown.module.css";
import { CircleMinus, CirclePlus } from "lucide-react";
import StackedBarGraph from "../StackedBarGraph/StackedBarGraph";
import Table from "../Table/Table";

const DetailGraph = ({
  activeTab,
  investmentAmount,
  expectedReturns,
  investmentYears,
}) => {
  const [toggle, setToggle] = useState(true);
  const [graphData, setGraphData] = useState({ labels: [], datasets: [] });
  const [tableData, setTableData] = useState([]);

  const calculateCompoundInterest = (P, r, t, n = 1) => {
    return P * Math.pow(1 + r / (n * 100), n * t);
  };

  useEffect(() => {
    // Reset graph and table data when active tab changes
    setGraphData({ labels: [], datasets: [] });
    setTableData([]);
  }, [activeTab]);

  useEffect(() => {
    const labels = [];
    const totalInvestmentData = [];
    const interestEarnedData = [];
    const tableData = [];

    let principal = investmentAmount;
    let accumulatedAmount = 0;
    let totalInvested = 0;

    for (let year = 1; year <= investmentYears; year++) {
      labels.push(`${new Date().getFullYear() + year - 1}`);
      if (activeTab === 2) {
        // Lumpsum calculation
        accumulatedAmount = calculateCompoundInterest(
          principal,
          expectedReturns,
          year
        );
        totalInvested = principal;
      } else {
        // Monthly SIP calculation
        totalInvested = principal * 12 * year;
        accumulatedAmount = Array.from({ length: 12 * year }).reduce(
          (acc, _, monthIndex) =>
            acc +
            calculateCompoundInterest(
              investmentAmount,
              expectedReturns,
              (12 * year - monthIndex) / 12
            ),
          0
        );
      }
      totalInvestmentData.push(totalInvested.toFixed(2));
      interestEarnedData.push((accumulatedAmount - totalInvested).toFixed(2));
      tableData.push({
        year: new Date().getFullYear() + year - 1,
        totalInvestment: totalInvested.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        }),
        interestEarned: (accumulatedAmount - totalInvested).toLocaleString(
          "en-IN",
          {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          }
        ),
        finalAmount: accumulatedAmount.toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        }),
      });
    }

    setGraphData({
      labels,
      datasets: [
        {
          label: "Total Investment",
          data: totalInvestmentData,
          backgroundColor: "#41558d",
        },
        {
          label: "Interest Earned",
          data: interestEarnedData,
          backgroundColor: "#82b770",
        },
      ],
    });

    setTableData(tableData);
  }, [investmentAmount, expectedReturns, investmentYears, activeTab]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        ticks: {
          stepSize: 8,
          callback: function (value) {
            return value.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
            });
          },
        },
      },
    },
  };

  return (
    <div className={styles.DetailedBreakdownContainer}>
      <div>
        {toggle ? (
          <CircleMinus onClick={() => setToggle(!toggle)} />
        ) : (
          <CirclePlus onClick={() => setToggle(!toggle)} />
        )}
        <p className={styles.Div2Title}>Detailed Investment Breakdown</p>
      </div>

      {toggle && (
        <>
          <div className={styles.graphDiv}>
            <div className={styles.whatInGraph}>
              <div>
                <div className={styles.colorBox1}></div>
                <p>Total Investment</p>
              </div>

              <div>
                <div className={styles.colorBox2}></div>
                <p>Interest Earned</p>
              </div>
            </div>
            <div style={{ marginTop: "48px" }}>
              <StackedBarGraph data={graphData} options={options} />
            </div>
          </div>

          <div
            style={{
              marginTop: "48px",
              maxHeight: "262px",
              overflowY: "scroll",
            }}
          >
            <Table data={tableData} />
          </div>
        </>
      )}
    </div>
  );
};

export default DetailGraph;
