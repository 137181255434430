import React, { useState, useEffect } from "react";
import styles from "./DetailedBreakDown.module.css";
import { CircleMinus, CirclePlus } from "lucide-react";
import StackedBarGraph from "../StackedBarGraph/StackedBarGraph";
import Table from "../Table/Table";

const DetailGraph = ({
  investmentAmount = 0, // Default value of 0
  expectedReturns = 0, // Default value of 0
  investmentYears = 0, // Default value of 0
  activeTab,
}) => {
  const [toggle, setToggle] = useState(true);
  const [sipData, setSipData] = useState([]);
  const [lumpSumData, setLumpSumData] = useState([]);

  useEffect(() => {
    const calculateSIP = () => {
      const data = [];
      const monthlyInterestRate = expectedReturns / 100 / 12;

      for (let year = 1; year <= investmentYears; year++) {
        const months = year * 12;
        const totalInvestment = Number(investmentAmount) * months;

        if (monthlyInterestRate > 0) {
          const totalValue =
            Number(investmentAmount) *
            ((Math.pow(1 + monthlyInterestRate, months) - 1) /
              monthlyInterestRate) *
            (1 + monthlyInterestRate);
          const interestEarned = totalValue - totalInvestment;

          data.push({
            year: new Date().getFullYear() + year - 1,
            totalInvestment: parseFloat(totalInvestment.toFixed(2)) || 0,
            interestEarned: parseFloat(interestEarned.toFixed(2)) || 0,
            totalValue: parseFloat(totalValue.toFixed(2)) || 0,
          });
        } else {
          data.push({
            year: new Date().getFullYear() + year - 1,
            totalInvestment: parseFloat(totalInvestment.toFixed(2)) || 0,
            interestEarned: 0,
            totalValue: parseFloat(totalInvestment.toFixed(2)) || 0,
          });
        }
      }

      setSipData(data);
    };

    const calculateLumpSum = () => {
      const data = [];
      const yearlyInterestRate = expectedReturns / 100;

      for (let year = 1; year <= investmentYears; year++) {
        const totalInvestment = Number(investmentAmount);
        const totalValue =
          yearlyInterestRate > 0
            ? totalInvestment * Math.pow(1 + yearlyInterestRate, year)
            : totalInvestment;
        const interestEarned = totalValue - totalInvestment;

        data.push({
          year: new Date().getFullYear() + year - 1,
          totalInvestment: parseFloat(totalInvestment.toFixed(2)) || 0,
          interestEarned: parseFloat(interestEarned.toFixed(2)) || 0,
          totalValue: parseFloat(totalValue.toFixed(2)) || 0,
        });
      }

      setLumpSumData(data);
    };

    calculateSIP();
    calculateLumpSum();
  }, [investmentAmount, expectedReturns, investmentYears]);

  const data = activeTab === 1 ? sipData : lumpSumData;
  const labels = data.map((item) => `${item.year}`);
  const totalInvestmentData = data.map((item) => item.totalInvestment);
  const interestEarnedData = data.map((item) => item.interestEarned);

  const graphData = {
    labels,
    datasets: [
      {
        label: "Total Investment",
        data: totalInvestmentData,
        backgroundColor: "#41558d",
      },
      {
        label: "Interest Earned",
        data: interestEarnedData,
        backgroundColor: "#82b770",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value;
          },
        },
      },
    },
  };

  return (
    <div className={styles.DetailedBreakdownContainer}>
      <div>
        {toggle ? (
          <CircleMinus onClick={() => setToggle(!toggle)} />
        ) : (
          <CirclePlus onClick={() => setToggle(!toggle)} />
        )}
        <p className={styles.Div2Title}>Detailed Investment Breakdown</p>
      </div>

      {toggle && (
        <>
          <div className={styles.graphDiv}>
            <div className={styles.whatInGraph}>
              <div>
                <div className={styles.colorBox1}></div>
                <p>Total Investment</p>
              </div>

              <div>
                <div className={styles.colorBox2}></div>
                <p>Interest Earned</p>
              </div>
            </div>
            <div style={{ marginTop: "48px" }}>
              <StackedBarGraph data={graphData} options={options} />
            </div>
          </div>

          {data.length > 0 && (
            <div
              style={{
                marginTop: "48px",
                maxHeight: "262px",
                overflowY: "scroll",
              }}
            >
              <Table data={data} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DetailGraph;
