import React, { useState, useEffect } from "react";
import styles from "./DetailedBreakDown.module.css";
import { CircleMinus, CirclePlus } from "lucide-react";
import StackedBarGraph from "../StackedBarGraph/StackedBarGraph";
import Table from "../Table/Table";
import { calculatePPFMaturity } from "../utils/calculatePPFMaturity";

const DetailGraph = ({
  investmentAmount,
  expectedReturns,
  investmentYears,
}) => {
  const [toggle, setToggle] = useState(true);

  // Calculate progressive values
  const [progressiveData, setProgressiveData] = useState([]);

  useEffect(() => {
    const data = [];
    for (let year = 1; year <= investmentYears; year++) {
      const totalAmount = calculatePPFMaturity(
        investmentAmount,
        expectedReturns,
        year
      );
      const interestEarned = totalAmount - investmentAmount * year;
      const currentYear = new Date().getFullYear();
      data.push({
        year: currentYear + year - 1,
        totalInvestment: investmentAmount * year,
        interestEarned,
        totalAmount,
      });
    }
    setProgressiveData(data);
  }, [investmentAmount, expectedReturns, investmentYears]);

  return (
    <div className={styles.DetailedBreakdownContainer}>
      <div>
        {toggle ? (
          <CircleMinus onClick={() => setToggle(!toggle)} />
        ) : (
          <CirclePlus onClick={() => setToggle(!toggle)} />
        )}
        <p className={styles.Div2Title}>Detailed Investment Breakdown</p>
      </div>

      {toggle && (
        <>
          <div className={styles.graphDiv}>
            <div className={styles.whatInGraph}>
              <div>
                <div className={styles.colorBox1}></div>
                <p>Total Investment</p>
              </div>

              <div>
                <div className={styles.colorBox2}></div>
                <p>Interest Earned</p>
              </div>
            </div>
            <div style={{ marginTop: "48px" }}>
              <StackedBarGraph data={progressiveData} />
            </div>
          </div>
          <div
            style={{
              marginTop: "48px",
              maxHeight: "262px",
              overflowY: "scroll",
            }}
          >
            <Table data={progressiveData} />
          </div>
        </>
      )}
    </div>
  );
};

export default DetailGraph;
