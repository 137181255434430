import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Logo from "../assets/common/logo.png";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import clevertap from "clevertap-web-sdk";
import { Menu, X } from "lucide-react";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const location = useLocation();
  // Function to close navbar and scroll to top on link click
  const handleLinkClick = () => {
    setIsOpen(false); // Close navbar on link click (optional)
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
  };

  // Check for screen size on component mount and window resize
  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false); // Close navbar on resize to larger screen (optional)
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className="navbar">
      <div className="nav-container">
        <div className="nav-icon" onClick={toggleMenu}>
          {isOpen ? <X /> : <Menu />}
        </div>
        <Link to="/" className="nav-logo">
          <img
            src={Logo}
            alt="logo"
            className="logo"
            onClick={() => {
              handleLinkClick();
              clevertap.event.push("Clicked Nav Logo", {
                Action: "Home Page",
              });
            }}
          />
        </Link>
        <ul className={isOpen ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              className="nav-link"
              onClick={() => {
                handleLinkClick();
                clevertap.event.push("Clicked Nav Link", {
                  Action: "Home Page",
                });
              }}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/product"
              className="nav-link"
              onClick={() => {
                handleLinkClick();
                clevertap.event.push("Clicked Nav Link", {
                  Action: "Product",
                });
              }}
            >
              Product
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/partnerships"
              className="nav-link"
              onClick={handleLinkClick}
            >
              Partnerships
            </Link>
            {/* <Link
              to="/taxfiling"
              className="nav-link nav-link-gradient"
              onClick={() => {
                handleLinkClick();
                clevertap.event.push("Clicked Nav Link", {
                  Action: "Tax Filing",
                });
              }}
            >
              Tax Filing
            </Link> */}
          </li>
          <li className="nav-item">
            <Link
              to="/about"
              className="nav-link"
              onClick={() => {
                handleLinkClick();
                clevertap.event.push("Clicked Nav Link", {
                  Action: "About Us",
                });
              }}
            >
              Our Story
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/pricing"
              className="nav-link"
              onClick={() => {
                handleLinkClick();
                clevertap.event.push("Clicked Nav Link", {
                  Action: "Pricing",
                });
              }}
            >
              Pricing
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contactus"
              className="nav-link"
              onClick={() => {
                handleLinkClick();
                clevertap.event.push("Clicked Nav Link", {
                  Action: "Contact Us",
                });
              }}
            >
              Get in touch
            </Link>
          </li>
        </ul>
        <div className="cta-container">
          <a href="https://d2jns.app.link/download-fabits">
            <button
              className="primary-cta"
              onClick={() => {
                clevertap.event.push("Clicked Button", {
                  Action: "My Wealth Page",
                });
              }}
            >
              Get started
            </button>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
