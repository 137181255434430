import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./PropsCarousel.css";

export default function PropsCarousel() {
  return (
    <>
      <Swiper slidesPerView={"auto"} spaceBetween={10} className="mySwiper">
        <SwiperSlide>
          <div className="swipper__slide-mobile">
            <img src="/TaxFiling/hand.svg" alt="goal" />
            <div>
              <h3>100% personalised</h3>
              <p className="sub-heading">
                1:1 sessions for tax review & suggestions; no generic stuff
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swipper__slide-mobile">
            <img src="/TaxFiling/mind.svg" alt="goal" />
            <div>
              <h3>Dedicated experts</h3>
              <p className="sub-heading">
                Get a dedicated licensed CA to do your taxes end to end
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swipper__slide-mobile">
            <img src="/TaxFiling/goal.svg" alt="goal" />
            <div>
              <h3>Highly optimised</h3>
              <p className="sub-heading">
                ITR filing to maximise income & reduce tax liability
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swipper__slide-mobile">
            <img src="/TaxFiling/customercare.svg" alt="goal" />
            <div>
              <h3>Post-filing support</h3>
              <p className="sub-heading">
                Reports & support for all things ITR even after filing
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
