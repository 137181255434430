import React, { useState } from "react";
import "./charterAccordian.css";

const CharterAccordian = ({ Data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion-container">
      {Data?.map((item, index) => (
        <div key={index} style={{ margin: "10px 0px" }}>
          <button
            className={`accordion ${activeIndex === index ? "active" : ""}`}
            onClick={() => toggleAccordion(index)}
          >
            <div>{item.title}</div>
            <div style={{ fontSize: "20px" }}>
              {activeIndex === index ? "-" : "+"}
            </div>
          </button>
          <div className={`panel ${activeIndex === index ? "show" : ""}`}>
            <p>{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CharterAccordian;
