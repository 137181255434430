import React, { useState, useEffect } from "react";
import styles from "./DetailedBreakDown.module.css";
import { CircleMinus, CirclePlus } from "lucide-react";
import StackedBarGraph from "../StackedBarGraph/StackedBarGraph";
import Table from "../Table/Table";
import { calculateCompoundInterest } from "../utils/calculateCompoundInterest";

const DetailGraph = ({
  activeTab,
  investmentAmount,
  expectedReturns,
  investmentYears,
}) => {
  const [toggle, setToggle] = useState(true);
  const [graphData, setGraphData] = useState({ labels: [], datasets: [] });
  const [tableData, setTableData] = useState([]);

  console.log(JSON.stringify(tableData, null, 2));

  useEffect(() => {
    const isSIP = activeTab === 1;
    const annualInvestmentAmount = isSIP
      ? investmentAmount * 12
      : investmentAmount;
    const investedFor = 60 - investmentYears;
    const currentYear = new Date().getFullYear();

    const investmentData = [];
    const interestData = [];
    const tableRows = [];
    const years = []; // Array to hold years for graph labels

    for (let i = 1; i <= investedFor; i++) {
      const totalForYear = calculateCompoundInterest(
        isSIP ? annualInvestmentAmount : investmentAmount,
        expectedReturns,
        i,
        isSIP
      );

      const investment = isSIP ? annualInvestmentAmount * i : investmentAmount;
      const interest = Math.max(0, totalForYear - investment);

      const year = currentYear + i - 1;
      tableRows.push({
        year: year,
        investment: Number(investment).toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        }),
        interest: Number(interest).toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        }),
        total: Number(totalForYear).toLocaleString("en-IN", {
          maximumFractionDigits: 2,
          style: "currency",
          currency: "INR",
        }),
      });

      years.push(year); // Collect years
      investmentData.push(investment);
      interestData.push(interest);
    }

    setGraphData({
      labels: years, // Use years as labels
      datasets: [
        {
          label: "Total Investment",
          data: investmentData,
          backgroundColor: "#41558d",
        },
        {
          label: "Interest Earned",
          data: interestData,
          backgroundColor: "#82b770",
        },
      ],
    });

    setTableData(tableRows);

    // Logging the years from tableData to verify
    tableRows.forEach((item, index) => {
      console.log(`Item ${index}:`, item);
    });
  }, [investmentAmount, expectedReturns, investmentYears, activeTab]);

  const maxInterestValue = Math.max(...(graphData.datasets[1]?.data || [0]));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Updated to show the legend
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        max: Math.max(maxInterestValue, 40),
        ticks: {
          stepSize: 8,
          callback: function (value) {
            return value.toLocaleString("en-IN", {
              maximumFractionDigits: 0,
            });
          },
        },
      },
    },
  };

  return (
    <div className={styles.DetailedBreakdownContainer}>
      <div>
        {toggle ? (
          <CircleMinus onClick={() => setToggle(!toggle)} />
        ) : (
          <CirclePlus onClick={() => setToggle(!toggle)} />
        )}
        <p className={styles.Div2Title}>Detailed Investment Breakdown</p>
      </div>

      {toggle && (
        <>
          {graphData.datasets.length > 0 &&
          graphData.datasets[0].data.length > 0 ? (
            <div className={styles.graphDiv}>
              <div className={styles.whatInGraph}>
                <div>
                  <div className={styles.colorBox1}></div>
                  <p>Total Investment</p>
                </div>

                <div>
                  <div className={styles.colorBox2}></div>
                  <p>Interest Earned</p>
                </div>
              </div>
              <div style={{ marginTop: "48px" }}>
                <StackedBarGraph data={graphData} options={options} />
              </div>
            </div>
          ) : (
            <p style={{ marginTop: "48px" }}>
              No data available for the graph.
            </p>
          )}
          <div
            style={{
              marginTop: "48px",
              maxHeight: "262px",
              overflowY: "scroll",
            }}
          >
            <Table data={tableData} />
          </div>
        </>
      )}
    </div>
  );
};

export default DetailGraph;
