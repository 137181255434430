import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale);

const StackedBarGraph = ({ data }) => {
  const chartData = {
    labels: data.map((item) => `${item.year}`),
    datasets: [
      {
        label: "Total Investment",
        data: data.map((item) => item.totalInvestment),
        backgroundColor: "#41558D",
      },
      {
        label: "Interest Earned",
        data: data.map((item) => item.interestEarned),
        backgroundColor: "#82B770",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        ticks: {
          callback: function (value) {
            return value.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
            });
          },
        },
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={options} height={296} />
    </div>
  );
};

export default StackedBarGraph;
