import React, { useState } from "react";
import styles from "./DetailedBreakDown.module.css";
import { CircleMinus, CirclePlus } from "lucide-react";
import StackedBarGraph from "../StackedBarGraph/StackedBarGraph";
import Table from "../Table/Table";
import { calculateRDData } from "../utils/calculateInvestment";

const DetailGraph = ({
  investmentAmount,
  expectedReturns,
  investmentYears,
  totalAmount,
  interestEarned,
  totalInvestment,
}) => {
  const [toggle, setToggle] = useState(true);

  // Calculate RD data based on props
  const { totalInvestment: ti, interestEarned: ie } = calculateRDData(
    investmentAmount,
    expectedReturns,
    investmentYears
  );

  // Generate labels for the chart
  const currentYear = new Date().getFullYear();
  const labels = Array.from(
    { length: investmentYears },
    (_, i) => `${currentYear + i}`
  );

  // Calculate max value for y-axis with padding
  const maxYValue = Math.max(
    ...ti.map((val) => val || 0),
    ...ie.map((val) => val || 0)
  );
  const maxY = maxYValue + maxYValue * 0.1; // 10% padding

  // Data for the chart
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total Investment",
        data: ti.map((val) => Number(val).toFixed(2)), // Format numbers with 2 decimal places
        backgroundColor: "#41558d",
      },
      {
        label: "Interest Earned",
        data: ie.map((val) => Number(val).toFixed(2)), // Format numbers with 2 decimal places
        backgroundColor: "#82b770",
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        max: maxY,
        ticks: {
          stepSize: Math.max(1, Math.ceil(maxY / 10)), // Dynamic step size
          callback: function (value) {
            return Number(value).toLocaleString("en-IN", {
              maximumFractionDigits: 2,
            }); // Format y-axis labels with 2 decimal places and commas
          },
        },
      },
    },
  };

  // Prepare table data
  const tableData = labels.map((label, yearIndex) => {
    const totalValue = (ti[yearIndex] || 0) + (ie[yearIndex] || 0);
    return {
      period: label,
      totalInvestment: (ti[yearIndex] || 0).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
      interestEarned: (ie[yearIndex] || 0).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
      totalValue: totalValue.toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
    };
  });

  console.log(tableData, "sadkjs");

  return (
    <div className={styles.DetailedBreakdownContainer}>
      <div>
        {toggle ? (
          <CircleMinus onClick={() => setToggle(!toggle)} />
        ) : (
          <CirclePlus onClick={() => setToggle(!toggle)} />
        )}
        <p className={styles.Div2Title}>Detailed Investment Breakdown</p>
      </div>

      {toggle && (
        <>
          <div className={styles.graphDiv}>
            <div className={styles.whatInGraph}>
              <div>
                <div className={styles.colorBox1}></div>
                <p>Total Investment</p>
              </div>

              <div>
                <div className={styles.colorBox2}></div>
                <p>Interest Earned</p>
              </div>
            </div>
            <div style={{ marginTop: "48px" }}>
              <StackedBarGraph data={data} options={options} />
            </div>
          </div>

          <div
            style={{
              marginTop: "48px",
              maxHeight: "262px",
              overflowY: "scroll",
            }}
          >
            <Table data={tableData} />
          </div>
        </>
      )}
    </div>
  );
};

export default DetailGraph;
