import React from "react";
import styles from "./InvestmentDetails.module.css";
import Slider from "../Slider/Slider";
import DoughnutChart from "../Doughnut/Doughnut";
import redirectTo from "../../../../utils/RedirectTo";

const InvestmentDetails = ({
  setInvestmentAmount,
  investmentAmount,
  setExpectedReturns,
  expectedReturns,
  setInvestmentYears,
  investmentYears,
  compoundingFrequency = 1, // compounding annually by default
}) => {
  const calculateCompoundInterest = (P, r, t, n = 1) => {
    return P * Math.pow(1 + r / (n * 100), n * t);
  };

  // Ensure investmentAmount is a number
  const investmentAmountNumber = Number(investmentAmount);
  const totalAmount = calculateCompoundInterest(
    investmentAmountNumber,
    expectedReturns,
    investmentYears,
    compoundingFrequency
  );
  const interestEarned = totalAmount - investmentAmountNumber;

  const data = {
    datasets: [
      {
        data: [investmentAmountNumber, interestEarned],
        backgroundColor: ["#41558D", "#82B770"],
        rotation: 90,
      },
    ],
  };

  return (
    <div className={styles.container}>
      <div className={styles.margin_container}>
        <div className={styles.inner_container1}>
          <h1>Investment Details</h1>
          <div style={{ marginTop: "50px" }}>
            <div>
              <p className={styles.sliderTitle}>Principal amount</p>
              <Slider
                endRange={200000}
                startRange={5000}
                price
                step={100}
                onChange={(value) => setInvestmentAmount(Number(value))}
              />
            </div>

            <div style={{ margin: "24px 0px" }}>
              <p className={styles.sliderTitle}>Annual interest rate</p>
              <Slider
                endRange={20}
                startRange={0}
                percentage
                step={1}
                onChange={(value) => setExpectedReturns(Number(value))}
              />
            </div>

            <div>
              <p className={styles.sliderTitle}>Investment period (years)</p>
              <Slider
                endRange={50}
                startRange={1}
                year
                step={1}
                onChange={(value) => setInvestmentYears(Number(value))}
              />
            </div>
          </div>

          <div className={styles.consistency} style={{ marginTop: "24px" }}>
            <p className={styles.spanContent}>
              An investment of{" "}
              <span>
                {investmentAmountNumber.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })}
              </span>{" "}
              for
              <span> {investmentYears}</span> years at an{" "}
              <span>{expectedReturns}%</span> annual interest rate (compounded{" "}
              {compoundingFrequency} times per year) would yield a total amount
              of{" "}
              <span>
                {totalAmount.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })}
              </span>
              .
            </p>
          </div>
        </div>

        <div className={styles.inner_container2}>
          <div className={styles.graph_container}>
            <h1>Investment Breakdown</h1>

            <div className={styles.donut}>
              <DoughnutChart data={data} />
            </div>

            <div className={styles.legend}>
              <div className={styles.l1}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend1}></div>
                  <p className={styles.legendTitle}>Principal amount</p>
                </div>
                <p className={styles.legendPrice}>
                  {investmentAmountNumber.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </div>

              <div className={styles.l2}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend2}></div>
                  <p className={styles.legendTitle}>Interest earned</p>
                </div>
                <p className={styles.legendPrice}>
                  {interestEarned.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </div>
            </div>

            <hr className={styles.horizontalRule} />

            <div className={styles.FinalAmount}>
              <p className={styles.FinalAmountTitle}>Final amount</p>
              <p className={styles.legendPrice}>
                {totalAmount.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })}
              </p>
            </div>
          </div>

          <button
            className={styles.startInvesting}
            onClick={() => redirectTo()}
          >
            <span className={styles.btnContent}>Start investing</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetails;
