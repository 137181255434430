import React, { useEffect } from "react";
import "./module.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation, useNavigate } from "react-router-dom";
import clevertap from "clevertap-web-sdk";

function Product() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    clevertap.event.push("Page Viewed", {
      Page_name: "Product page",
    });
  }, [location.pathname]);
  return (
    <>
      <div className="container">
        <Helmet>
          <title>Fabits - Product</title>
          <meta
            name="description"
            content="Explore Fabits' advanced financial tools and expert advice. Achieve money milestones with AI-driven portfolio management. Secure your financial future effortlessly."
          />
        </Helmet>
        <h2 id="discover">Discover Insights into Your Financial Adventure</h2>
        <p id="belowh2">
          Financial tools and services to help you achieve your money milestones
          and wealth goals
        </p>
        <div className="buttons-container">
          <button
            id="dashboard"
            onClick={() =>
              clevertap.event.push("Clicked Button", {
                Action: "Go to Dashboard",
                Page_name: "Product Page",
              })(
                (window.location.href =
                  "https://mywealth.fabits.com/dashboard/plans")
              )
            }
          >
            Go to Dashboard
          </button>
          <button
            id="learnmore"
            onClick={() => {
              navigate("/taxfiling");
              clevertap.event.push("Clicked Button", {
                Action: "File ITR now",
                Page_name: "Product Page",
              });
            }}
          >
            File ITR now
          </button>
        </div>
        <img src="/Product-page/Group 1.svg" alt="Group 1" id="group1" />
        <img
          src="/Product-page/Group1mobile.svg"
          alt="Group1mobile"
          id="Group1mobile"
        />
        <img src="/Product-page/Group 32.svg" alt="Group 32" id="group32" />
        <img
          src="/Product-page/Group 32 (1).svg"
          alt="Group 32"
          id="group321"
        />
        <img src="/Product-page/Frame.svg" alt="frame" id="frame" />
        <img
          src="/Product-page/Framemobile.svg"
          alt="Framemobile"
          id="Framemobile"
        />

        <img src="/Product-page/Baskets 1.svg" alt="Baskets" id="baskets" />
        <img
          src="/Product-page/Baskets1mobile.svg"
          alt="Baskets1mobile"
          id="Baskets1mobile"
        />
      </div>
      <div className="newcoponentfromthedesign">
        <div className="textsectionrobotsection">
          <h3 className="aiasheedingtext">
            Advanced Investor Assistance System (AIAS)
          </h3>
          <p className="aiasheedingpara1">
            The Advanced Investor Assistance System is built using our
            proprietary deep tech models & algorithms that ensure your portfolio
            doesn’t sink even on the worst market days. Now you can live your
            financial milestones while the AIAS manages market movement &
            volatility for you.
          </p>
          <h3 className="aiasheedingtext1">How does it work?</h3>
          <p className="aiasheedingpara">
            <img
              src="/Partnerships/app_badging.svg"
              alt="app_badging"
              id="app_badging"
            />
            <strong>Systematic Risk Assessment &nbsp; </strong>
            {"  "}
            <p className="paraaferstrongthingproductpage">
              manages risk as per your appetite
            </p>
          </p>
          <p className="aiasheedingpara">
            <img
              src="/Partnerships/app_badging.svg"
              alt="app_badging"
              id="app_badging"
            />
            <strong>Constant allocation monitoring &nbsp; </strong>
            {"  "}
            <p className="paraaferstrongthingproductpage">
              to reduce dips & accrue incremental gains via proprietary quant
              models
            </p>
          </p>
          <p className="aiasheedingpara">
            <img
              src="/Partnerships/app_badging.svg"
              alt="app_badging"
              id="app_badging"
            />
            <strong>Personalisation &nbsp; </strong> {"  "}
            <p className="paraaferstrongthingproductpage">
              because goals aren’t common, why should financial planning be?
            </p>
          </p>
          <a href="https://youtu.be/_nplDtaJVuA" target="_blank" rel="noreferrer">
            <button
              id="learnmore1"
              className="learnmorebuttonaiasarea"
              onClick={() => {
                clevertap.event.push("Clicked Button", {
                  Action: "Learn more",
                  Page_name: "Product Page",
                });
              }}
            >
              Learn more
            </button>
          </a>
        </div>
        <div className="robotsection">
          <img
            src="/Partnerships/robotflyingimage.svg"
            alt="robotflyingimage"
            id="robotflyingimage"
          />
        </div>
      </div>
      <div className="container2">
        <h3 id="comprehensive">A Comprehensive Set of Offerings</h3>
        <img
          src="/Product-page/Vector container2 right.svg"
          alt="Vector container2 right"
          id="Vectorcontainer2right"
        />
        <img
          src="/Product-page/container2designmobile.svg"
          alt="container2designmobile"
          id="container2designmobile"
        />
        <img
          src="/Product-page/container1designmobile.svg"
          alt="container1designmobile"
          id="container1designmobile"
        />
        <img
          src="/Product-page/Vectorcontainer2left.svg"
          alt="Vector container2 left"
          id="Vectorcontainer2left"
        />
        <div className="goalplanningandexpertadvisory">
          <div className="goalplanning">
            <img
              src="/Product-page/Frame 395.svg"
              alt="Group 395"
              id="group395"
            />
            <img
              src="/Product-page/ads_click.svg"
              alt="ads_click"
              id="ads_click"
            />
            <h3 id="goalplanning">Goal Planning</h3>
            <p id="goalplanningtext">
              Craft your financial future with personalized goal planning,
              tailored to your aspirations and dreams
            </p>
          </div>
          <div className="expertadvisory">
            <img
              src="/Product-page/Frame 398.svg"
              alt="Group 398"
              id="group398"
            />
            <img
              src="/Product-page/workspace_premium.svg"
              alt="workspace_premium"
              id="workspace_premium"
            />
            <h3 id="expertadvisory">Expert Advisory</h3>
            <p id="expertadvisorytext">
              Seasoned experts, trusted by High Net Worth Individuals, now bring
              their tailored advice to you
            </p>
          </div>
        </div>
        <div className="ipoandinvestment">
          <div className="ipo">
            <h3 id="ipo">IPO</h3>
            <p id="ipotext">
              Effortlessly explore IPOs—a gateway to dynamic stock offerings.
            </p>
            <img src="/Product-page/Ipoimg.svg" alt="Ipoimg" id="ipoimg" />
            <img
              src="/Product-page/Ipoimgmob.svg"
              alt="Ipoimgmob"
              id="ipoimgmob"
            />
          </div>
          <div className="Insurance">
            <h3 id="Insurance">Insurance</h3>
            <p id="Insurancetext">
              Purchase and monitor insurance easily—empowering your coverage.
            </p>
            <img
              src="/Product-page/investment.png"
              alt="Insuranceimg"
              id="insuranceimg"
            />
            <img
              src="/Product-page/investment.png   "
              alt="Insuranceimgmob"
              id="insuranceimgmob"
            />
          </div>
          <div className="etfbasketmobile">
            <div className="etfbaskettextmobile">
              <h3 id="etfbasketmobile">Readymade ETF Baskets</h3>
              <p id="etfbaskettextmobile">
                Curated options for your financial goals, making investing
                hassle-free.
              </p>
              <img
                src="/Product-page/basketsetf.svg"
                alt="basketsetf"
                id="basketsetfmobile"
              />
              <img
                src="/Product-page/basketsetfmob.svg"
                alt="basketsetfmob"
                id="basketsetfmob"
              />
            </div>
          </div>
          <div className="GoldBondsmob">
            <h3 id="GoldBondsmob">Sovereign Gold Bonds</h3>
            <p id="Goldondsmob">
              Invest in gold bonds easily with fixed rates, government-backed.
            </p>
            <img
              src="/Product-page/goldbonds.svg"
              alt="goldbonds"
              id="goldbondsmob"
            />
            <img
              src="/Product-page/goldbondsmob.svg"
              alt="goldbondsmob"
              id="goldbondsmob1"
            />
          </div>
          {/* This is for mobile only */}
          <div className="signup">
            {/* Traking link */}
            <a href="https://mywealth.fabits.com/sign-up" id="signuplink">
              <button id="signup">
                <img
                  src="/Product-page/getstartedwithbutton.svg"
                  alt="Frame404mobileonly"
                  id="Frame404mobileonly"
                />
              </button>
            </a>
          </div>
        </div>
        <div className="etfbaskets">
          <div className="etfbasket">
            <div className="etfbaskettext">
              <h3 id="etfbasket">Readymade ETF Baskets</h3>
              <p id="etfbaskettext">
                Curated options for your financial goals, making investing
                hassle-free.
              </p>
              <img
                src="/Product-page/basketsetf.svg"
                alt="basketsetf"
                id="basketsetf"
              />
            </div>
          </div>
          <div className="Elevate">
            <h2 id="Elevate">Elevate your finances. Sign up now!</h2>
            <button
              id="getstarted"
              onClick={() => (
                (window.open = "https://mywealth.fabits.com/sign-up"),
                "_blank",
                clevertap.event.push("Clicked Button", {
                  Action: "Get started",
                  Page_name: "Product Page",
                })
              )}
            >
              Get Started
            </button>
          </div>
          <div className="GoldBonds">
            <h3 id="GoldBonds">Sovereign Gold Bonds</h3>
            <p id="Goldonds">
              Invest in gold bonds easily with fixed rates, government-backed.
            </p>
            <img
              src="/Product-page/goldbonds.svg"
              alt="goldbonds"
              id="goldbonds"
            />
          </div>
        </div>
        <div className="signuptab">
          {/* Traking link */}
          <a href="https://mywealth.fabits.com/sign-up" id="signuplinktab">
            <button id="signuptab">
              <img
                src="/Product-page/getstartedwithbutton.svg"
                alt="Frame404mobileonly"
                id="Frame404mobileonlytab"
              />
            </button>
          </a>
        </div>
      </div>
      <div id="greenpart">
        <h3>Get Started in 3 Easy Steps</h3>
        <img src="/Home-page/Group 57.svg" alt="Group57" id="Group57"></img>
        <img src="/Home-page/Group 59.svg" alt="Group59" id="Group59"></img>
        <img src="/Home-page/Frame59.svg" alt="Frame59" id="Frame59"></img>
        <img src="/Home-page/Group 82.svg" alt="Group82" id="Group82"></img>
        <img
          src="/Home-page/Homescreen-animation.gif"
          alt="Rectangle19"
          id="Rectangle191"
          className="Rectangle19productpage"
          loading="lazy"
        ></img>
      </div>
    </>
  );
}

export default Product;
