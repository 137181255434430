import React, { useEffect } from "react";
import "./module.css";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import clevertap from "clevertap-web-sdk";

function Mainpage() {
  const location = useLocation();
  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);

    clevertap.event.push("Page Viewed", {
      Page_name: "Home Page",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Fabits - Home</title>
        <meta
          name="description"
          content="Achieve financial freedom with Fabits. Expert financial planning and deep tech models help you manage money like the rich. Secure your future and enjoy life. Get started today. SEBI registered."
        />
      </Helmet>
      <div className="mainpage">
        <div className="topsection">
          <img src="/Home-page/Circle.svg" alt="Circle" id="Circle-Img" />
          <img src="/Home-page/Circle.svg" alt="Circle" id="Circle-Img1" />
          <img src="/Home-page/Vector.png" alt="Right" id="rightsvg" />
          <img src="/Home-page/Bulbe.svg" alt="Vecto" id="Bulbe" />
          <img src="/Home-page/Tick.svg" alt="Vecto" id="Tick" />
          <img src="/Home-page/Plus.svg" alt="Vecto" id="Plus1" />
          <img src="/Home-page/leftbottem.svg" alt="Vecto" id="leftbottem" />
          <img src="/Home-page/Vector 33.png" alt="Vecto" id="Vector33" />
          <img src="/Home-page/Group.svg" alt="Group" id="Group" />

          <h2 id="First-line-Mobile">Getting rich is super simple...</h2>
          <h2 id="Second-line-Mobile">You just need a plan.</h2>
          <h2 id="Third-line-Mobile">Manage your money like the rich </h2>
          <img src="/Home-page/Center.svg" alt="Center" id="topsvg" />
          <h2 id="First-line">Getting rich is super simple...</h2>
          <h2 id="Second-line">You just need a plan.</h2>
          <p id="Third-line">Manage your money like the rich</p>
          <button
            id="Know-More"
            onClick={() => {
              window.location.href = "https://mywealth.fabits.com/login";
              clevertap.event.push("Clicked Button", {
                Action: "Start now",
                Page_name: "Home Page",
              });
            }}
          >
            Start now
          </button>
        </div>
        <div className="consumers-have">
          <h3 id="indian-consumers">
            80% Indian consumers have experienced improved quality of life after
            building a financial plan for their future
          </h3>
          <img src="/Home-page/Group 93.svg" alt="Group93" id="group93"></img>
        </div>
        <div className="Card-Section">
          <div id="Card">
            <pre contentEditable={true} className="language-css" tabIndex={0}>
              <code className="language-css">
                <div className="token selector" id="Card-title">
                  financial freedom
                </div>
                <div className="token punctuation" id="Latin">
                  / fi-nan-shuhl free-duhm/
                </div>
                <div className="token property" id="noun">
                  noun
                </div>
                <div className="token punctuation" id="Def">
                  <p>
                    the ability to make decisions and live life the way you want
                    to without worrying about monetary income.
                  </p>
                </div>
                <div className="token punctuation"></div>
              </code>
            </pre>
          </div>
          {/* <img src="/Home-page/cash.svg" alt="Cash" id="cash-desktop" /> */}
          {/* <img
            src="/Home-page/cash-mobile.svg"
            alt="cash-mobile"
            id="cash-mobile"
          /> */}
          {/* <img src="/Home-page/puzle.svg" alt="puzle" id="puzle" /> */}
          <p id="Beside-Card">
            Achieving financial freedom is super simple; all you need is a plan.
            <br></br>
            <br></br>
            Financial planning ensures money doesn’t dictate how to react to
            emergencies or live the life of your dreams.
          </p>
          {/* <img src="/Home-page/coin.svg" alt="coin" id="Coin" /> */}
        </div>
        <div id="businessman-area">
          <img
            src="/Home-page/Vector 2.png"
            alt="Green dublicate"
            id="green-dupe"
          ></img>
          <img
            src="/Home-page/green background.svg"
            alt="green background"
            id="green-background"
          />
          <img src="/Home-page/Vector 69.svg" alt="Vector 69" id="Vector69" />
          <img
            src="/Home-page/experienced-businessman-standing-office-room-indian-content-office-employee-eyeglasses-smiling-posing-with-folded-hands-business-management-corporati.svg"
            alt="experienced-businessman"
            id="experienced-businessman"
          />
          <h3 id="Get-Experts">Futuristic planning to plan your future</h3>
          <p id="Get-Experts-para">
            Plan and manage your money using pre-built modules. <br></br>
            <br></br>
            Our proprietary deep tech quant models are built by experts with 25+
            years of experience in managing money for the ultrawealthy
          </p>
          <a href="https://youtu.be/1GATUfsgyN0?si=a-ZvBS56Ri4ShiCM">
            <button
              id="Get-Started"
              onClick={() => {
                clevertap.event.push("Clicked Button", {
                  Action: "Learn more",
                  Page_name: "Main Page",
                });
              }}
            >
              Learn more
            </button>
          </a>
          {/* <img
            src="/Home-page/Group 55.svg"
            id="botten-some-design"
            alt="design"
          ></img> */}
          {/* <img src="/Home-page/Frame.svg" id="Frame" alt="Frame"></img> */}
          {/* <img src="/Home-page/key.svg" id="key" alt="key"></img> */}
        </div>
        <div id="design1">
          {/* <img src="/Home-page/Vector70.svg" id="Vector70" alt="Vector70" /> */}
          {/* <img src="/Home-page/Vector 1.svg" id="Vector1" alt="Vector1" /> */}
          {/* <img src="/Home-page/Frame1.svg" id="Frame1" alt="Frame1" /> */}
          <img
            src="/Home-page/Rectangle 33.svg"
            id="Rectangle33"
            alt="Rectangle33"
          />
          <p id="Design1-para">
            Don’t put a price on the priceless moments of your life
          </p>
          <p id="Design1-para1">
            Plan your money for the significant milestones in your life
          </p>
          <a
            href="https://mywealth.fabits.com/dashboard/plans"
            target="_blank"
            rel="noreferrer"
          >
            <button
              id="Plan-a-goal"
              onClick={() => {
                clevertap.event.push("Clicked Button", {
                  Action: "Plan a milestone",
                  Page_name: "Main Page",
                });
              }}
            >
              Plan a milestone
            </button>
          </a>
          {/* <img src="/Home-page/Vector 71.svg" id="Vector71" alt="Vector71" /> */}
          {/* <img src="/Home-page/Frame 32.svg" alt="Fream 32" id="Fream-32"></img> */}
          {/* <img src="/Home-page/dollor.svg" alt="Dollar" id="dollar"></img> */}
          {/* <img src="/Home-page/plan.svg" alt="plan" id="plan"></img> */}
          {/* <img
            src="/Home-page/cash-mobile.svg"
            alt="cash"
            id="cash-mobile"
          ></img> */}
          {/* <img src="/Home-page/pencil.svg" alt="pencil" id="pencil"></img> */}
          {/* <img src="/Home-page/search.svg" alt="search" id="search"></img> */}
          {/* <img src="/Home-page/coin1.svg" alt="coin1" id="coin1"></img> */}
          {/* <img src="/Home-page/arrow.svg" alt="arrow" id="arrow"></img> */}
        </div>
        <div className="dontlet">
          <h3>
            Dont let emergencies knock you down.<br></br>Secure the most
            fundamental aspects of your life.
          </h3>
          <a
            href="https://mywealth.fabits.com/dashboard/insurance"
            target="_blank"
            rel="noreferrer"
          >
            <button
              id="insurances-button"
              onClick={() => {
                clevertap.event.push("Clicked Button", {
                  Action: "Explore Insurances",
                  Page_name: "Main Page",
                });
              }}
            >
              Explore Insurances
            </button>
          </a>
        </div>
        <div id="Counselling-section">
          <img src="/Home-page/Group 81.svg" alt="group81" id="group81"></img>
          <h3>
            Start planning your money for a comfortable and financially secure
            future
          </h3>
          <a
            href="https://wa.me/+918217801975?text=Hi+I+was+going+through+your+website+and+want+to+book+a+financial+counselling+session+with+Fabits."
            target="_blank"
            rel="noreferrer"
          >
            <button
              id="Counselling-section-button"
              onClick={() => {
                clevertap.event.push("Clicked Button", {
                  Action: "Book a Financial Counselling Session",
                  Page_name: "Main Page",
                });
              }}
            >
              Book a Financial Counselling Session
            </button>
          </a>
        </div>
        <div id="greenpart">
          <h3>Get Started in 3 Easy Steps</h3>
          <img src="/Home-page/Group 57.svg" alt="Group57" id="Group57"></img>
          {/* <img src="/Home-page/Group 59.svg" alt="Group59" id="Group59"></img> */}
          {/* <img src="/Home-page/Frame59.svg" alt="Frame59" id="Frame59"></img> */}
          {/* <img src="/Home-page/Group 82.svg" alt="Group82" id="Group82"></img> */}
          <img
            src="/Home-page/Homescreen-animation.gif"
            alt="Rectangle19"
            id="Rectangle19"
            loading="lazy"
            className="Rectangle19mainpage"
          ></img>
        </div>
        <div>
          <img
            src="/Home-page/Screenshot 2024-01-11 at 10.33 1.svg"
            alt="Supported banks"
            id="Supported-banks"
          />
        </div>
      </div>
    </>
  );
}

export default Mainpage;
