const Application_Software_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Application Software Policy.pdf";
const Audit_Trail_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Audit Trail Policy.pdf";
const Back_up_and_Recovery_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Back-up and Recovery Policy.pdf";
const Client_Code_Modification_Policy_and_Error_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Client Code Modification Policy and Error Policy.pdf";
const Client_Onboarding_Process_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Client_Onboarding_Process_Policy.pdf";
const Cyber_Security_and_Cyber_Resilience_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Cyber Security and Cyber Resilience Policy.pdf";
const Cyber_Incident_Response_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Cyber_Incident_Response_Policy.pdf";
const Data_Classification_and_Protection_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Data Classification and Protection Policy [DCP].pdf";
const Hiring_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Hiring_Policy.pdf";
const Inactive_Account_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Inactive_Account_Policy.pdf";
const Information_Risk_Management_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Information_Risk_Management_Policy.pdf";
const Investor_Redressal_Mechanism =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Investor Redressal Mechanism.pdf";
const Network_Security_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Network_Security_Policy.pdf";
const Password_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Password_Policy.pdf";
const PMLA_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/PMLA Policy.pdf";
const Policy_for_dealing_with_Conflict_of_interest_of_Intermediaries_and_Associated_Persons_in_Securities_Market =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Policy for dealing with Conflict of interest of Intermediaries and Associated Persons in Securities Market.pdf";
const Policy_for_Handling_Technical_Glitches_to_Prevent_Business_Disruption =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Policy for Handling Technical Glitches to Prevent Business Disruption.pdf";
const Policy_on_Third_party_Outsourcing_and_Business_Partnerships =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Policy on Third-party Outsourcing and Business Partnerships.pdf";
const Policy_on_Facsimile =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Policy_on_Facsimile.pdf";
const Policy_on_Insider_Trading =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Policy_on_Insider_Trading.pdf";
const Policy_on_Internal_Control =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Policy_on_Internal_Control.pdf";
const Policy_on_IT_Infrastructure_Planning =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Policy_on_IT_Infrastructure_Planning.pdf";
const Remote_Access_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Remote_Access_Policy.pdf";
const RMS_and_Surveillance_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/RMS_and_Surveillance_Policy.pdf";
const Securities_Operation_and_Risk_Management_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Securities_Operation and Risk_Management_Policy.pdf";
const Security_Incident_and_Event_Management_Policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policies/Security_Incident and Event_Management_Policy.pdf";

export {
  Application_Software_Policy,
  Audit_Trail_Policy,
  Back_up_and_Recovery_Policy,
  Client_Code_Modification_Policy_and_Error_Policy,
  Client_Onboarding_Process_Policy,
  Cyber_Security_and_Cyber_Resilience_Policy,
  Cyber_Incident_Response_Policy,
  Data_Classification_and_Protection_Policy,
  Hiring_Policy,
  Inactive_Account_Policy,
  Information_Risk_Management_Policy,
  Investor_Redressal_Mechanism,
  Network_Security_Policy,
  Password_Policy,
  PMLA_Policy,
  Policy_for_dealing_with_Conflict_of_interest_of_Intermediaries_and_Associated_Persons_in_Securities_Market,
  Policy_for_Handling_Technical_Glitches_to_Prevent_Business_Disruption,
  Policy_on_Third_party_Outsourcing_and_Business_Partnerships,
  Policy_on_Facsimile,
  Policy_on_Insider_Trading,
  Policy_on_Internal_Control,
  Policy_on_IT_Infrastructure_Planning,
  Remote_Access_Policy,
  RMS_and_Surveillance_Policy,
  Securities_Operation_and_Risk_Management_Policy,
  Security_Incident_and_Event_Management_Policy,
};
