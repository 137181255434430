import React, { useState } from "react";
import styles from "./InvestmentDetails.module.css";
import Tabs from "../Tabs/Tabs";
import Slider from "../Slider/Slider";
import DoughnutChart from "../Doughnut/Doughnut";
import ReturnTab from "../TabComponent/ReturnTab";
import { calculateCompoundInterest } from "../utils/calculateCompoundInterest";
import redirectTo from "../../../../utils/RedirectTo";

const InvestmentDetails = ({
  activeTab,
  setActiveTab,
  investmentAmount,
  setInvestmentAmount,
  expectedReturns,
  setExpectedReturns,
  investmentYears,
  setInvestmentYears,
}) => {
  const [sipActiveTab, setSipActiveTab] = useState(0); // Track active tab for SIP

  const isSIP = activeTab === 1;
  const annualInvestmentAmount = isSIP
    ? investmentAmount * 12
    : investmentAmount;

  const investedFor = 60 - investmentYears;

  const totalAmount = calculateCompoundInterest(
    annualInvestmentAmount,
    expectedReturns,
    investedFor,
    isSIP
  );

  // Convert to number and validate
  const validTotalAmount = Number.isFinite(totalAmount)
    ? parseFloat(totalAmount)
    : 0;
  const totalInvestment = isSIP
    ? parseFloat(annualInvestmentAmount * investedFor)
    : parseFloat(investmentAmount);

  // Validate interestEarned calculation
  const interestEarned = validTotalAmount - totalInvestment;

  console.log("totalAmount:", totalAmount);
  console.log("validTotalAmount:", validTotalAmount);
  console.log("totalInvestment:", totalInvestment);
  console.log("interestEarned:", interestEarned);

  // Handle possible NaN or non-numeric values
  const formattedTotalAmount = validTotalAmount
    ? validTotalAmount.toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      })
    : "0.00";

  const data = {
    datasets: [
      {
        data: [totalInvestment, interestEarned],
        backgroundColor: ["#41558D", "#82B770"],
        rotation: 90,
      },
    ],
  };

  const returnTab = [
    {
      id: 1,
      title: "Low",
      rate: 8,
      icon: (
        <span className="material-symbols-rounded">
          check_indeterminate_small
        </span>
      ),
    },
    {
      id: 2,
      title: "Medium",
      rate: 10,
      icon: <span className="material-symbols-rounded">keyboard_arrow_up</span>,
    },
    {
      id: 3,
      title: "High",
      rate: 12,
      icon: (
        <span className="material-symbols-rounded">
          keyboard_double_arrow_up
        </span>
      ),
    },
  ];

  const tabs = [
    {
      id: 1,
      name: "Monthly SIP",
      content: (
        <div>
          <div>
            <p className={styles.sliderTitle}>Expected returns</p>
            <ReturnTab
              tabsData={returnTab}
              activeTab={sipActiveTab}
              setActiveTab={setSipActiveTab}
              rateChange={setExpectedReturns}
              isSIP={true}
            />
          </div>

          <div style={{ margin: "24px 0px" }}>
            <p className={styles.sliderTitle}>Monthly investment amount</p>
            <Slider
              endRange={200000}
              startRange={500}
              price
              step={100}
              onChange={setInvestmentAmount}
            />
          </div>

          <div>
            <p className={styles.sliderTitle}>Your age</p>
            <Slider
              endRange={60}
              startRange={18}
              year
              step={1}
              onChange={setInvestmentYears}
            />
          </div>
        </div>
      ),
    },
    {
      id: 2,
      name: "Lumpsum",
      content: (
        <div>
          <div>
            <p className={styles.sliderTitle}>Expected returns</p>
            <ReturnTab
              tabsData={returnTab}
              activeTab={sipActiveTab}
              setActiveTab={setSipActiveTab}
              rateChange={setExpectedReturns}
              isSIP={false}
            />
          </div>

          <div style={{ margin: "24px 0px" }}>
            <p className={styles.sliderTitle}>Lumpsum amount</p>
            <Slider
              endRange={200000}
              startRange={500}
              price
              step={100}
              onChange={setInvestmentAmount}
            />
          </div>

          <div>
            <p className={styles.sliderTitle}>Your age</p>
            <Slider
              endRange={60}
              startRange={18}
              year
              step={1}
              onChange={setInvestmentYears}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.margin_container}>
        <div className={styles.inner_container1}>
          <h1>Investment Details</h1>
          <Tabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />

          <div className={styles.consistency} style={{ marginTop: "24px" }}>
            <p className={styles.spanContent}>
              A{" "}
              <span>{isSIP ? "monthly investment" : "lumpsum investment"}</span>{" "}
              of <span>₹{investmentAmount.toLocaleString("en-IN")}</span> from
              <span> {investmentYears} years</span>, at an{" "}
              <span>{expectedReturns}%</span> rate of return would yield a total
              amount of over <span>{formattedTotalAmount}</span> at the age of{" "}
              <span>60</span>
            </p>
          </div>
        </div>

        <div className={styles.inner_container2}>
          <div className={styles.graph_container}>
            <h1>Investment Breakdown</h1>

            <div className={styles.donut}>
              <DoughnutChart data={data} />
            </div>

            <div className={styles.legend}>
              <div className={styles.l1}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend1}></div>
                  <p className={styles.legendTitle}>Total investment</p>
                </div>
                <p className={styles.legendPrice}>
                  {Number.isFinite(totalInvestment)
                    ? totalInvestment.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })
                    : "0.00"}
                </p>
              </div>

              <div className={styles.l2}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend2}></div>
                  <p className={styles.legendTitle}>Interest earned</p>
                </div>
                <p className={styles.legendPrice}>
                  {Number.isFinite(interestEarned)
                    ? interestEarned.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })
                    : "0.00"}
                </p>
              </div>
            </div>

            <hr className={styles.horizontalRule} />

            <div className={styles.FinalAmount}>
              <p className={styles.FinalAmountTitle}>Final amount</p>
              <p className={styles.legendPrice}>{formattedTotalAmount}</p>
            </div>
          </div>

          <button
            className={styles.startInvesting}
            onClick={() => redirectTo()}
          >
            <span className={styles.btnContent}>Start investing</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetails;
