export const calculateRDMaturity = (P, R, t, N = 4) => {
  let totalMaturity = 0;
  const monthlyRate = R / (N * 100) / 3; // Quarterly to Monthly
  const totalMonths = t * 12;
  for (let i = 1; i <= totalMonths; i++) {
    const monthsRemaining = totalMonths - i + 1;
    totalMaturity += P * Math.pow(1 + monthlyRate, monthsRemaining);
  }
  return totalMaturity;
};

export const calculateRDData = (monthlyDeposit, annualRate, years) => {
  const totalInvestment = [];
  const interestEarned = [];

  for (let i = 1; i <= years; i++) {
    const totalMaturity = calculateRDMaturity(monthlyDeposit, annualRate, i);
    const totalInvested = monthlyDeposit * i * 12;
    totalInvestment.push(totalInvested);
    interestEarned.push(totalMaturity - totalInvested);
  }

  return { totalInvestment, interestEarned };
};
