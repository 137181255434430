import React, { useEffect } from "react";
import styles from "./InvestmentDetails.module.css";
import Tabs from "../Tabs/Tabs";
import Slider from "../Slider/Slider";
import DoughnutChart from "../Doughnut/Doughnut";
import redirectTo from "../../../../utils/RedirectTo";

// Function to format numbers in the Indian numbering system
const formatIndianCurrency = (value) => {
  const [integerPart, decimalPart] = value.toFixed(2).split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{2})+(?!\d))/g,
    ","
  );
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};

const InvestmentDetails = ({
  investmentAmount,
  expectedReturns,
  investmentYears,
  setInvestmentAmount,
  setExpectedReturns,
  setInvestmentYears,
  setActiveTab,
  activeTab,
}) => {
  // Compound interest calculation function
  const calculateCompoundInterest = (P, r, t, n = 1) => {
    return P * Math.pow(1 + r / (n * 100), n * t);
  };

  let totalAmount, totalInvestment;
  if (activeTab === 2) {
    // Lumpsum calculation
    totalAmount = calculateCompoundInterest(
      investmentAmount,
      expectedReturns,
      investmentYears
    );
    totalInvestment = investmentAmount; // For lumpsum, total investment is the initial amount
  } else {
    // Monthly SIP calculation
    const totalMonths = investmentYears * 12;
    totalInvestment = investmentAmount * totalMonths;
    totalAmount = Array.from({ length: totalMonths }).reduce(
      (acc, _, monthIndex) =>
        acc +
        calculateCompoundInterest(
          investmentAmount,
          expectedReturns,
          (totalMonths - monthIndex) / 12
        ),
      0
    );
  }

  const interestEarned = totalAmount - totalInvestment;

  const data = {
    datasets: [
      {
        data: [totalInvestment, interestEarned],
        backgroundColor: ["#41558D", "#82B770"],
        rotation: 90,
      },
    ],
  };

  const tabs = [
    {
      id: 1,
      name: "Monthly SIP",
      content: (
        <div>
          <div>
            <p className={styles.sliderTitle}>Monthly investment amount</p>
            <Slider
              endRange={200000}
              startRange={1200}
              price
              step={100}
              onChange={(value) => setInvestmentAmount(Number(value))}
            />
          </div>

          <div style={{ margin: "24px 0px" }}>
            <p className={styles.sliderTitle}>Expected returns</p>
            <Slider
              endRange={20}
              startRange={1}
              percentage
              step={1}
              onChange={(value) => setExpectedReturns(Number(value))}
            />
          </div>

          <div>
            <p className={styles.sliderTitle}>Investment period</p>
            <Slider
              endRange={50}
              startRange={1}
              year
              step={1}
              onChange={(value) => setInvestmentYears(Number(value))}
            />
          </div>
        </div>
      ),
    },
    {
      id: 2,
      name: "Lumpsum",
      content: (
        <div>
          <div>
            <p className={styles.sliderTitle}>Lumpsum Amount</p>
            <Slider
              endRange={200000}
              startRange={1200}
              price
              step={100}
              onChange={(value) => setInvestmentAmount(Number(value))}
            />
          </div>

          <div style={{ margin: "24px 0px" }}>
            <p className={styles.sliderTitle}>Expected returns</p>
            <Slider
              endRange={20}
              startRange={1}
              percentage
              step={1}
              onChange={(value) => setExpectedReturns(Number(value))}
            />
          </div>

          <div>
            <p className={styles.sliderTitle}>Investment period</p>
            <Slider
              endRange={50}
              startRange={1}
              year
              step={1}
              onChange={(value) => setInvestmentYears(Number(value))}
            />
          </div>
        </div>
      ),
    },
  ];

  // Reset data when the active tab changes
  useEffect(() => {
    setInvestmentAmount(1200);
    setExpectedReturns(1);
    setInvestmentYears(1);
  }, [activeTab, setInvestmentAmount, setExpectedReturns, setInvestmentYears]);

  return (
    <div className={styles.container}>
      <div className={styles.margin_container}>
        <div className={styles.inner_container1}>
          <h1>Investment Details</h1>
          <Tabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />

          <div className={styles.consistency} style={{ marginTop: "24px" }}>
            <p className={styles.spanContent}>
              {activeTab === 2 ? (
                <>
                  A <span>lumpsum investment</span> of{" "}
                  <span>
                    {investmentAmount.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: "INR",
                    })}
                  </span>{" "}
                  over <span>{investmentYears} years</span>, at an{" "}
                  <span>{expectedReturns}%</span> rate of return would yield a
                  total amount of{" "}
                  <span>
                    {totalAmount.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: "INR",
                    })}
                  </span>
                </>
              ) : (
                <>
                  A <span>monthly SIP</span> of{" "}
                  <span>
                    {investmentAmount.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: "INR",
                    })}
                  </span>{" "}
                  over <span>{investmentYears} years</span>, at an{" "}
                  <span>{expectedReturns}%</span> rate of return would yield a
                  total amount of{" "}
                  <span>
                    {totalAmount.toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: "INR",
                    })}
                  </span>
                </>
              )}
            </p>
          </div>
        </div>

        <div className={styles.inner_container2}>
          <div className={styles.graph_container}>
            <h1>Investment Breakdown</h1>

            <div className={styles.donut}>
              <DoughnutChart data={data} />
            </div>

            <div className={styles.legend}>
              <div className={styles.l1}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend1}></div>
                  <p className={styles.legendTitle}>Total investment</p>
                </div>
                <p className={styles.legendPrice}>
                  {totalInvestment.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </div>

              <div className={styles.l2}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend2}></div>
                  <p className={styles.legendTitle}>Interest earned</p>
                </div>
                <p className={styles.legendPrice}>
                  {interestEarned.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </div>
            </div>

            <hr className={styles.horizontalRule} />

            <div className={styles.FinalAmount}>
              <p className={styles.FinalAmountTitle}>Final amount</p>
              <p className={styles.legendPrice}>
                {totalAmount.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })}
              </p>
            </div>
          </div>

          <button
            className={styles.startInvesting}
            onClick={() => redirectTo()}
          >
            <span className={styles.btnContent}>Start investing</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetails;
