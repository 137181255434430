import { MoveRight } from "lucide-react";
import "./Insurance.css";
import { useEffect } from "react";

const Insurance = () => {
  const isMobile = window.innerWidth <= 767;

  useEffect(() => {
    const ladderDivs = document.querySelectorAll(".ladder > div");

    if (isMobile) {
      ladderDivs.forEach((div) => div.classList.add("ladder__mobile"));
    } else {
      ladderDivs.forEach((div) => div.classList.remove("ladder__mobile"));
    }
  }, [isMobile]);

  return (
    <div className="insurance">
      {isMobile ? (
        <>
          <div className="insurance__header">
            <div style={{ zIndex: "10" }}>
              <div>
                <h1>Insurance for all occasions</h1>
                <p>Let experts build your path to it</p>
              </div>
              <div className="insurance__contact">
                <button
                  className="insurance__button insurance__button--primary"
                  onClick={() => {
                    window.open(
                      "https://fabits.zohobookings.in/#/203748000000035018",
                      "_blank"
                    );
                  }}
                >
                  Book Free Call
                  {!isMobile && <MoveRight />}
                </button>
                <button
                  className="insurance__button insurance__button--secoundary"
                  onClick={() => {
                    window.open(
                      "https://youtu.be/A6fVok5RdAY?utm_source=fabits+landing+page&utm_medium=insurance+for+health+%26+life&utm_campaign=health+insurance&utm_id=13062024&utm_term=learn+more",
                      "_blank"
                    );
                  }}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
          <div className="insurance__ditto">
            <div>
              <img src="/Insurance/local_police.svg" alt="local police" />
              <img src="/Insurance/ditto.svg" alt="ditto logo" />
            </div>
            <h3>Secured by Ditto Insurance.</h3>
            <p>Leading Indian insurance advisory and commerce platform.</p>
          </div>
        </>
      ) : (
        <div className="insurance__header">
          <div style={{ zIndex: "10" }}>
            <div>
              <h1>
                Insurance for your <br /> Health & Life
              </h1>
              <p>Build financial safety-net for the emergencies of tomorrow</p>
            </div>
            <div className="insurance__contact">
              <button
                className="insurance__button insurance__button--primary"
                onClick={() => {
                  window.open(
                    "https://fabits.zohobookings.in/#/203748000000035018",
                    "_blank"
                  );
                }}
              >
                Book Free Call
                {!isMobile && <MoveRight />}
              </button>
              <button
                className="insurance__button insurance__button--secondary"
                onClick={() => {
                  window.open(
                    "https://youtu.be/A6fVok5RdAY?utm_source=fabits+landing+page&utm_medium=insurance+for+health+%26+life&utm_campaign=health+insurance&utm_id=13062024&utm_term=learn+more",
                    "_blank"
                  );
                }}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="insurance__ditto">
            <div>
              <img src="/Insurance/local_police.svg" alt="local police" />
              <img src="/Insurance/ditto.svg" alt="ditto logo" />
            </div>
            <h3>Secured by Ditto Insurance.</h3>
            <p>Leading Indian insurance advisors</p>
          </div>
        </div>
      )}

      <div className="insurance__trusts">
        <h1>
          {isMobile
            ? "Join who trust Ditto"
            : "We’ve partnered with the best in the industry"}
        </h1>

        <div>
          <div>
            <h2>2.8 Crore+</h2>
            <p>Customers insured</p>
          </div>
          <div className="insurance__divider" />
          <div>
            <h2>8 Crore+</h2>
            <p>Policies issued</p>
          </div>
          <div className="insurance__divider" />
          <div>
            <h2>94.54%</h2>
            <p>Claim settlement ratio</p>
          </div>
          <div className="insurance__divider" />
          <div>
            <h2>12 minutes</h2>
            <p>Fastest claim settled</p>
          </div>
        </div>
      </div>

      <div className="insurance__get-started">
        <h1 style={{ textAlign: isMobile ? "start" : "center" }}>
          Get started easily
        </h1>

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-evenly",
            marginTop: "30px",
            alignItems: isMobile ? "start" : "center",
          }}
        >
          <div>
            <span>
              <h3>1</h3>
            </span>
            <div>
              <h2>Ready</h2>
              <p>
                <a
                  href="https://fabits.zohobookings.in/#/203748000000035018"
                  target="_blank"
                  rel="noreferrer"
                >
                  Schedule a call now
                </a>
                &nbsp; and <br />
                tell us about your needs
              </p>
            </div>
          </div>
          <div className="insurance__divider" />
          <div>
            <span>
              <h3>2</h3>
            </span>
            <div>
              <h2>Set</h2>
              <p>
                We assess your policy and <br /> identify coverage gaps
              </p>
            </div>
          </div>
          <div className="insurance__divider" />
          <div>
            <span>
              <h3>3</h3>
            </span>
            <div>
              <h2>Go!</h2>
              <p>
                Get access to a tailor-
                <br />
                made insurance plan
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="insurance__plans">
        <div>
          <div>
            <img src="/Insurance/doctor-1.png" alt="Health Insurance" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "start",
              rowGap: "20px",
            }}
          >
            <h1>Health Insurance</h1>
            <p style={{ color: "#727272" }}>
              Medical inflation in India is at an all-time high of 14%.
              Investing in personal health insurance, over & above your
              corporate one, has become a necessity.
            </p>

            <p style={{ color: "#727272" }}>
              Get financially ready to cover health-related expenses - Choose a
              comprehensive plan and insure a sum enough for you to be prepared
              for medical emergencies.
            </p>
            <p style={{ color: "#727272" }}>
              We can help you identify the gaps and pick the right insurance for
              you.
            </p>

            <button
              className="insurance__button insurance__button--tertiary"
              onClick={() => {
                window.open(
                  "https://youtu.be/KazMH5Dknx0?utm_source=fabits+landing+page&utm_medium=Insurance+landing+page&utm_campaign=health+insurance&utm_id=13062024&utm_term=learn+more",
                  "_blank"
                );
              }}
            >
              Learn More
            </button>
          </div>
        </div>
        <div>
          <div>
            <img
              src="/Insurance/home-page-life-insurance.png"
              alt="Life Insurance"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "start",
              rowGap: "20px",
            }}
          >
            <h1>Life Insurance</h1>
            <p style={{ color: "#727272" }}>
              Life Insurance is a vital part of a financial plan, a tool that
              ensures you are prepared for unexpected events.
            </p>
            <p style={{ color: "#727272" }}>
              The right policy gives your loved ones the financial security they
              need.
            </p>

            <button
              className="insurance__button insurance__button--tertiary"
              onClick={() => {
                window.open(
                  "https://blog.fabits.com/2024/05/29/term-insurance-101-what-why-and-how/?utm_source=fabits+landing+page&utm_medium=term+insurance+blog&utm_campaign=life+insurance&utm_id=13062024&utm_term=learn+more",
                  "_blank"
                );
              }}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>

      {isMobile ? (
        <button
          className="insurance__button insurance__button-mobile"
          onClick={() => {
            window.open(
              "https://fabits.zohobookings.in/#/203748000000035018",
              "_blank"
            );
          }}
        >
          Book Free Call
        </button>
      ) : (
        <div className="insurance__bottom">
          <div>
            <h1 style={{ color: "white" }}>Get Free Consultation Today!</h1>
            <p style={{ color: "#C4CADC" }}>
              Assess if you have sufficient coverage; talk to our experts for
              free.
            </p>
          </div>

          <button
            className="insurance__button insurance__button--primary"
            onClick={() => {
              window.open(
                "https://fabits.zohobookings.in/#/203748000000035018",
                "_blank"
              );
            }}
          >
            Book Free Call
            <MoveRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default Insurance;
