import React from "react";
import { Chart, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement, Tooltip, Legend, Title);

const DoughnutChart = ({ data }) => {
  return (
    <div
      style={{
        width: "196px",
        height: "196px",
      }}
    >
      <Doughnut data={data} />
    </div>
  );
};

export default DoughnutChart;
