import React from "react";
import "./table.css";

const Table = ({ tableData, title }) => {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>SI No.</th>
            <th>{title ? title : "Forms / Application"}</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td style={{ width: "50px", textAlign: "center" }}>{row.id}</td>
              <td>{row.title}</td>
              <td style={{ width: "20px" }}>
                <a
                  href={row.pdf}
                  target="blank"
                  rel="noreferrer"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span class="material-symbols-rounded">download</span>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
