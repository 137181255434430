import React, { useState, useEffect, useRef } from "react";
import styles from "./slider.module.css";

const Slider = ({
  startRange,
  endRange,
  percentage,
  year,
  price,
  step,
  onChange,
  disabled,
  title,
}) => {
  const [value, setValue] = useState(startRange);
  const sliderRef = useRef(null);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    if (sliderRef.current) {
      const progress = ((value - startRange) / (endRange - startRange)) * 100;
      sliderRef.current.style.setProperty("--progress", `${progress}%`);
    }
  }, [value, startRange, endRange]);

  return (
    <div className={styles.Slider}>
      <div className={styles.SliderContainer}>
        {disabled ? (
          <p>{title}</p>
        ) : (
          <>
            <input
              ref={sliderRef}
              className={`${styles.slider}-${startRange}`}
              type="range"
              min={startRange}
              max={endRange}
              value={value}
              onChange={handleChange}
              step={step}
            />

            <p className={styles.pricingslidersection}>
              <span className={styles.sliderspan}>
                {price && "₹"}{" "}
                {startRange.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                })}{" "}
                {percentage && "%"} {year && "year"}
              </span>
              <span className={styles.sliderspan}>
                {price && "₹"}{" "}
                {endRange.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                })}{" "}
                {percentage && "%"} {year && "years"}
              </span>
            </p>
          </>
        )}
      </div>
      <div className={styles.Cost}>
        <p>
          {price && "₹"}{" "}
          {value.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
          })}
          {percentage && "%"} {year && "years"}
        </p>
      </div>
    </div>
  );
};

export default Slider;
