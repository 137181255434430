import React from "react";
import "./NotFound.css";

const NotFound = () => {
  const Learn_btn_link = () => {
    window.open(
      "https://youtu.be/A6fVok5RdAY?utm_source=fabits+landing+page&utm_medium=insurance+for+health+%26+life&utm_campaign=health+insurance&utm_id=13062024&utm_term=learn+more",
      "_blank"
    );
  };

  const Explore_btn_lnk = () => {
    window.open("https://d2jns.app.link/download-fabits", "_blank");
  };

  return (
    <div className="not-found-container">
      <img src="/Error/error.svg" alt="Error Image" className="error-image" />
      <div className="error-text">
        <p>Oops! That page doesn't exist</p>
        <p className="small-text">Fret not, we've still got you covered!</p>
        <div className="button-container">
          <button className="error_btn_learn" onClick={Learn_btn_link}>
            Learn More
          </button>
          <button className="error_btn_explore" onClick={Explore_btn_lnk}>
            Explore MyWealth
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
