import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale);

const StackedBarGraph = ({ options, data }) => {
  return (
    <div>
      <Bar data={data} options={options} height={296} />
    </div>
  );
};

export default StackedBarGraph;
