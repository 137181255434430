import React, { useEffect } from "react";
import "./Termsandconditions.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import CharterAccordian from "../components/Investor_Charter/CharterAccordian";
import InvestorComplaints from "./InvestorComplaints";
import PrivacyPolicy from "./PrivacyPolicy";
import Disclosure from "./Disclosure";
import PoliciesAndProcedures from "./PoliciesAndProcedures";
import TermsandConditions from "./TermsandConditions";
import Table from "../components/common/Table/Table";
import RiskDisclosure from "./RiskDisclosure";
import {
  Account_Closure_Form,
  Demat_Debit_and_Pledge_Instruction,
  Dematerialisation_Request_Form,
  DIS_Request_Form,
  Dormant_Account_Reactivation_Form,
  Freeze_Unfreeze_Request_Form,
  Invocation_Request_Form,
  KYC_Form,
  Margin_Invocation_Request_Form,
  Margin_Pledge_Request_Form,
  Margin_Un_pledge_Request_Form,
  Nomination_Form,
  Nomination_Opting_Out_Declaration_form,
  Pledge_Request_Form,
  Rejection_Letter_for_Dematerialisation_Request_Number_DRN,
  Rejection_Letter_for_Dematerialisation_Request_Number_DRN_BO_TO_DP,
  Trading_Demat_Account_Details_Addition_Modification_Form,
  Transmission_cum_Dematerialisation,
  Transmission_Form_Sole_Holder,
  Transmission_With_and_Without_Nominee,
  Transposition_Request_Form,
  Unpledge_Request_Form,
} from "../assets/forms_and_applications";
import { Bank_Details, Investor_Charter_DP } from "../assets/fabits_assets";

const tableData = [
  {
    id: 1,
    title: "Nomination Form",
    pdf: Nomination_Form,
  },
  {
    id: 2,
    title: "Nomination Opting Out Declaration form",
    pdf: Nomination_Opting_Out_Declaration_form,
  },
  {
    id: 3,
    title: "KYC Form",
    pdf: KYC_Form,
  },
  {
    id: 4,
    title: "Pledge Request Form",
    pdf: Pledge_Request_Form,
  },
  {
    id: 5,
    title: "Un-pledge Request Form",
    pdf: Unpledge_Request_Form,
  },
  {
    id: 6,
    title: "Demat Debit and Pledge Instruction (DDPI)",
    pdf: Demat_Debit_and_Pledge_Instruction,
  },
  {
    id: 7,
    title: "Freeze-Unfreeze Request Form",
    pdf: Freeze_Unfreeze_Request_Form,
  },
  {
    id: 8,
    title: "Invocation Request Form (IRF)",
    pdf: Invocation_Request_Form,
  },
  {
    id: 9,
    title: "Margin Pledge Request Form",
    pdf: Margin_Pledge_Request_Form,
  },
  {
    id: 10,
    title: "Margin Invocation Request Form",
    pdf: Margin_Invocation_Request_Form,
  },
  {
    id: 11,
    title: "Margin Un-pledge Request Form",
    pdf: Margin_Un_pledge_Request_Form,
  },
  {
    id: 12,
    title: "Trading / Demat Account Details Addition / Modification Form",
    pdf: Trading_Demat_Account_Details_Addition_Modification_Form,
  },
  {
    id: 13,
    title: "Individual Account Opening Form ",
    pdf: "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Individual Account Opening Form.pdf",
  },
  {
    id: 14,
    title: "NRI Account Opening Form",
    pdf: "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/NRI Account Opening Form.pdf",
  },
  {
    id: 15,
    title: "Non-Individual Account Opening Form",
    pdf: "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Non-Individual Account Opening Form.pdf",
  },
  {
    id: 16,
    title: "DIS Request Form ",
    pdf: DIS_Request_Form,
  },
  {
    id: 17,
    title: "Dematerialisation Request Form",
    pdf: Dematerialisation_Request_Form,
  },
  {
    id: 18,
    title: "Transposition Request Form",
    pdf: Transposition_Request_Form,
  },
  {
    id: 19,
    title: "Transmission cum Dematerialisation",
    pdf: Transmission_cum_Dematerialisation,
  },
  {
    id: 20,
    title: "Transmission Form Sole Holder",
    pdf: Transmission_Form_Sole_Holder,
  },
  {
    id: 21,
    title: "Transmission With and Without Nominee",
    pdf: Transmission_With_and_Without_Nominee,
  },
  {
    id: 22,
    title: "Dormant Account Reactivation Form",
    pdf: Dormant_Account_Reactivation_Form,
  },
  {
    id: 23,
    title: "Account Closer Form",
    pdf: Account_Closure_Form,
  },
  {
    id: 24,
    title: "Rejection Letter for Dematerialisation Request Number (DRN)",
    pdf: Rejection_Letter_for_Dematerialisation_Request_Number_DRN,
  },
  {
    id: 25,
    title:
      "Rejection Letter for Dematerialisation Request Number (DRN) (BO-to-DP)",
    pdf: Rejection_Letter_for_Dematerialisation_Request_Number_DRN_BO_TO_DP,
  },
  {
    id: 26,
    title: "FATCA",
    pdf: "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/FATCA.pdf",
  },
];

const Data = [
  {
    id: 1,
    title: <span>INVESTOR CHARTER - STOCK BROKERS</span>,
    content: (
      <>
        <div classNameName="contentstartshere">
          <h2 style={{ marginTop: "20px" }}>VISION</h2>

          <p className="mt-5">
            To follow the highest standards of ethics and compliances while
            facilitating client trades in securities in a fair and transparent
            manner, to contribute to creating wealth for investors. We envision
            a world, where every individual makes good financial habits a part
            of their lifestyle.
          </p>

          <h3 className="heeding3standcpage">MISSION</h3>

          <ol className="olol">
            <li>
              To provide high-quality and dependable service through innovation,
              capacity enhancement, and the use of technology.
            </li>
            <li>
              To establish and maintain a relationship of trust and ethics with
              the investors.
            </li>
            <li>
              To observe the highest standard of compliance and transparency.
            </li>
            <li>
              To always keep ‘protection of investors’ interest’ as a goal while
              providing service.
            </li>
            <li>
              In our journey to realise this vision, we bring together the best
              practices, learned over decades of industry experience and provide
              effortless investment solutions, crafted for everyone.
            </li>
          </ol>
          <h3 className="heeding3standcpage">Services provided to Investors</h3>

          <ol className="olol">
            <li>Execution of trades on behalf of investors.</li>
            <li>Issuance of Contract Notes.</li>
            <li>Issuance of intimations regarding margin due payments.</li>
            <li>
              Facilitate execution of early pay-in obligation instructions.
            </li>
            <li>Settlement of client’s funds.</li>
            <li>
              Intimation of securities held in Client Unpaid Securities Account
              (CUSA).
            </li>
            <li>Issuance of retention statement of funds.</li>
            <li>
              Risk management systems to mitigate operational and market risk.
            </li>
            <li>
              Facilitate client profile changes in the system as instructed by
              the client.
            </li>
            <li>
              Information sharing with the client w.r.t. exchange circulars.
            </li>
            <li>Redressal of Investor’s grievances</li>
          </ol>

          <h3 className="heeding3standcpage">Rights of Investors</h3>

          <ol className="olol">
            <li>
              Ask for and receive information from Fabits about the work history
              and background of the person handling your account, as well as
              information about Fabits itself.
            </li>
            <li>
              Receive complete information about the risks, obligations, and
              costs of any investment before investing.
            </li>
            <li>
              Receive recommendations consistent with your financial needs and
              investment objectives.
            </li>
            <li>
              Receive a copy of all completed account forms and agreements.
            </li>
            <li>
              Receive account statements that are accurate and understandable.
            </li>
            <li>
              Understand the terms and conditions of transactions you undertake.
            </li>
            <li>
              Access your funds in a timely manner and receive information about
              any restrictions or limitations on access.
            </li>
            <li>
              Receive complete information about maintenance or service charges,
              transaction or redemption fees, and penalties.
            </li>
            <li>
              Discuss your grievances with the compliance officer of the firm
              and receive prompt attention to and fair consideration of your
              concerns.
            </li>
          </ol>

          <h3 className="heeding3standcpage">
            Various activities of Stock Brokers with timelines
          </h3>
          <table className="table-investorcharter">
            <thead>
              <tr>
                <th className="row th p-3 col-6">Activities</th>
                <th className="row th p-3 col-6">Expected Timelines</th>
              </tr>
            </thead>
            <tbody>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">KYC entered into KRA System and CKYCR</td>
                <td className="col-6">10 days of account opening</td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">Client Onboarding</td>
                <td className="col-6">
                  Immediate, but not later than one week
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">Order execution</td>
                <td className="col-6">
                  Immediate on receipt of order, but not later than the same day
                </td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">Allocation of Unique Client Code</td>
                <td className="col-6">Before trading</td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Copy of duly completed Client Registration Documents to
                  clients
                </td>
                <td className="col-6">
                  7 days from the date of upload of Unique Client Code to the
                  Exchange by the trading member
                </td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">Issuance of contract notes</td>
                <td className="col-6">24 hours of execution of trades</td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Collection of upfront margin from client
                </td>
                <td className="col-6">Before initiation of trade</td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">
                  Issuance of intimations regarding other margin due payments
                </td>
                <td className="col-6">At the end of the T day</td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">Settlement of client funds</td>
                <td className="col-6">
                  30 days / 90 days for running account settlement (RAS) as per
                  the preference of the client. If consent is not given for RAS
                  - within 24 hours of pay-out
                </td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">
                  ‘Statement of Accounts’ for Funds, Securities and Commodities
                </td>
                <td className="col-6">
                  Weekly basis (Within four trading days of Securities and
                  Commodities following week)
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Issuance of retention statement of funds /commodities
                </td>
                <td className="col-6">5 days from the date of settlement</td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">Issuance of Annual Global Statement</td>
                <td className="col-6">
                  30 days from the end of the financial year
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">Investor grievances redressal</td>
                <td className="col-6">
                  30 days from the receipt of the complaint
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="heeding3standcpage">DOs and DON’Ts for Investors</h3>
          <table className="table-investorcharter">
            <thead>
              <tr>
                <th className="row th p-3 col-6">DOs</th>
                <th className="row th p-3 col-6">DON’Ts</th>
              </tr>
            </thead>
            <tbody>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Read all documents and conditions before signing the account
                  opening form.
                </td>
                <td className="col-6">
                  Do not deal with unregistered stockbrokers.
                </td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">
                  Receive a copy of KYC, copy of account opening documents and
                  Unique Client Code.
                </td>
                <td className="col-6">
                  Do not forget to strike off blanks in your account opening and
                  KYC Forms.
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Read the product / operational framework / timelines related
                  to various Trading and Clearing & Settlement processes.
                </td>
                <td className="col-6">
                  Do not submit an incomplete account opening and KYC form.
                </td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">
                  Receive all information about brokerage, fees and other
                  charges levied.
                </td>
                <td className="col-6">
                  Do not forget to inform any change in information linked to
                  the trading account and obtain confirmation of updation in the
                  system.
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Register your mobile number and email ID in your trading,
                  demat and bank accounts to get regular alerts on your
                  transactions.
                </td>
                <td className="col-6">
                  Do not transfer funds, for the purposes of trading to anyone
                  other than a stock broker. No payment should be made in name
                  of employee of stock broker.
                </td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">
                  If executed, receive a copy of Power of Attorney. However,
                  Power of Attorney is not a mandatory requirement as per SEBI /
                  Stock Exchanges. Before granting Power of Attorney, carefully
                  examine the scope and implications of powers being granted.
                </td>
                <td className="col-6">
                  Do not ignore any emails / SMSs received with regards to
                  trades done, from the Stock Exchange and raise a concern, if
                  discrepancy is observed.
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Receive contract notes for trades executed, showing
                  transaction price, brokerage, GST and STT etc. as applicable,
                  separately, within 24 hours of execution of trades.
                </td>
                <td className="col-6">
                  Do not opt for digital contracts, if not familiar with
                  computers.
                </td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">
                  Receive funds and securities / commodities on time within 24
                  hours from pay-out.
                </td>
                <td className="col-6">Do not share a trading password.</td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Verify details of trades, contract notes and statement of
                  account and approach relevant authority for any discrepancies.
                  Verify trade details on the Exchange websites from the trade
                  verification facility provided by the Exchanges.
                </td>
                <td className="col-6">
                  Do not fall prey to fixed / guaranteed returns schemes.
                </td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">
                  Receive statement of accounts periodically. If opted for
                  running account settlement, account has to be settled by the
                  stock broker as per the option given by the client (30 or
                  90days).
                </td>
                <td className="col-6">
                  Do not fall prey to fraudsters sending emails and SMSs luring
                  to trade in stocks / securities promising huge profits.
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  In case of any grievances, approach stock broker or Stock
                  Exchange or SEBI for getting the same resolved within
                  prescribed timelines.
                </td>
                <td className="col-6">
                  Do not follow herd mentality for investments. Seek expert and
                  professional advice for your investments.
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="mt-4">Grievance Redressal Mechanism</h3>

          <p className="mt-5">
            <b>Level 1–</b>
            Approach the Stock Broker at the designated Investor Grievance
            e-mail ID of the stock broker. The Stock Broker will strive to
            redress the grievance immediately, but not later than 30 days of the
            receipt of the grievance.
          </p>

          <p className="mt-5">
            <b>Level 2–</b>
            Approach the Stock Exchange using the grievance mechanism mentioned
            at the website of the respective exchange.
          </p>
          <img
            src="./charter.jpg"
            alt="Invester Charter"
            id="investercharter"
          ></img>
          <p className="text-center">
            <b>
              Complaints Resolution Process at Stock Exchange explained
              graphically:
            </b>
          </p>

          <div className="d-flex justify-content-center mb-5">
            <img
              src="FabitsAssets/Complaints/charter.jpg"
              alt=""
              className="img-fluid "
            />
          </div>

          <h3 className="heeding3standcpage">
            Timelines for complaint resolution process at Stock Exchanges
            against stock brokers
          </h3>
          <table className="table-investorcharter">
            <thead>
              <tr>
                <th className="row th p-3 col-6">Type of Activity</th>
                <th className="row th p-3 col-6">Timelines for activity</th>
              </tr>
            </thead>
            <tbody>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">Receipt of Complaint</td>
                <td className="col-6">Day of complaint (C Day).</td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">
                  Additional information sought from the investor, if any, and
                  provisionally forwarded to stock broker.
                </td>
                <td className="col-6">C + 7 Working days.</td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Registration of the complaint and forwarding to the stock
                  broker.
                </td>
                <td className="col-6">C+8 Working Days i.e. T day.</td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">Amicable Resolution</td>
                <td className="col-6">T+15 Working Days.</td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Refer to Grievance Redressal Committee (GRC), in case of no
                  amicable resolution.
                </td>
                <td className="col-6">T+16 Working Days.</td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">Complete resolution process post GRC.</td>
                <td className="col-6">T + 30 Working Days.</td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  In case where the GRC Member requires additional information,
                  GRC order shall be completed within.
                </td>
                <td className="col-6">T + 45 Working Days.</td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">Implementation of GRC Order.</td>
                <td className="col-6">
                  On receipt of GRC Order, if the order is in favour of the
                  investor, debit the funds of the stock broker. Order for debit
                  is issued immediately or as per the directions given in GRC
                  order.
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  In case the stock broker is aggrieved by the GRC order, will
                  provide intention to avail arbitration
                </td>
                <td className="col-6">Within 7 days from receipt of order</td>
              </tr>
              <tr className="p-1 py-2">
                <td className="col-6">
                  If intention from stock broker is received and the GRC order
                  amount is upto Rs.20 lakhs
                </td>
                <td className="col-6">
                  Investor is eligible for interim relief from Investor
                  Protection Fund (IPF). The interim relief will be 50% of the
                  GRC order amount or Rs.2 lakhs whichever is less. The same
                  shall be provided after obtaining and Undertaking from the
                  investor.
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  Stock Broker shall file for arbitration
                </td>
                <td className="col-6">
                  Within 6 months from the date of GRC recommendation
                </td>
              </tr>
              <tr className="p-1 py-2 bg-light">
                <td className="col-6">
                  In case the stock broker does not file for arbitration within
                  6 months{" "}
                </td>
                <td className="col-6">
                  The GRC order amount shall be released to the investor after
                  adjusting the amount released as interim relief, if any.
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="heeding3standcpage">
            Handling of Investor’s claims / complaints in case of default of a
            Trading Member / Clearing Member (TM/CM)
          </h3>

          <h3 className="heeding3standcpage">Default of TM/CM</h3>

          <p className="mt-5">
            Following steps are carried out by Stock Exchange for benefit of
            investor, in case stockbroker defaults:
          </p>

          <ol className="olol">
            <li>
              Circular is issued to inform about declaration of Stock Broker as
              Defaulter.
            </li>
            <li>
              Information of defaulter stock broker is disseminated on Stock
              Exchange website.
            </li>
            <li>
              Public Notice is issued informing declaration of a stock broker as
              defaulter and inviting claims within specified period.
            </li>
            <li>
              Intimation to clients of defaulter stock brokers via emails and
              SMS for facilitating lodging of claims within the specified
              period.
            </li>
          </ol>

          <p className="mt-5">
            Following information is available on Stock Exchange website for
            information of investors:
          </p>

          <ol className="olol">
            <li>Norms for eligibility of claims for compensation from IPF.</li>
            <li>
              Claim form for lodging claim against defaulter stock broker.
            </li>
            <li>
              FAQ on processing of investors’ claims against defaulter stock
              broker.
            </li>
            <li>Provision to check online status of client’s claim.</li>
          </ol>

          <p className="mt-5">
            <b>Level 3 –</b>
            The complaint not redressed at Stock Broker / Stock Exchange level,
            may be lodged with SEBI on SCORES (a web-based centralized grievance
            redressal system of SEBI) @
            <br />
            <a href="https://scores.gov.in/scores/Welcome.html">
              https://scores.gov.in/scores/Welcome.html
            </a>
          </p>
        </div>
      </>
    ),
  },
  {
    id: 2,
    title: (
      <span>
        INVESTOR CHARTER FOR DEPOSITORIES AND DEPOSITORY PARTICIPANTS (Annexure
        – A)
      </span>
    ),
    content: (
      // <>
      //   <h3 className="heeding3standcpage">Vision</h3>

      //   <p className="mt-5">
      //     Towards making the Indian Securities Market – Transparent, Efficient,
      //     & Investor friendly by providing safe, reliable, transparent and
      //     trusted record keeping platform for investors to hold and transfer
      //     securities in dematerialized form
      //   </p>

      //   <h3 className="heeding3standcpage">Mission</h3>

      //   <ol className="olol">
      //     <li>
      //       To hold securities of investors in dematerialized form and
      //       facilitate its transfer, while ensuring safekeeping of securities
      //       and protecting interest of investors.
      //     </li>
      //     <li>
      //       To provide timely and accurate information to investors with regard
      //       to their holding and transfer of securities held by them.
      //     </li>
      //     <li>
      //       To provide the highest standards of investor education, investor
      //       awareness and timely services so as to enhance Investor Protection
      //       and create awareness about Investor Rights.
      //     </li>
      //   </ol>

      //   <h3 className="heeding3standcpage">
      //     Details of business transacted by the Depository and Depository
      //     Participant (DP)
      //   </h3>

      //   <p className="mt-5">
      //     A Depository is an organization which holds securities of investors in
      //     electronic form. Depositories provide services to various market
      //     participants – Exchanges, Clearing Corporations, Depository
      //     Participants (DPs), Issuers, and Investors in both primary as well as
      //     secondary markets. The depository carries out its activities through
      //     its agents which are known as Depository Participants (DP). Details
      //     are available on the link
      //     <a href="https://www.cdslindia.com/DP/dplist.aspx">
      //       [https://www.cdslindia.com/DP/dplist.aspx].
      //     </a>
      //   </p>

      //   <h3 className="heeding3standcpage">
      //     Description of services provided by the Depository through Depository
      //     Participants (DPs) to investors
      //   </h3>

      //   <p className="mt-5">(1) Basic Services</p>
      //   <table className="table-investorcharter">
      //     <thead>
      //       <tr>
      //         <th className="row th p-3 col-6">
      //           Brief about the Activity / Service
      //         </th>
      //         <th className="row th p-3 col-6">
      //           Expected Timelines for processing by the DP after receipt of
      //           proper documents
      //         </th>
      //       </tr>
      //     </thead>
      //     <tbody>
      //       <tr className="p-1 py-2 bg-light">
      //         <td className="col-6">Dematerialization of securities</td>
      //         <td className="col-6">7 days</td>
      //       </tr>
      //       <tr className="p-1 py-2">
      //         <td className="col-6">Rematerialization of securities</td>
      //         <td className="col-6">7 days</td>
      //       </tr>
      //       <tr className="p-1 py-2 bg-light">
      //         <td className="col-6">
      //           Mutual Fund Conversion / Destatementization
      //         </td>
      //         <td className="col-6">5 days</td>
      //       </tr>
      //       <tr className="p-1 py-2">
      //         <td className="col-6">
      //           Re-conversion / Restatementisation of Mutual fund units
      //         </td>
      //         <td className="col-6">7 days</td>
      //       </tr>
      //       <tr className="p-1 py-2 bg-light">
      //         <td className="col-6">Transmission of securities</td>
      //         <td className="col-6">7 days</td>
      //       </tr>
      //       <tr className="p-1 py-2">
      //         <td className="col-6">Registering pledge request</td>
      //         <td className="col-6">15 days</td>
      //       </tr>
      //       <tr className="p-1 py-2 bg-light">
      //         <td className="col-6">Closure of demat account</td>
      //         <td className="col-6">30 days</td>
      //       </tr>
      //       <tr className="p-1 py-2">
      //         <td className="col-6">Settlement Instruction</td>
      //         <td className="col-6">
      //           Depositories to accept physical DIS for pay-in of securities up
      //           to 4 p.m. and DIS in electronic form up to 6 p.m. on T+1 day
      //         </td>
      //       </tr>
      //     </tbody>
      //   </table>
      //   <p className="mt-5">
      //     (2) Depositories provide special services like pledge, hypothecation,
      //     internet-based services etc. in addition to their core services and
      //     these include
      //   </p>
      //   <table className="table-investorcharter">
      //     <thead>
      //       <tr>
      //         <th className="row th p-3 col-6">Type of Activity / Service</th>
      //         <th className="row th p-3 col-6">
      //           Brief about the Activity / Service
      //         </th>
      //       </tr>
      //     </thead>
      //     <tbody>
      //       <tr className="p-1 py-2 bg-light">
      //         <td className="col-6">Value Added Services</td>
      //         <td className="col-6">
      //           Depositories also provide value added services such as
      //           <br />
      //           <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //             a. Basic Services Demat Account (BSDA)
      //           </a>
      //           <br />
      //           <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //             b. Transposition cum dematerialization
      //           </a>
      //           <br />
      //           <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //             c. Linkages with Clearing System
      //           </a>
      //           <br />
      //           <p className="mt-5">
      //             d. Distribution of cash and non-cash corporate benefits
      //             (Bonus, Rights, IPOs etc.), stock lending.
      //           </p>
      //         </td>
      //       </tr>
      //       <tr className="p-1 py-2">
      //         <td className="col-6">Consolidated Account statement (CAS)</td>
      //         <td className="col-6">
      //           CAS is issued 10 days from the end of the month (if there were
      //           transactions in the previous month) or half yearly(if no
      //           transactions) .
      //         </td>
      //       </tr>
      //       <tr className="p-1 py-2 bg-light">
      //         <td className="col-6">
      //           Digitalization of provided services by the depositories
      //         </td>
      //         <td className="col-6">
      //           Depositories offer below technology solutions and e-facilities
      //           to their demat account holders through DPs:
      //           <ol className="olol">
      //             <li>
      //               E-account opening: Details available on the{" "}
      //               <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //                 link
      //               </a>
      //             </li>
      //             <li>
      //               Online instructions for execution: Details available on the{" "}
      //               <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //                 link
      //               </a>
      //               <ul>
      //                 <li>
      //                   <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //                     Easiest
      //                   </a>
      //                 </li>
      //                 <li>
      //                   <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //                     Gift transfers
      //                   </a>
      //                 </li>
      //               </ul>
      //             </li>
      //             <li>
      //               e-DIS / Demat Gateway: Details available on the{" "}
      //               <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //                 link
      //               </a>
      //             </li>
      //             <li>
      //               e-CAS facility: Details available on the{" "}
      //               <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //                 link
      //               </a>
      //             </li>
      //             <li>
      //               Miscellaneous services: Details available on the{" "}
      //               <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //                 link
      //               </a>
      //             </li>
      //           </ol>
      //         </td>
      //       </tr>
      //     </tbody>
      //   </table>
      //   <h2>Details of Grievance Redressal Mechanism</h2>

      //   <p className="mt-5">The Process of investor grievance redressal</p>
      //   <table className="table-investorcharter">
      //     <tbody>
      //       <tr className="row p-1 py-2">
      //         <td className="col-6">Investor Complaint/ Grievances</td>
      //         <td className="col-6">
      //           <p className="mt-5">
      //             Investor can lodge complaint/ grievance against the
      //             Depository/DP in the following ways:
      //           </p>
      //           <ol className="olol">
      //             <li>Electronic mode -</li>
      //             <li>
      //               <a href="https://www.scores.gov.in/scores/Welcome.html">
      //                 SCORES
      //               </a>{" "}
      //               (a web-based centralized grievance redressal system of SEBI)
      //               <ul className="ulul">
      //                 <li className="justify">
      //                   Respective Depository’s web portal dedicated for the
      //                   filing of complaint
      //                 </li>
      //               </ul>
      //             </li>
      //             <li>
      //               Offline mode [details of link to the form to be provided by
      //               DPs]
      //             </li>
      //           </ol>
      //           <p className="mt-5">
      //             The complaints/ grievances lodged directly with the Depository
      //             shall be resolved within 30 days.
      //           </p>
      //         </td>
      //       </tr>
      //       <tr className="row p-1 py-2 bg-light">
      //         <td className="col-6">
      //           Investor Grievance Redressal Committee of Depository
      //         </td>
      //         <td className="col-6">
      //           If no amicable resolution is arrived, then the Investor has the
      //           option to refer the complaint/grievance to the Grievance
      //           Redressal Committee (GRC) of the Depository. Upon receipt of
      //           reference, the GRC will endeavor to resolve the complaint/
      //           grievance by hearing the parties and examining the necessary
      //           information and documents.
      //         </td>
      //       </tr>
      //       <tr className="row p-1 py-2">
      //         <td className="col-6">Arbitration proceedings</td>
      //         <td className="col-6">
      //           The Investor may also avail the arbitration mechanism set out in
      //           the Byelaws and Business Rules/Operating Instructions of the
      //           Depository in relation to any grievance, or dispute relating to
      //           depository services. The arbitration reference shall be
      //           concluded by way of issue of an arbitral award within 4 months
      //           from the date of appointment of arbitrator(s).
      //         </td>
      //       </tr>
      //     </tbody>
      //   </table>
      //   <p className="mt-5">
      //     For the Multi-level complaint resolution mechanism available at the
      //     Depositories please refer to
      //     <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
      //       link.
      //     </a>
      //   </p>

      //   <h2>
      //     Guidance pertaining to special circumstances related to market
      //     activities: Termination of the Depository Participant
      //   </h2>

      //   <table className="table-investorcharter">
      //     <tbody>
      //       <tr className="row p-3">
      //         <td className="col-6">
      //           <b>Type of special circumstances</b>
      //         </td>
      //         <td className="col-6">
      //           <b>Timelines for the Activity/ Service</b>
      //         </td>
      //       </tr>
      //       <tr className="p-3">
      //         <td className="col-6">
      //           <ol className="olol">
      //             <li>
      //               Depositories to terminate the participation in case a
      //               participant no longer meets the eligibility criteria and/or
      //               any other grounds as mentioned in the bye laws like
      //               suspension of trading member by the Stock Exchanges.
      //             </li>
      //             <li>
      //               Participant surrenders the participation by its own wish.
      //             </li>
      //           </ol>
      //         </td>
      //         <td className="col-6">
      //           Client will have a right to transfer all its securities to any
      //           other Participant of its choice without any charges for the
      //           transfer within 30 days from the date of intimation by way of
      //           letter/email.
      //         </td>
      //       </tr>
      //     </tbody>
      //   </table>

      // </>
      <div style={{ marginTop: "20px" }}>
        <a
          href={Investor_Charter_DP}
          target="_blank"
          style={{ color: "black", fontWeight: "bold" }}
          rel="noreferrer"
        >
          Click Here To Download
        </a>
      </div>
    ),
  },
];

const compData = [
  {
    id: 1,
    title: "Complaints",
    content: (
      <>
        <InvestorComplaints />
      </>
    ),
  },
];

const privacyData = [
  {
    id: 1,
    title: "Privacy Policy",
    content: (
      <>
        <PrivacyPolicy />
      </>
    ),
  },
];

const discData = [
  {
    id: 1,
    title: "Disclosure",
    content: (
      <>
        <Disclosure />
      </>
    ),
  },
];

const policyData = [
  {
    id: 1,
    title: "Policies and Procedures",
    content: (
      <>
        <PoliciesAndProcedures />
      </>
    ),
  },
];

const TandCData = [
  {
    id: 1,
    title: "Terms and Conditions",
    content: (
      <>
        <TermsandConditions />
      </>
    ),
  },
];

const BankDetailsTandCData = [
  {
    id: 1,
    title: "Bank Details",
    content: (
      <div style={{ marginTop: "20px" }}>
        <a
          href={Bank_Details}
          target="_blank"
          style={{ color: "black", fontWeight: "bold" }}
          rel="noreferrer"
        >
          Click Here To Download
        </a>
      </div>
    ),
  },
];

const policiesData = [
  {
    id: 1,
    title: "Application Forms",
    content: (
      <>
        <Table tableData={tableData} />
      </>
    ),
  },
];

const riskData = [
  {
    id: 1,
    title: "Risk Disclosure On Derivatives",
    content: (
      <>
        <RiskDisclosure />
      </>
    ),
  },
];

function InvestorCharter() {
  const location = useLocation();
  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className="termsandconditionspage">
        <Helmet>
          <title>Fabits - Investor Charter</title>
          <meta
            name="description"
            content="Regulatory page displaying the investor charter of Shareway Securities Limited"
          />
        </Helmet>
        {/* Investor's Portal */}
        <div>
          <h1 style={{ fontSize: "2em", margin: "20px 0px 0px 0px" }}>
            1. Investor's Portal
          </h1>
          <CharterAccordian Data={Data} />
        </div>
        {/* Investor Complaints */}
        <div>
          <h1 style={{ fontSize: "2em", margin: "30px 0px 0px 0px" }}>
            2. Investor Complaints
          </h1>
          <CharterAccordian Data={compData} />
        </div>
        {/* Privacy */}
        <div>
          <h1 style={{ fontSize: "2em", margin: "30px 0px 0px 0px" }}>
            3. Privacy
          </h1>
          <CharterAccordian Data={privacyData} />
        </div>
        {/* Disclosure */}
        <div>
          <h1 style={{ fontSize: "2em", margin: "30px 0px 0px 0px" }}>
            4. Disclosure
          </h1>
          <CharterAccordian Data={discData} />
        </div>

        {/* Policies and Procedures */}
        <div>
          <h1 style={{ fontSize: "2em", margin: "30px 0px 0px 0px" }}>
            5. Policies and Procedures
          </h1>
          <CharterAccordian Data={policyData} />
        </div>

        {/* Terms and Conditions  */}
        <div>
          <h1 style={{ fontSize: "2em", margin: "30px 0px 0px 0px" }}>
            6. Terms and Conditions
          </h1>
          <CharterAccordian Data={TandCData} />
        </div>

        {/* Bank Details */}
        <div>
          <h1 style={{ fontSize: "2em", margin: "30px 0px 0px 0px" }}>
            7. Bank Details
          </h1>
          <CharterAccordian Data={BankDetailsTandCData} />
        </div>

        {/* Downloads  */}
        <div>
          <h1 style={{ fontSize: "2em", margin: "30px 0px 0px 0px" }}>
            8. Application Forms
          </h1>
          <CharterAccordian Data={policiesData} />
        </div>

        {/* Risks  */}
        <div>
          <h1 style={{ fontSize: "2em", margin: "30px 0px 0px 0px" }}>
            9. Risks
          </h1>
          <CharterAccordian Data={riskData} />
        </div>
      </div>
    </>
  );
}

export default InvestorCharter;
