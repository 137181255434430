import React, { useRef } from "react";
import styles from "./knowledgeHub.module.css";

const KnowledgeHub = () => {
  // Create refs for each FAQ answer section
  const faqRefs = {
    faq1: useRef(null),
    faq2: useRef(null),
    faq3: useRef(null),
    faq4: useRef(null),
    faq5: useRef(null),
    faq6: useRef(null),
    faq7: useRef(null),
    faq8: useRef(null),
    faq9: useRef(null),
    faq10: useRef(null),
    faq11: useRef(null),
    faq12: useRef(null),
    faq13: useRef(null),
  };

  // Scroll to the corresponding FAQ answer
  const scrollToFaq = (faqKey) => {
    if (faqRefs[faqKey] && faqRefs[faqKey].current) {
      // Add the offset class before scrolling
      faqRefs[faqKey].current.classList.add(styles.scrollOffset);

      // Scroll into view with smooth behavior
      faqRefs[faqKey].current.scrollIntoView({ behavior: "smooth" });

      // Remove the offset class after a short delay
      setTimeout(() => {
        faqRefs[faqKey].current.classList.remove(styles.scrollOffset);
      }, 500); // Adjust delay if needed
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.topDiv}>
          <h1>PPF Calculator - Knowledge Hub</h1>
          <p>
            In an era where there is a wide gamut of investment options, the
            Public Provident Fund (PPF) has continually withstood the test of
            time, serving as a stable and reliable financial tool. Understanding
            the essence of PPF and learning how to invest in it can be pivotal
            to your financial planning.
          </p>
          <p>
            In this article, we shall discuss all things PPF, including:
            <ul>
              <li>Features and benefits of PPF investment</li>
              <li>Entry and exit rules</li>
              <li>How the PPF interest is calculated.</li>
            </ul>
          </p>
          <p>
            We shall also shed light on PPF calculators, and discuss how they
            can be instrumental to your investment planning.
          </p>
        </div>
      </div>
      <div className={styles.subInnerContainer}>
        <div className={styles.FAQside}>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq1")}>
            <h2>What is the Public Provident Fund (PPF)?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq2")}>
            <h2>How did the idea for PPF take shape?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq3")}>
            <h2>The salient features of PPF</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq4")}>
            <h2>How does a PPF account work?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq5")}>
            <h2>How is interest calculated on PPF?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq6")}>
            <h2>What are the documents required for joining PPF?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq7")}>
            <h2>How can you open a PPF account?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq8")}>
            <h2>Tax and other benefits of PPF investments</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq9")}>
            <h2>What is a PPF calculator and why should you use it?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq10")}>
            <h2>PPF vs other stable investments - What makes PPF stand out?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq11")}>
            <h2>How can you avail loans against PPF?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq12")}>
            <h2>How can you withdraw funds from your PPF account?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq13")}>
            <h2>PPF - a steady and stable investment option</h2>
          </div>
        </div>

        <div className={styles.FAQquesAns}>
          <div className={styles.FAQ1} ref={faqRefs.faq1}>
            <h1>What is the Public Provident Fund (PPF)?</h1>
            <p>
              The Public Provident Fund is an Indian government-backed long-term
              savings and investment program that was first introduced in the
              country in 1968. Established with the goals of encouraging people
              to save money and giving them a safe and risk-averse way to
              accumulate wealth, the PPF has long been considered one of the
              most preferred investment tools in India. PPF is renowned for its
              ease of use, tax advantages, and the safety net it provides to
              investors.
            </p>
          </div>
          <div className={styles.FAQ2} ref={faqRefs.faq2}>
            <h1>How did the idea for PPF take shape?</h1>
            <p>
              It's important to trace the history of PPF all the way back to the
              beginning in order to fully understand its significance. The
              British first popularised the idea of a provident fund in colonial
              India - as a way to offer government workers retirement benefits.
              As this notion gained prominence , the Indian government
              introduced the PPF program in 1968; since then, the PPF has
              undergone a number of revisions and refinements. 
            </p>
          </div>

          <div className={styles.FAQ3} ref={faqRefs.faq3}>
            <h1>The salient features of PPF</h1>
            <p>
              The Public Provident Fund (PPF) boasts a host of distinctive
              features that cement its status as a premier investment tool in
              India. Here are some of the salient features of PPF:
            </p>
            <ul>
              <li>
                <span className={styles.boldText}>
                  1. Low investment threshold:
                </span>{" "}
                You can start your investment in a PPF account with as little as
                ₹500 per annum. The maximum permissible yearly contribution is
                ₹1.5 lakhs.
              </li>
              <li>
                <span className={styles.boldText}>2. Tenure:</span> PPF accounts
                have a minimum investment tenure of 15 years, which can be
                further extended in blocks of 5 years.
              </li>
              <li>
                <span className={styles.boldText}>3. Return:</span> The interest
                rate for PPF is determined by the Government of India on a
                quarterly basis. The current PPF interest rate is 7.1% pa.
              </li>
              <li>
                <span className={styles.boldText}>4. Partial withdrawals:</span>{" "}
                After the completion of 5 years from the date of account
                opening, partial withdrawals are permitted from a PPF account.
              </li>
              <li>
                <span className={styles.boldText}>5. Tax benefits:</span> PPF
                investments, interest income, and withdrawal are exempt from
                income tax.
              </li>
              <li>
                <span className={styles.boldText}>6. Security:</span> Since the
                PPF is backed by the Indian government, there is minimal, if
                any, risk of default; this feature makes PPF a relatively safe
                investment option.
              </li>
              <li>
                <span className={styles.boldText}>7. Nomination facility:</span>{" "}
                PPF accounts have a nomination facility to ensure that your
                investment is safe and accessible by the person designated by
                you.
              </li>
            </ul>
          </div>

          <div className={styles.FAQ4} ref={faqRefs.faq4}>
            <h1>How does a PPF account work?</h1>
            <p>
              In this section, we shall discuss the workings of the Public
              Provident Fund.
            </p>
            <ul>
              <li>
                <span className={styles.boldText}> 1. Account opening:</span> In
                order to start your PPF investment, you must open a PPF account
                at a designated bank or post office. A PPF account can be opened
                with a deposit of ₹500, making it an affordable and accessible
                investment avenue.
              </li>
              <li>
                <span className={styles.boldText}>
                  2. Deposits and Contributions:
                </span>{" "}
                The maximum annual contribution you can make to your PPF account
                is ₹1.5 lakhs; you can contribute this sum in one go or in
                installments.
              </li>
              <li>
                <span className={styles.boldText}>
                  3. Tenure and Withdrawals:
                </span>{" "}
                The PPF has a fixed 15-year tenure. However, you can start
                making partial withdrawals after the completion of five years
                from the date of opening your PPF account.
              </li>
              <li>
                <span className={styles.boldText}>4. Exit:</span> You can exit
                the PPF after 15 years, or choose to extend your account's
                tenure in blocks of five years post the original term.
              </li>
              <li>
                <span className={styles.boldText}>5. Penalty:</span> If you
                don't contribute a minimum amount of ₹500 to your PPF account on
                an annual basis, you can make the contribution in the next
                fiscal year. The late payment shall, however, attract a nominal
                fee of ₹50.
              </li>
            </ul>
          </div>

          <div className={styles.FAQ5} ref={faqRefs.faq5}>
            <h1>How is interest calculated on PPF?</h1>
            <p>
              If you're wondering how interest is calculated on PPF, then this
              section should suffice to answer your queries. The interest for
              PPF is determined by the Government of India on a quarterly basis,
              and the range is generally between 7-8% per annum. Compounded
              annually, the PPF interest rate is usually higher than the return
              from several other stable investment tools. The current PPF
              interest rate is 7.1%.
            </p>
            <p>Here's the formula used to determine PPF returns:</p>
            <p>
              <code>{`F = P[({(1 + i) ^ n}-1)/i]`}</code>
            </p>
            <p>Where:</p>
            <ul>
              <li>F = Maturity proceeds of the PPF</li>
              <li>P = Annual installments</li>
              <li>n = Number of years</li>
              <li>i = Rate of interest/100</li>
            </ul>
            <p>
              It is worth noting here that the interest you get on your PPF
              account is completely exempt from tax under Section 80 C of the
              Income Tax Act, 1961. By investing in PPF, you can enjoy a wide
              gamut of tax benefits, but more on that in a bit.
            </p>
          </div>
          <div className={styles.FAQ6} ref={faqRefs.faq6}>
            <h1>Who can join PPF?</h1>
            <p>
              If you wish to join the Public Provident Fund, but aren't sure
              whether you're eligible, here is the list of eligibility criteria
              for opening a PPF account:
            </p>
            <ul>
              <li>
                <span className={styles.boldText}>Nationality:</span> Indian
              </li>
              <li>
                <span className={styles.boldText}>Age:</span> 18 years (Minors
                can open a PPF account but it shall be managed by a major)
              </li>
              <li>
                <span className={styles.boldText}>Number of accounts:</span> One
                account per individual
              </li>
              <li>
                <span className={styles.boldText}>Extension:</span> Allowed in
                chunks of five years post 15 years
              </li>
            </ul>
            <p>
              It is noteworthy that Non-resident Indians (NRIs) and Hindu
              Undivided Families (HUFs) are not permitted to open a PPF account.
              The limited restrictions on the eligibility for entry make PPF a
              viable option for a wide range of demographics. Irrespective of
              your age and occupation, you can choose PPF as a stable long-term
              investment avenue.
            </p>
            <p>
              Not only are PPF investments largely risk-free and secured (owing
              to government backing), they can also help drive financial
              discipline and encourage a habit of regular savings and investing.
            </p>
          </div>

          <div className={styles.FAQ7} ref={faqRefs.faq7}>
            <h1>What are the documents required for joining PPF?</h1>
            <p>
              Opening a PPF account is easy and swift; here are the documents
              you should keep handy for the process:
            </p>
            <ul>
              <li>Duly filled and signed PPF account opening form</li>
              <li>Duly filled and signed Nomination form</li>
              <li>Valid photo identity proof</li>
              <li>Valid address proof</li>
              <li>Recent passport-sized photographs</li>
            </ul>
            <p>
              For opening a PPF account for minors, the KYC documents pertaining
              to their guardian shall be required.
            </p>
          </div>

          <div className={styles.FAQ8} ref={faqRefs.faq8}>
            <h1>How can you open a PPF account?</h1>
            <p>
              Should you decide to open a PPF account, there are two modes
              available - online and offline. Here is the process for both modes
              of opening a PPF account.
            </p>

            <p>
              <strong>Steps to open a PPF account online:</strong>
            </p>
            <ul>
              <li>
                Select the bank through which you wish to open a PPF account and
                visit their official website.
              </li>
              <li>Search the option for opening a PPF account.</li>
              <li>Fill the PPF account opening form online.</li>
              <li>
                Upload the required documents (the list has been mentioned in
                the preceding section).
              </li>
              <li>
                Pay the initial deposit for opening a PPF account through online
                banking services (minimum deposit: ₹500).
              </li>
              <li>
                Await confirmation from the bank about the successful opening of
                your PPF account.
              </li>
            </ul>
          </div>
          <div className={styles.FAQ9} ref={faqRefs.faq9}>
            <h1>Tax Benefits and Other Benefits of PPF Investments</h1>
            <p>
              One of the main reasons why PPF has remained an extremely popular
              investment option for over five decades is the myriad of benefits
              it offers. Here are the major benefits of PPF investments:
            </p>
            <ul>
              <li>
                <span className={styles.boldText}>Tax exemptions galore:</span>{" "}
                PPF is categorised as an EEE (Exempt-Exempt-Exempt) investment
                owing to its triple tax benefits. Not only are your investments
                into PPF tax free, but the interest earned from PPF investments
                and the maturity proceeds (minimum tenure of 15 years) are also
                exempt from tax. It is worth noting here that Section 80C of the
                Income Tax Act, 1961, permits a maximum annual deduction of ₹1.5
                lakhs; this section includes deductions for contributions made
                to a PPF account.
              </li>
              <li>
                <span className={styles.boldText}>Security and Safety:</span>{" "}
                PPF is a savings plan backed by the Government of India;
                therefore, PPF investments are largely safe, particularly
                compared to other investment options.
              </li>
              <li>
                <span className={styles.boldText}>Stable returns:</span> The
                rate of interest for PPF investments remains relatively stable.
                Unlike market-linked investment instruments, PPF offers a stable
                return.
              </li>
              <li>
                <span className={styles.boldText}>
                  Flexibility to start with small investments:
                </span>{" "}
                Your investment into a PPF account can start from ₹500 per
                annum. There is, thus, no requirement for large investments into
                PPF. You can choose to contribute funds into your PPF account in
                line with your financial plan.
              </li>
              <li>
                <span className={styles.boldText}>Loan Facility:</span> After
                the completion of three years from the date of opening your PPF
                account, you can avail of loans against your PPF investments.
                The maximum permissible loan is 25% of the balance in your PPF
                account at the end of 2 years.
              </li>
              <li>
                <span className={styles.boldText}>
                  Freedom from market-related risks:
                </span>{" "}
                Another excellent benefit of PPF investments is the absence of
                any market-related risks. Irrespective of the state of the
                nation's capital market, you shall continue to receive interest
                on your PPF account in line with the prevailing PPF interest
                rate as declared by the government.
              </li>
            </ul>
          </div>

          <div className={styles.FAQ10} ref={faqRefs.faq10}>
            <h1>What is a PPF Calculator and Why Should You Use It?</h1>
            <p>
              In an age defined by technology and precision, financial planning
              has become extremely important and efficient. One of the tools you
              can add to your financial planning armoury is a PPF calculator.
            </p>
            <p>
              A PPF calculator can help you determine the amount you should
              invest in your PPF account. In addition to this, you can assess
              the returns on your PPF account based on a certain monthly/annual
              investment. Here are the major benefits of using a PPF calculator:
            </p>
            <ul>
              <li>
                <span className={styles.boldText}>Accurate forecasts:</span> A
                PPF calculator can help you create realistic financial goals by
                providing accurate forecasts of the maturity amount/s based on
                different contributions over varying tenures.
              </li>
              <li>
                <span className={styles.boldText}>
                  Efficient investment strategy:
                </span>{" "}
                With the application of a PPF calculator, you can tailor your
                investment strategy in line with your financial goals by
                experimenting with different investment amounts and tenures.
              </li>
              <li>
                <span className={styles.boldText}>Informed Decisions:</span> By
                providing information on the impact of fluctuating interest
                rates on the returns of PPF investments, a PPF calculator can
                help you make well-informed and timely investment decisions.
              </li>
              <li>
                <span className={styles.boldText}>Time saving:</span> With the
                help of a PPF calculator, you can save time and ensure that your
                investments into a PPF account are aligned with your financial
                goals.
              </li>
            </ul>
          </div>

          <div className={styles.FAQ11} ref={faqRefs.faq11}>
            <h1>PPF vs Other Stable Investments - What Makes PPF Stand Out?</h1>
            <p>
              In addition to the Public Provident Fund, there are several other
              stable investment options at your disposal. When compared to the
              aforementioned investment options, however, PPF boasts a host of
              benefits that set it apart from the competition:
            </p>
            <ul>
              <li>
                <span className={styles.boldText}>
                  Bank Fixed Deposits (FDs):
                </span>{" "}
                While bank FDs have taxable interest, PPF gives tax-free
                returns. Furthermore, PPF investments encourage long-term
                savings and financial discipline owing to their long duration
                (15 years) which is generally longer than bank FDs.
              </li>
              <li>
                <span className={styles.boldText}>
                  National Savings Certificate (NSC):
                </span>{" "}
                PPF permits extensions in intervals of five years following
                maturity, whereas NSC has a set tenure of 5 years which cannot
                be extended.
              </li>
              <li>
                <span className={styles.boldText}>
                  Senior Citizens' Savings Scheme (SCSS):
                </span>{" "}
                PPF is open to a wide range of investors, whereas SCSS is
                reserved for senior citizens. It is also worth noting here that
                the minimum investment required for SCSS is ₹1,000 which is
                twice that of the corresponding amount for PPF, that is, ₹500.
              </li>
            </ul>
          </div>

          <div className={styles.FAQ12} ref={faqRefs.faq12}>
            <h1>How Can You Avail Loans Against PPF?</h1>
            <p>
              Another noteworthy feature of the Public Provident Fund (PPF) is
              the provision for loans against the accumulated balance. After the
              completion of three years from the date of opening your account,
              you are eligible to avail a loan against the balance in the
              account at the end of the second year. The amount of such a loan
              can be 25% of the aforementioned balance.
            </p>
            <p>
              Notably, the facility for loans against PPF is available only
              between the third and sixth year of the operation of a PPF
              account. Such loans attract an interest rate of 1% per annum, and
              their repayment tenure is 36 months. Failure to repay the loan
              within 36 months entails a rise in the interest rate from 1% to
              6%.
            </p>
          </div>

          <div className={styles.FAQ13} ref={faqRefs.faq13}>
            <h1>How Can You Withdraw Funds from Your PPF Account?</h1>
            <p>
              Since the Public Provident Fund has a minimum tenure of 15 years,
              you can withdraw the entire balance in your PPF account only upon
              the maturity of the account. However, PPF also permits partial
              withdrawals on the completion of 6 years from the date of account
              opening.
            </p>
            <p>
              At the end of the sixth year of your PPF account's operation, you
              can withdraw up to 50% of the balance accumulated in the account
              at the end of the fourth year. It is also worth noting here that
              you can make such withdrawals only once in a financial year.
            </p>
          </div>

          <div className={styles.FAQ14} ref={faqRefs.faq14}>
            <h1>PPF - A Steady and Stable Investment Option</h1>
            <p>
              After our thorough investigation into the Public Provident Fund
              (PPF) and the useful applications of a PPF calculator, we can
              conclude that the PPF is a stable and secure financial instrument,
              particularly for long-term investments. Armed with a plethora of
              tax benefits, consistent returns, and special features like the
              facility for loans and withdrawals, PPF stands out as a reliable
              investment tool supported by the government.
            </p>
            <p>
              If you're looking to invest in the PPF, a PPF calculator is a
              handy tool that can help you ensure accuracy in your investment
              planning. Furthermore, you can save time and effort in the
              process, and ensure that your PPF investments are in line with
              your financial plan and investment strategy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeHub;
