import useMediaQuery from "../hooks/useMediaQuery";
import PropsCarousel from "./TaxFiling/PropsCarousel";
import "./TaxFiling.css";
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "react-modal";
import MyModal from "./Model";

Modal.setAppElement("#root");

const TaxFiling = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const recaptcha = useRef();

  const [cooldown, setCooldown] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    terms: true,
  });

  useEffect(() => {
    if (cooldown) {
      // Start the cooldown timer
      const cooldownTimer = setTimeout(() => {
        setCooldown(false);
      }, 10000); // 1 Minute cooldown

      // Clear the timer when the component unmounts or cooldown is reset
      return () => clearTimeout(cooldownTimer);
    }
  }, [cooldown]);

  const [selectPlan, serSelectPlan] = useState("");

  const [errors, setErrors] = useState({});
  // const dialogRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    clevertap.event.push("Page Viewed", {
      Page_name: "Tax Filing Landing Page",
    });
  }, []);

  const openDialog = (plan) => {
    serSelectPlan(plan);
    // dialogRef.current.showModal();
    setIsModalOpen(true);
  };

  const closeDialog = () => {
    setFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      terms: true,
    });
    // dialogRef.current.close();
    setIsModalOpen(false);
  };

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const addScript = () => {
    const script = document.createElement("script");
    script.innerHTML = `
       !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '695545221662123');
fbq('track', 'PageView');
fbq('track', 'Lead');
      `;
    // document.head.appendChild(script);

    const noscript = document.createElement("noscript");
    noscript.innerHTML = `
        <img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=695545221662123&ev=PageView&noscript=1"
/>
      `;
    document.head.appendChild(script, noscript);
  };

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validate = () => {
    const newErrors = {};

    if (!/^[a-zA-Z\s]*$/.test(formData.firstName)) {
      newErrors.firstName = "First name can only contain letters.";
    } else if (formData.firstName.length < 3) {
      newErrors.firstName = "First name must be at least 3 characters long.";
    }

    if (!/^[a-zA-Z\s]*$/.test(formData.lastName)) {
      newErrors.lastName = "Last name can only contain letters.";
    }

    if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be exactly 10 digits.";
    }

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      const res = await fetch(
        "https://devapi.fabits.com/customerservice/api/captcha/verify",
        {
          method: "POST",
          body: JSON.stringify({ captchaValue }),
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const data = await res.json();

      if (data.success) {
        const newErrors = validate();
        if (Object.keys(newErrors).length === 0) {
          const payload = {
            ...formData,
            phoneNumber: "+91" + formData.phoneNumber,
            planSelected: selectPlan,
            planAmount: 0.0,
            isUserExists: false,
          };

          clevertap.event.push("Clicked Button", {
            Action: "Tax Filing Submitted",
            Plan: selectPlan,
          });

          clevertap.profile.push({
            Site: {
              Name: payload.firstName + " " + payload.lastName,
              Phone: payload.phoneNumber,
              Email: payload.email,
              "MSG-email": true, // Enable email notifications
              "MSG-push": true, // Enable push notifications
              "MSG-sms": true, // Enable sms notifications
              "MSG-whatsapp": true, // Enable whatsapp notifications
            },
          });

          const response = await axios.post(
            `${API_URL}/customerservice/api/taxFiling`,
            payload
          );

          if (response.status === 201) {
            closeDialog();
            addScript();
            toast.success(
              "Grab a coffee! Our experts will call you in the next 24 hours to get started with the filing process."
            );

            setFormData({
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              terms: true,
            });
          } else {
            closeDialog();

            setFormData({
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              terms: true,
            });

            toast.error("Something went wrong. Try again later.");
          }
        } else {
          setErrors(newErrors);
        }
      } else {
        alert("reCAPTCHA validation failed!");
      }
    }
  };

  const plans = [
    {
      id: 1,
      level: "Basic",
      for: isDesktop
        ? "For income via salary < 50 LPA, interest, or rent."
        : "For professionals with income lesser than 50 LPA",
      props: [
        "Salaried professionals",
        "Non-salaried professionals",
        "2 or lesser income streams",
        "Rental or interest income",
      ],
      price: 1499,
      discount: 40,
      actualPrice: 2499,
      icon: "/TaxFiling/basic.svg",
      href: "https://wa.me/+918217801975?text=I%20want%20to%20know%20more%20about%20the%20Basic%20ITR%20filing%20service%20on%20Fabits",
      svg: "/TaxFiling/basic_circle.svg",
    },
    {
      id: 2,
      level: "Advanced",
      for: "For capital gains/losses, plus all in Basic",
      props: [
        "Capital gains/losses from MFs, stocks, property, lottery, etc",
        "Long-term investors",
        "Traders",
        "More than 2 income streams",
      ],
      price: 5999,
      discount: 40,
      actualPrice: 9999,
      icon: "/TaxFiling/advanced.svg",
      href: "https://wa.me/+918217801975?text=I%20want%20to%20know%20more%20about%20the%20Advanced%20ITR%20filing%20service%20on%20Fabits",
      svg: "/TaxFiling/advanced_circle.svg",
    },
    {
      id: 3,
      level: "Pro",
      for: "For salary above 50 LPA, ESOPs/RSUs, plus all in Advanced",
      props: [
        "For income above 50 LPA",
        "For ESOPs/RSU holders",
        "For directors, and CXOs",
        "PSU employees",
      ],
      price: 9999,
      discount: 40,
      actualPrice: 16599,
      icon: "/TaxFiling/pro.svg",
      href: "https://wa.me/+918217801975?text=I%20want%20to%20know%20more%20about%20the%20Pro%20ITR%20filing%20service%20on%20Fabits",
      svg: "/TaxFiling/pro_circle.svg",
    },
  ];

  function formatPrice(price) {
    // Ensure the price is a number
    if (isNaN(price)) {
      return "Invalid number";
    }

    // Convert the number to a string and split it into the integer and decimal parts
    let parts = price.toString().split(".");

    // Use a regular expression to add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the integer and decimal parts back together
    return parts.join(".");
  }

  return (
    <div id="taxFiling">
      <Helmet>
        <title>Fabits - Tax Filing</title>
        <meta name="description" content="Reach out to us for tax filing" />
      </Helmet>
      <div className="taxFiling__referral">
        <div className="taxFiling__referral-context">
          <div>
            <span>
              <p style={{ color: "#C4CADC" }}>ITR for FY 2023-24</p>
            </span>
            <h1 className="text-white">Filing taxes should not be taxing</h1>
            <p style={{ color: "#C4CADC" }}>
              Grab a coffee, and chill! Let our tax experts do the hard work.
            </p>
            <div>
              <MyModal isOpen={isModalOpen} onRequestClose={closeDialog}>
                <h3>Hire a professional!</h3>
                <p>Let licensed CAs handle your ITR filing for FY23-24</p>

                <div className="seprator" />

                <form className="form-container">
                  <div className="form-grid">
                    <div className="form-group">
                      <label htmlFor="firstName">First name:</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="eg. Dhruv"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                      {errors.firstName && (
                        <span className="error">{errors.firstName}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last name:</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="eg. Sharma"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                      {errors.lastName && (
                        <span className="error">{errors.lastName}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone number:</label>
                      <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="eg. 9871013579"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                      />
                      {errors.phoneNumber && (
                        <span className="error">{errors.phoneNumber}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="user_email">Email:</label>
                      <input
                        type="email"
                        id="user_email"
                        name="email"
                        placeholder="eg. dhruv.s@email.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>
                  </div>

                  <div className="checkbox-wrapper-46">
                    <input
                      checked={formData.terms}
                      onChange={handleChange}
                      type="checkbox"
                      name="terms"
                      id="cbx-46"
                      className="inp-cbx"
                    />
                    <label
                      for="cbx-46"
                      className="cbx"
                      style={{ display: "flex" }}
                    >
                      <span>
                        <svg viewBox="0 0 12 10" height="10px" width="12px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span> I wish to receive communication from Fabits</span>
                    </label>
                  </div>

                  <div className="seprator" />
                </form>

                <div
                  className="modal-btn-layout"
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  <div>
                    <ReCAPTCHA
                      ref={recaptcha}
                      sitekey={`6LdJW-spAAAAANJ9T03r-2mv0bOy1dTL05_Wm3rh`}
                    />
                  </div>
                  <div className="button_container" style={{ height: "40px" }}>
                    <button onClick={closeDialog} className="close-button">
                      Close
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!formData.terms}
                      className="callback-button"
                    >
                      Request Callback
                    </button>
                  </div>
                </div>
              </MyModal>
              <button
                className="button--secoundary"
                onClick={() => openDialog("")}
              >
                File ITR now
              </button>
            </div>
          </div>
          <div className="taxFiling__referral-image">
            <img src="/TaxFiling/fno-referral.svg" alt="tax planning" />
          </div>
        </div>
      </div>

      <div className="taxFiling__about">
        <h1 className="about--heading text-center">
          Worried about the impending ITR deadline?
        </h1>
        <p className="sub-heading text-center">
          We’ve got you covered for all things ITR filing!
        </p>
        <div className="taxFiling__about--carousel">
          <div className="taxFiling__about--carouselDesktop">
            <img
              src="/TaxFiling/hand.svg"
              alt="tax filing"
              style={{ marginLeft: "2px" }}
            />
            <div>
              <h3>100% personalised</h3>
              <p className="sub-heading">
                1:1 sessions for tax review & suggestions; no generic stuff
              </p>
            </div>
          </div>
          <div className="taxFiling__about--carouselDesktop">
            <img
              src="/TaxFiling/mind.svg"
              alt="tax filing"
              style={{ marginLeft: "2px" }}
            />
            <div>
              <h3>Dedicated experts</h3>
              <p className="sub-heading">
                Get a dedicated licensed CA to do your taxes end to end
              </p>
            </div>
          </div>
          <div className="taxFiling__about--carouselDesktop">
            <img
              src="/TaxFiling/goal.svg"
              alt="tax filing"
              style={{ marginLeft: "2px" }}
            />
            <div>
              <h3>Highly optimised</h3>
              <p className="sub-heading">
                ITR filing to maximise income & reduce tax liability
              </p>
            </div>
          </div>
          <div className="taxFiling__about--carouselDesktop">
            <img
              src="/TaxFiling/customercare.svg"
              alt="tax filing"
              style={{ marginLeft: "2px" }}
            />
            <div>
              <h3>Post-filing support</h3>
              <p className="sub-heading">
                Reports & support for all things ITR even after filing
              </p>
            </div>
          </div>

          <div className="taxFiling__about--carouselMobile">
            <PropsCarousel />
          </div>
        </div>
      </div>

      <div className="tax_container">
        <div className="header">
          <h3 className="about--heading">Plans for everyone</h3>

          <p className="subtitle">Reduce liability, maximise income!</p>
        </div>
        <div className="plans-grid" id="plans">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`plan-card ${plan.level.toLowerCase()}`}
              style={{
                background:
                  plan.level === "Basic"
                    ? "radial-gradient(77.04% 68.72% at 16.46% 11.89%, rgba(69, 133, 254, 0.12) 0%, rgba(69, 133, 254, 0.00) 100%)"
                    : plan.level === "Advanced"
                    ? "radial-gradient(77.04% 68.72% at 16.46% 11.89%, rgba(214, 108, 216, 0.12) 0%, rgba(214, 108, 216, 0.00) 100%)"
                    : "radial-gradient(77.04% 68.72% at 16.46% 11.89%, rgba(189, 106, 60, 0.12) 0%, rgba(189, 106, 60, 0.00) 100%)",
              }}
            >
              <div className="plan-header">
                <img src={plan.icon} alt={plan.level} />

                <div>
                  <h3 className={`plan-level ${plan.level.toLowerCase()}-text`}>
                    {plan.level}
                  </h3>
                  <p className={`plan-for ${plan.level.toLowerCase()}-text`}>
                    {plan.for}
                  </p>
                </div>
              </div>
              <div className="plan-body">
                <p
                  className={`plan-description ${plan.level.toLowerCase()}-text`}
                >
                  This plan is perfect for
                </p>
                <ul className="plan-props">
                  {plan.props.map((prop, i) => (
                    <li key={prop + i} className="plan-prop">
                      <img
                        src={plan.svg}
                        alt={plan.level}
                        className="plan-icon"
                      />
                      {prop}
                    </li>
                  ))}
                </ul>
                <div className="plan-footer">
                  <h1 className={`plan-price ${plan.level.toLowerCase()}-text`}>
                    &#8377;{formatPrice(plan.price)}
                  </h1>
                  <span
                    className={`plan-actual-price ${plan.level.toLowerCase()}-text`}
                  >
                    &#8377; {formatPrice(plan.actualPrice)}
                    <img
                      className="cross-icon"
                      src="/TaxFiling/cross.svg"
                      alt="cross"
                    />
                  </span>
                  <span className="plan-discount">(-{plan.discount}%)</span>
                </div>
                <p className={`plan-fee ${plan.level.toLowerCase()}-text`}>
                  One time service fee, inclusive of GST
                </p>
                <button
                  className={`select-button ${plan.level.toLowerCase()}`}
                  onClick={() => openDialog(`${plan.level}`)}
                >
                  Select {plan.level} Plan
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="taxFiling__about">
        <div className="taxFiling__about--fabits">
          <div>
            <div>
              <h1 className="heading">What is Fabits?</h1>
              <p className="sub-heading">
                Fabits is a wealth-tech platform on a mission to make financial
                freedom & wellness accessible to all. F&O is our brand new
                offering for customers who seek high risk & high rewards.
              </p>

              <p>
                Get a personal (finance) assistant on your fingertips; sign up
                on Fabits now!
              </p>
            </div>
            <div className="taxFiling__about--video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/70DiXsiG5Tw?si=0ZHsWBhdOcDUHUl0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div className="taxFiling__about--props">
            <div>
              <div>
                <img src="/TaxFiling/men.svg" alt="Fabits" />
              </div>
              <div>
                <h3>Decades of Wealth Management</h3>
                <p className="sub-heading">
                  25+ years of experience in planning & managing wealth for the
                  ultra rich
                </p>
              </div>
            </div>
            <div>
              <div>
                <img src="/TaxFiling/women.svg" alt="Fabits" />
              </div>
              <div>
                <h3>Guiding Your Wealth</h3>
                <p className="sub-heading">
                  Constant support & hand holding, because money defines the way
                  you live life
                </p>
              </div>
            </div>
            <div>
              <div>
                <img src="/TaxFiling/desk.svg" alt="Fabits" />
              </div>
              <div>
                <h3>Tech-Driven Financial Planning</h3>
                <p className="sub-heading">
                  Humane financial planning with experts, assisted by deep tech
                  models for accuracy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="taxFiling__bottom">
        <h1 className="text-center">Confused which plan to pick?</h1>
        <p className="text-center">
          Get in touch with us for complete, dedicated assistance
        </p>
        <button
          className="button--secoundary"
          onClick={() => {
            window.open(
              "https://wa.me/+918217801975?text=I%20want%20to%20know%20more%20about%20the%20ITR%20filing%20service%20on%20Fabits."
            );
            clevertap.event.push("Clicked Button", {
              Action: "Get in touch",
              Page_name: "Tax Filing Landing Page",
            });
          }}
        >
          Get in touch
        </button>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        style={{ minWidth: "400px" }}
      />
    </div>
  );
};

export default TaxFiling;
