import React, { useRef } from "react";
import styles from "./knowledgeHub.module.css";

const KnowledgeHub = () => {
  // Create refs for each FAQ answer section
  const faqRefs = {
    faq1: useRef(null),
    faq2: useRef(null),
    faq3: useRef(null),
    faq4: useRef(null),
  };

  // Scroll to the corresponding FAQ answer
  const scrollToFaq = (faqKey) => {
    if (faqRefs[faqKey] && faqRefs[faqKey].current) {
      // Add the offset class before scrolling
      faqRefs[faqKey].current.classList.add(styles.scrollOffset);

      // Scroll into view with smooth behavior
      faqRefs[faqKey].current.scrollIntoView({ behavior: "smooth" });

      // Remove the offset class after a short delay
      setTimeout(() => {
        faqRefs[faqKey].current.classList.remove(styles.scrollOffset);
      }, 500); // Adjust delay if needed
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.topDiv}>
          <h1>NPS Calculator - Knowledge Hub</h1>
          <p>
            The Central Government's social security program is called the
            National Pension Scheme (NPS). Employees in the public, private, and
            even unorganized sectors are eligible for this pension plan, with
            the exception of those in the military forces. It is a long-term,
            voluntary investment plan for retirement that is governed by the
            Central Government and the Pension Fund Regulatory and Development
            Authority (PFRDA).
          </p>
          <p>
            The scheme encourages people to invest in a pension account at
            regular intervals during the course of their employment. The
            subscribers may withdraw a certain portion of the corpus upon
            retirement. After you retire, the balance will be paid to you as an
            NPS account holder as a monthly pension. For those who have a low
            tolerance for risk and wish to start saving for retirement early,
            the NPS is a viable option. Undoubtedly, having a steady pension
            during your retirement years can be beneficial, particularly for
            those who leave private sector employment.
            <br />
            <br />
          </p>
          <p>
            <strong>Eligibility criteria:</strong> All Indian nationals who are
            at least 18 years old but not older than 60 are permitted to invest
            in the pension plan, according to the NPS regulations. In order to
            begin investing in the NPS system, applicants must also adhere to
            the Know-Your-Customer (KYC) regulations.
            <br /> With the help of the National Pension Scheme (NPS)
            calculator, one may determine how much of a pension and provisional
            lump payment an NPS subscriber can expect upon retirement depending
            on their monthly contributions, the annuity they selected, the
            predicted rate of return on their investments, and the annuity
            itself. The NPS calculator does not guarantee the precise amount; it
            merely shows the approximate pension.
          </p>
        </div>
      </div>
      <div className={styles.subInnerContainer}>
        <div className={styles.FAQside}>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq1")}>
            <h2>What is the NPS Calculator?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq2")}>
            <h2>How to use the NPS calculator?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq3")}>
            <h2>Formula for NPS Calculation with Example</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq4")}>
            <h2>Frequently asked questions(FAQs)</h2>
          </div>
        </div>

        <div className={styles.FAQquesAns}>
          <div className={styles.FAQ1} ref={faqRefs.faq1}>
            <h1>What is the NPS Calculator?</h1>
            <p>
              An online tool that can assist in determining the maturity corpus
              for your NPS donations is the NPS calculator. It takes into
              account variables like your age, investment amount, and investing
              style (moderate, conservative, or aggressive). It determines the
              pension amount and the maturity value of your investments based on
              these inputs. <br />
              Using the NPS calculator, you can also determine how much tax
              savings you can receive from investing in NPS. That's covered by
              sections 80CCD(1) and 80CCD(1B) of the Income Tax Act. In
              addition, your retirement corpus is tax-free, and you pay no
              capital gains tax on the lump sum you receive from retirement.
            </p>
            <h4 style={{ marginTop: "10px" }}>
              Benefits of National Pension Scheme Calculator
            </h4>
            <p>
              Here are some of the benefits of National Pension System
              calculator:
            </p>
            <ul>
              <li>
                Calculating your expected retirement corpus and pension amount
                in the future is made simple by it.
              </li>
              <li>
                Your money is distributed across various NPS asset classes,
                including government securities, debt, and equities, when you
                invest in the NPS. Additionally, NPS fund managers look after
                your funds. It can be difficult to calculate returns for a
                combination of asset types on your own these days.
              </li>
              <li>
                You may quickly determine your maturity corpus for a variety of
                investment strategies, including aggressive, moderate, and
                conservative, by using this calculator. Additionally, you
                receive a unique option for NPS investing that allows you to
                enter your expected interest rate and contribution amount to
                determine your total maturity value.
              </li>
              <li>
                Any laptop or mobile device with internet connectivity can use
                the online NPS calculator.
              </li>
              <li>
                An automated tool called the NPS calculator assists investors in
                estimating the corpus generated by NPS investments and computing
                NPS returns depending on different situations. Because the
                calculator can perform scenario analysis, investors are able to
                estimate the lump amount and annuities that they may receive
                based on the decisions they make.
              </li>
              <li>
                Investors are given the choice to assess the NPS investment
                amount needed to reach their ideal retirement corpus.
              </li>
              <li>
                The Income Tax Act's Section 80CCD(1) provides NPS tax benefits.
                The amount of tax you would save by investing in NPS is
                displayed by the NPS calculator.
              </li>
              <li>
                Handles the retirement calculations, which aids in overall
                financial planning. After that, investors are free to
                concentrate on their other financial objectives.
              </li>
            </ul>
          </div>

          <div className={styles.FAQ2} ref={faqRefs.faq2}>
            <h1>How to use the NPS calculator?</h1>
            <p>
              Investors can use the online NPS calculator to determine the
              maturity value or the monthly investment needed to reach a chosen
              corpus value when calculating their NPS annuity.
            </p>
            <h4 className={styles.stepsTitle}>
              Steps to Calculate the Required Monthly Investment:
            </h4>
            <p>
              This is one of the few NPS calculators available in India that
              lets users calculate how much money needs to be invested to meet a
              specific retirement savings target. This is helpful for customers
              who want to know the investments in pension wealth that are
              required to reach a pre-established retirement goal amount. The
              following are the actions to be taken while utilizing the National
              Pension Scheme calculator in this manner.
            </p>

            <ul className={styles.stepsList}>
              <li>
                <strong>Step 1 – Enter the goal amount</strong>
                <br />
                Enter the desired amount you wish to save for your retirement.
              </li>
              <li>
                <strong>Step 2 – Select investment strategy/growth rate</strong>
                <br />
                Here, investors can get their desired quantity based on their
                risk-profile-appropriate investment approach. Aggressive,
                moderate, conservative, and bespoke risk profiles are available
                for users to select from. While the personalized risk profile
                gives investors the ability to project their own rate of return
                based on their perspective for the future, the pre-determined
                risk profiles provide the projected rate of return from each
                strategy.
              </li>
              <li>
                <strong>Step 3 – Age</strong>
                <br />
                Users can enter their current age in this input box, which is
                another crucial parameter for the NPS return calculator to
                utilize when calculating their retirement.
              </li>
            </ul>
            <p>
              <strong>Result:</strong>
              <br />
              The calculator determines the monthly investment needed to reach
              your goal amount at maturity based on the input entered in the
              first three phases. Users can also see this graphically via a pie
              chart, which helps them estimate how much they have invested and
              how much interest they have made over time. The investor can also
              see how much money they have saved over the course of the
              investment period by using the calculator to make NPS investments.
              <br />
              <br />
              Additionally, users can perform scenario analysis to modify the
              target amount to account for inflation. The user can experiment
              with several inflation scenarios here, ranging from 4% to 8%. With
              this feature, customers can modify their investments in
              anticipation of the impact of inflation on their retirement fund.
            </p>
          </div>

          <div className={styles.FAQ3} ref={faqRefs.faq3}>
            <h1>Formula for NPS Calculation with Example</h1>
            <p>
              The National Pension System calculator underlying engine uses the
              inputs provided by the user as seen in the steps of the calculator
              to compute the maturity value of the investment.
            </p>
            <h4 className={styles.stepsTitle}>
              The Formula for NPS Calculation:
            </h4>
            <p>Maturity value (MV) = P x (1 + R/N) ^ NT</p>
            <p>
              where,
              <br />
              P Principal invested
              <br />
              R Assumed rate of return or the expected rate of return
              <br />
              N Number of times the growth rate compounds
              <br />T Tenure of the investment
            </p>
            <h4 className={styles.stepsTitle}>Example for NPS Calculation:</h4>
            <p>
              To understand the NPS formula better, let us look at an example –
            </p>
            <p>
              Rahul, who is aged 25, wants to use the NPS calculator as he is
              looking to invest in NPS for his retirement years. He has the
              ability to invest Rs 5,000 per month and has an aggressive
              investment strategy (Ability to generate 14% return) over the
              investment horizon of 35 years (retirement age 60 – current age).
            </p>
            <p>
              The tool, based on the inputs given above, will show the following
              results:
            </p>
            <ul>
              <li>Principal invested = Rs 21 lakhs</li>
              <li>Gains made on the principal invested = Rs 5.41 crore</li>
              <li>
                Total maturity value = Rs 5.62 crore (principal invested of Rs
                21 lakhs + gains made of Rs 5.41 crore)
              </li>
              <li>
                Lump sum withdrawn @40%, which means he has withdrawn 60% of the
                money = Rs 3.37 crore
              </li>
              <li>Money invested for annuities = Rs 2.25 crore</li>
              <li>Monthly pension expected = Rs 1.24 lakhs</li>
            </ul>
            <p>
              Note: The above illustration is a suggested example and does not
              guarantee the actual numbers.
            </p>
          </div>

          <div className={styles.FAQ4} ref={faqRefs.faq4}>
            <h1>Frequently Asked Questions</h1>
            <ul>
              <li>
                <span className={styles.boldText}>
                  Does the calculator help determine how much tax is due on NPS
                  withdrawals?
                </span>
                <br />
                No. When taking money out of your NPS account, there are several
                things to take into account when figuring out how much tax you
                would owe. The NPS calculator does not contain information about
                the tax that would be due upon withdrawal because these factors
                vary throughout NPS subscribers.
              </li>
              <li>
                <span className={styles.boldText}>
                  Is the NPS determined annually or monthly?
                </span>
                <br />
                The maturity value of NPS can be computed for both annual and
                monthly payments.
              </li>
              <li>
                <span className={styles.boldText}>
                  What is NPS's interest rate right now?
                </span>
                <br />
                Since NPS is a market-linked instrument, its return is based on
                how well the underlying assets—such as government securities,
                debt, and equity—perform. Thus, the interest rate in NPS is not
                fixed, in contrast to FDs.
              </li>
              <li>
                <span className={styles.boldText}>
                  Can I use the NPS calculator with any kind of NPS account?
                </span>
                <br />
                Absolutely, you may use the NPS calculator to find the maturity
                amount for both Tier 1 and Tier 2 NPS accounts. However, since
                there is no pension in a Tier 2 account, the annuity or pension
                computation only applies to Tier 1 accounts.
              </li>
              <li>
                <span className={styles.boldText}>
                  Is it possible to plan for my retirement using the NPS
                  calculator?
                </span>
                <br />
                Yes, you can use the NPS calculator to plan for retirement. With
                your contributions, age, investment duration, and anticipated
                returns in mind, it is specifically made to estimate your
                retirement corpus and monthly annuity. Your ability to plan for
                retirement will be aided by knowing how much you need to save
                and the possible amount of pension you might receive by
                supplying the necessary information.
              </li>
              <li>
                <span className={styles.boldText}>
                  Is the Fabits NPS calculator precise?
                </span>
                <br />
                At Fabits, we recognize that you need reliable financial data in
                order to make confident plans for the future. An estimate is
                provided by our NPS calculator based on the information you
                supply. To maintain openness, we would like to point out that
                actual outcomes could differ depending on a number of
                circumstances, including market fluctuations, contribution
                levels, and investment performance. You may be confident that we
                will give you the greatest resources and advice possible for
                your financial journey.
              </li>
              <li>
                <span className={styles.boldText}>
                  What data is required in order to utilize the NPS calculator?
                </span>
                <br />
                All you need to do is enter your age, your desired monthly or
                annual investment amount, and your preferred investing style
                (Aggressive, Conservative, Moderate). By entering these details,
                both your pension amount and maturity value will be displayed.
              </li>
              <li>
                <span className={styles.boldText}>
                  Can I use the NPS calculator to help me choose wisely among
                  investments?
                </span>
                <br />
                Yes, you can use the NPS calculator to make well-informed
                financial decisions. Because it enables you to determine the
                pension amount and maturity value, which serve as the foundation
                for your investing decisions.
              </li>
              <li>
                <span className={styles.boldText}>
                  What is the Tier I and Tier II NPS subscribers' minimum
                  contribution?
                </span>
                <br />A minimum payment of ₹500 must be put into the NPS Tier 1
                at the time the account is opened. Similarly, when starting an
                NPS Tier 2 account, a minimum contribution of ₹1000 must be
                made. After opening an account, there is presently no minimum
                annual contribution requirement for Tier 2 NPS accounts. Your
                NPS Tier 1 account must be active for the whole of the financial
                year with a minimum contribution of ₹1,000.
              </li>
              <li>
                <span className={styles.boldText}>
                  Do tier-1 and tier-2 cities have different pension maturity
                  values?
                </span>
                <br />
                No, tier-1 and tier-2 cities have the same maturity values.
                Since NPS is a market-linked instrument, you will always receive
                returns throughout the nation.
              </li>
              <li>
                <span className={styles.boldText}>
                  After I retire, how may I receive a monthly pension of one
                  lakh NPS?
                </span>
                <br />
                Once you retire from NPS, you will get a ₹1 lakh monthly
                pension, but only provided you make consistent large
                contributions and choose the right investment plan. Making
                long-term investments and regularly evaluating your portfolio to
                make sure it aligns with your retirement objectives are
                essential. You can use the NPS calculator to determine your
                pension amount based on your investment. You can use this to
                find out how much you need to invest in order to obtain a
                monthly pension of ₹1 lakh, depending on your investing
                strategy.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeHub;
