import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./module.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

function Partnerships() {
  const recaptcha = useRef();

  const [submitted, setSubmitted] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const form = useRef();
  const location = useLocation();

  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    if (cooldown) {
      // Start the cooldown timer
      const cooldownTimer = setTimeout(() => {
        setCooldown(false);
      }, 100000); // 1 Minute cooldown

      // Clear the timer when the component unmounts or cooldown is reset
      return () => clearTimeout(cooldownTimer);
    }
  }, [cooldown, location.pathname]);

  const sendEmail = async (e) => {
    e.preventDefault();

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      const res = await fetch(
        "https://devapi.fabits.com/customerservice/api/captcha/verify",
        {
          method: "POST",
          body: JSON.stringify({ captchaValue }),
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const data = await res.json();

      if (data.success) {
        // Prevent submission during cooldown period
        if (cooldown) {
          return;
        }
        // Placeholder logic to simulate successful submission
        setSubmitted(true);
        setCooldown(true);
        // Reset the form after 10 seconds
        setTimeout(() => {
          setSubmitted(false);
          setCooldown(false);
          form.current.reset();
          recaptcha.current.reset();
        }, 10000); // 10 seconds cooldown
        // You can replace this code with your actual emailjs logic
        emailjs
          .sendForm("service_1znzuh7", "template_ib0edc8", e.target, {
            publicKey: "Tkc_DuArwUPCZZqsR",
          })
          .then(
            () => {
              console.log("Email sent successfully!");
            },
            (error) => {
              console.error("Email sending failed:", error.text);
            }
          );
      } else {
        alert("reCAPTCHA validation failed!");
      }
    }
  };

  return (
    <>
      <div className="partnerships">
        <Helmet>
          <title>Fabits - Partnership</title>
          <meta
            name="description"
            content="Explore customized financial planning solutions for advisors and corporates with Fabits, your partner for achieving financial freedom and productivity."
          />
        </Helmet>
        <h3 id="spread">Spread the magic of financial planning</h3>
        <p id="belowspread">
          Customised financial planning solutions for advisors and corporates
        </p>
        <img
          src="/Product-page/Group 32 (1).svg"
          alt="Group 32"
          id="group321"
        ></img>
        <img
          src="/Product-page/Framemobile.svg"
          alt="Framemobile"
          id="Framemobile"
        ></img>
        <img
          src="/Product-page/Group1mobile.svg"
          alt="Group1mobile"
          id="Group1mobile"
        ></img>
        <img src="/Partnerships/Group 52.svg" alt="Group 52" id="group52" />
        <img src="/Partnerships/Frame 52.svg" alt="Frame 52" id="frame52" />
        <img src="/Partnerships/Group 1.svg" alt="Group 1" id="group1" />
        <div className="partnershipsbuttons">
          <a
            href="https://wa.me/+918217801975?text=Hi+I+was+going+through+your+website+and+want+to+partner+with+you+for+financial+sessions+for+my+employees.+How+can+we+take+this+forward?"
            target="_blank"
            rel="noreferrer"
          >
            <button id="dashboard">Get in touch</button>
          </a>
          <a
            href="https://youtu.be/_nplDtaJVuA"
            target="_blank"
            rel="noreferrer"
          >
            <button id="learnmore">Learn more</button>
          </a>
        </div>
        <a href="#partnerships4" className="linksnefn">
          <img src="/Partnerships/Frame 5.svg" alt="Frame 5" id="frame5" />
        </a>
      </div>
      <div className="partnerships2">
        <h3 id="mutual">For Financial advisors</h3>
        <p id="belowmutual">
          Offer diverse investment products and solutions to your clients
        </p>
        <div className="partnership2top2">
          <div id="partnership2top2left">
            <h3 className="partnership2top2lefttext">
              Reduce loss of clients to DIY investments
            </h3>
            <p className="partnership2top2leftpara" id="clientspara">
              Clients are 70% more likely to consolidate investments with
              advisors who offer a comprehensive suite of products and services
              <img
                src="/Partnerships/Group 42mobile.svg"
                alt="Group 42"
                id="group42mobile"
              />
              <img
                src="/Partnerships/Group 42.svg"
                alt="Group 42"
                id="group42"
              />
            </p>
            <a
              href="https://youtu.be/j0yZd_BMUv0"
              target="_blank"
              className="linksnefn"
              rel="noreferrer"
            >
              <button className="partnership2top2leftbutton">
                Learn more
                <img
                  src="/Partnerships/arrow_right_alt.svg"
                  alt="arrow"
                  className="learnmorearrow"
                ></img>
              </button>
            </a>
          </div>
          <div id="partnership2top2right">
            <img src="/Partnerships/Group 67.svg" alt="Group 67" id="group67" />
            <img
              src="/Partnerships/Group67mobile.svg"
              alt="Group67mobile"
              id="group67mobile"
            />
            <h3 className="partnership2top2lefttext">
              Become a one-stop solution for your clients
            </h3>
            <p className="partnership2top2leftpara" id="offerpara">
              Offer investment solutions as well as financial planning and
              wealth management expertise through our products
            </p>
            <a
              href="https://youtu.be/2Sg_enZrxCc"
              target="_blank"
              className="linksnefn"
              rel="noreferrer"
            >
              <button className="partnership2top2leftbutton">
                Learn more
                <img
                  src="/Partnerships/arrow_right_alt.svg"
                  alt="arrow"
                  className="learnmorearrow"
                ></img>
              </button>
            </a>
          </div>
        </div>
        <div className="partnership2bottom">
          <div className="partnership2bottom1">
            <h4 className="partnership2bottomtext">
              In partnership with trusted names in the financial services
              industry
            </h4>
            <p id="partnership2bottompara21">
              Fabits is offering this in partnership with Redvision
              Technologies, a trusted name among MFDs. We are also compliant
              with partner bodies SEBI, NSE, and CDSL.
            </p>
          </div>
          <div className="partnership2bottom2">
            <h4 className="partnership2bottomtext">
              <img
                src="/Partnerships/Frame 370.svg"
                alt="Frame 370"
                id="frame370"
              />
              Your clients always remain yours
            </h4>
            <p className="partnership2bottompara">
              We never get in touch with your clients; you remain the sole point
              of contact for them for all things financial planning!
            </p>
          </div>
          <div className="partnership2bottom3">
            <h4 className="partnership2bottomtext">
              <img
                src="/Partnerships/Frame 370.svg"
                alt="Frame 370"
                id="frame370"
              />
              Here is what you stand to gain
            </h4>
            <p className="partnership2bottompara">
              Increase your AuM and earn up to 50% more in commissions by
              diversifying your offerings
            </p>
          </div>
          <img
            src="/Partnerships/Vector711.svg"
            alt="Vector 711"
            id="vector711"
          />
        </div>
      </div>
      <div className="partnerships3">
        <h4 id="corporate">For Corporates and Startups</h4>
        <p id="corporatepara">
          Set your employees on the path to financial independence
        </p>
        <div className="partnerships3topop">
          <table id="partnershiptable">
            <div className="partnerships3topop3">
              <tr className="partnershitr">
                <td className="partnershipnumbers">01</td>
                <td className="partnershipheader">
                  Increase employee productivity
                </td>
                <td className="partnerships3text" id="partnership3textparaw2">
                  Workplace productivity can rise by <strong> 6.2% </strong> if
                  your employees are financially stable
                </td>
              </tr>
            </div>
            <div className="partnerships3topop3">
              <tr className="partnershitr">
                <td className="partnershipnumbers">02</td>
                <td className="partnershipheader">
                  Financial counselling sessions
                </td>
                <td className="partnerships3text" id="partnershps3test">
                  Get experts to address the financial needs of your employees
                  through exclusively curated sessions
                </td>
              </tr>
            </div>
            <div className="partnerships3topop3" id="partnerships3topop1">
              <tr className="partnershitr">
                <td className="partnershipnumbers">03</td>
                <td className="partnershipheader">
                  Enable breaking the glass ceiling
                </td>
                <td className="partnerships3text">
                  Build company-specific financial processes for the financial
                  wellbeing of your employees
                </td>
              </tr>
            </div>
          </table>
          <img
            src="/Partnerships/Vector505.svg"
            alt="Vector505"
            id="vector505"
          />
        </div>
      </div>

      <div id="partnerships4">
        <img src="/Partnerships/Vector404.svg" alt="Group 404" id="vector404" />
        <div id="talktous">
          <img src="/Partnerships/Group 43.svg" alt="Group 43" id="group43" />
          <img
            src="/Partnerships/Group 43mobile.svg"
            alt="Group 43"
            id="group43mobile"
          />
          <h2 id="talktoush2">Talk to us</h2>
          <p id="talktouspara">
            Fill up the form, and we wll get in touch soon
          </p>
          <img src="/Partnerships/Group 54.svg" alt="Group 54" id="group54" />
          <img
            src="/Partnerships/Group 54mobile.svg "
            alt="Group 54"
            id="group54mobile"
          />
          <div className="talktousform">
            <form id="talktousform" onSubmit={sendEmail} ref={form}>
              <div className="nameanddesignation">
                <div className="form1">
                  <label htmlFor="name" className="formlabels">
                    Full name
                  </label>
                  <input
                    type="text"
                    className="forminputs"
                    name="user_name"
                    placeholder="e.g. G Surya Karthik"
                  />
                </div>
                <div className="form1">
                  <label htmlFor="Designation" className="formlabels">
                    Designation
                  </label>
                  <input
                    type="text"
                    className="forminputs"
                    name="Designation"
                    placeholder="e.g. General Manager"
                  />
                </div>
              </div>
              <div className="companyandemail">
                <div className="form1">
                  <label htmlFor="Company" className="formlabels">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="forminputs"
                    name="Company"
                    placeholder="e.g. Shareway Securities"
                  />
                </div>
                <div className="form1">
                  <label htmlFor="Email" className="formlabels">
                    Email address*
                  </label>
                  <input
                    required
                    type="email"
                    className="forminputs"
                    name="user_email"
                    placeholder="e.g. G.Suryakarthik@gmail.com"
                  />
                </div>
              </div>
              <div className="phoneandmessage">
                <div className="form1">
                  <label htmlFor="Phone" className="formlabels">
                    Phone number
                  </label>
                  <input
                    type="text"
                    className="forminputs"
                    name="Phone"
                    placeholder="e.g. +918217801975"
                  />
                </div>
                <div className="partner-captcha">
                  <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={`6LdJW-spAAAAANJ9T03r-2mv0bOy1dTL05_Wm3rh`}
                  />
                </div>
              </div>

              <button
                type="submit"
                value="Send"
                id="submitpatnership"
                disabled={submitted || cooldown}
                className={submitted ? "submitted" : ""}
              >
                {submitted ? "Details submitted" : "Submit Details"}
              </button>
            </form>
          </div>
          <img src="/Partnerships/Group404.svg" alt="Group404" id="group404" />
        </div>
      </div>
      <div className="container3">
        <div className="EasySteps">
          <img
            src="/Product-page/Frame 394.svg"
            alt="Frame 394"
            id="Frame394"
          />
          <h2 id="Easysteps">Get Started in 3 Easy Steps</h2>
          <a href="https://mywealth.fabits.com/login">
            <div className="step1">
              <p>Sign up with Fabits</p>
            </div>
            <div className="step2">
              <p>Answer a few simple questions</p>
            </div>
            <div className="step3">
              <p>Attain financial freedom with actionable steps</p>
            </div>{" "}
          </a>
        </div>
      </div>
    </>
  );
}

export default Partnerships;
