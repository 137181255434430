import React from "react";
import styles from "./returnTabs.module.css";

const ReturnTab = ({
  tabsData,
  activeTab,
  setActiveTab,
  rateChange,
  isSIP,
}) => {
  return (
    <div>
      <div className={styles.tabs}>
        {tabsData.map((tab, index) => (
          <React.Fragment key={index}>
            <input
              type="radio"
              name={`tabs-${isSIP ? "sip" : "lumpsum"}`}
              id={`tabs-${index}`}
              checked={activeTab === index}
              onChange={() => {
                setActiveTab(index);
                rateChange(tab.rate);
              }}
            />
            <label htmlFor={`tabs-${index}`} className={styles.tab}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {tab.icon}
              </span>{" "}
              {tab.title}
            </label>
          </React.Fragment>
        ))}
        <span
          className={styles.glider}
          style={{ transform: `translateX(${activeTab * 100}%)` }}
        ></span>
      </div>
    </div>
  );
};

export default ReturnTab;
