import { useEffect, useState } from "react";

export default function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    // Function to check if the media query matches
    const mediaQuery = window.matchMedia(query);

    // Initial check
    if (mediaQuery.matches !== matches) {
      setMatches(mediaQuery.matches);
    }

    // Event listener to update matches when the media query changes
    const handleChange = () => {
      setMatches(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleChange);

    // Clean up the event listener
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, [query, matches]);

  return matches;
}
