import React from "react";
import styles from "./tabs.module.css";

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div>
      <div className={styles.tab}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`${styles.tablinks} ${
              activeTab === tab.id ? styles.active : ""
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.name}
          </button>
        ))}
      </div>

      {tabs.map((tab) => (
        <div
          key={tab.id}
          id={tab.id}
          className={styles.tabcontent}
          style={{ display: activeTab === tab.id ? "block" : "none" }}
        >
          <p>{tab.content}</p>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
