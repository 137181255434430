import React, { useEffect, useState } from "react";
import styles from "./emi.module.css";
import InvestmentDetails from "./InvestmentDetails/InvestmentDetails";
import DetailGraph from "./DetailedBreakdown/DetailedBreakDown";

import { useNavigate } from "react-router-dom";
import KnowledgeHub from "./KnowledgeHub/KnowledgeHub";

const Emi = () => {
  const [investmentAmount, setInvestmentAmount] = useState(100000);
  const [expectedReturns, setExpectedReturns] = useState(0);
  const [investmentYears, setInvestmentYears] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={styles.breadCrumb}
        onClick={() => navigate("/calculator")}
      >
        <span class="material-symbols-rounded">chevron_left</span>
        <h1> EMI Calculator</h1>
      </div>

      <InvestmentDetails
        setInvestmentAmount={setInvestmentAmount}
        setExpectedReturns={setExpectedReturns}
        setInvestmentYears={setInvestmentYears}
        investmentAmount={investmentAmount}
        expectedReturns={expectedReturns}
        investmentYears={investmentYears}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <DetailGraph
        investmentAmount={investmentAmount}
        expectedReturns={expectedReturns}
        investmentYears={investmentYears}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {/* <KnowledgeHub /> */}
    </div>
  );
};

export default Emi;
