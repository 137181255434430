export const calculateCompoundInterest = (
  investmentAmount,
  rateOfReturn,
  years,
  isSIP = true
) => {
  let totalCorpus = 0;
  rateOfReturn = rateOfReturn / 100;

  if (isSIP) {
    // SIP calculation
    for (let i = 0; i < years; i++) {
      totalCorpus += investmentAmount * Math.pow(1 + rateOfReturn, years - i);
    }
  } else {
    // Lumpsum calculation
    totalCorpus = investmentAmount * Math.pow(1 + rateOfReturn, years);
  }

  return totalCorpus;
};
