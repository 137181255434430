import React, { useState, useEffect, useRef, useCallback } from "react";
import "./module.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import clevertap from "clevertap-web-sdk";
import useMediaQuery from "../hooks/useMediaQuery";

function Pricing() {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 980px)");

  function handleClick(e) {
    setSelected(e.target.id);
  }

  const [selected, setSelected] = useState("yearlybutton1");
  const tooltipRef = useRef(null);
  const [AMCvalue, setAMCValue] = useState(0); // AMCvalue is the value of AMC charges

  const minLog = Math.log(2500);
  const maxLog = Math.log(1000000); // minLog and maxLog are the minimum and maximum values of the slider
  const scale = (maxLog - minLog) / 100; // scale is the scale of the slider. we use this to make the slider logarithmic

  const defaultValue = 10000;
  const defaultSliderValue = (Math.log(defaultValue) - minLog) / scale;

  const [sliderValue, setSliderValue] = useState(defaultSliderValue);
  const [value, setValue] = useState(defaultValue); // Set initial value to 10000

  const handleSliderChange = (event) => {
    const newSliderValue = event.target.value;
    const calculatedValue = Math.exp(minLog + scale * newSliderValue);
    const roundedValue = Math.round(calculatedValue / 1000) * 1000; // Round to nearest 1000
    setValue(roundedValue);
    setSliderValue(newSliderValue);
    setMonthlyInvestment(roundedValue);
  };

  const handleValueChange = useCallback(
    (newValue) => {
      const newSliderValue = (Math.log(newValue) - minLog) / scale;
      setValue(newValue);
      setSliderValue(newSliderValue); // Update sliderValue as well
    },
    [minLog, scale]
  );

  useEffect(() => {
    handleValueChange(defaultValue); // Set initial value to defaultValue (10000)
  }, [handleValueChange]);

  useEffect(() => {
    const newAMCValue = calculateAMCValue(value);
    setAMCValue(newAMCValue);
  }, [value]);

  useEffect(() => {
    clevertap.event.push("Page Viewed", {
      Page_name: "Pricing Page",
    });
  }, []);

  function calculateAMCValue(value) {
    let AMCvalue;
    if (value >= 50000000) {
      AMCvalue = 1999;
    } else if (value >= 1000000) {
      AMCvalue = 999;
    } else if (value >= 200000) {
      AMCvalue = 299;
    } else if (value >= 15000) {
      AMCvalue = 49;
    } else {
      AMCvalue = 0;
    }
    return AMCvalue;
  }

  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        const tooltiptext = document.querySelector(".tooltiptext");
        tooltiptext.classList.remove("show");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTooltipClick = () => {
    const tooltiptext = document.querySelector(".tooltiptext");
    tooltiptext.classList.toggle("show");
  };

  function formatIndianNumeral(number) {
    return new Intl.NumberFormat("en-IN", {
      currency: "INR",
    }).format(number);
  }

  const [monthlyInvestment, setMonthlyInvestment] = useState(10000); // Set to 10000 initially
  const [years, setYears] = useState(10);
  const [monthlyRate, setMonthlyRate] = useState(2 / 12 / 100);
  const [etfRate, setEtfRate] = useState(0.15 / 12 / 100);
  const [results, setResults] = useState([]);
  const [totalCharges, setTotalCharges] = useState(0);
  const [totalEtfCharges, setTotalEtfCharges] = useState(0);
  const [totalConditionalCharges, setTotalConditionalCharges] = useState(0);
  const [totalTaxes, setTotalTaxes] = useState(0);
  const [totalChargesSummary, setTotalChargesSummary] = useState(0);
  const [percentageDifference, setPercentageDifference] = useState(0);
  const [tenYearsReinvestment, setTenYearsReinvestment] = useState(0);
  const [twentyYearsReinvestment, setTwentyYearsReinvestment] = useState(0);
  const [totalEtfAndConditionalCharges, setTotalEtfAndConditionalCharges] =
    useState(0);
  const [saving, setSaving] = useState(0);

  const calculateConditionalCharge = (investment) => {
    if (investment > 50000000) {
      return 1999;
    } else if (investment > 1000000) {
      return 999;
    } else if (investment > 200000) {
      return 299;
    } else if (investment > 15000) {
      return 49;
    } else {
      return 0;
    }
  };

  const calculateInvestmentforTenYears = () => {
    let investment = 0;
    let totalCharge = 0;
    let totalEtfCharge = 0;
    let totalConditionalCharge = 0;
    let totalReinvestedReturnForTenYears = 0;
    const monthlyResults = [];
    const totalMonths = years * 12;
    const monthlyInterestRate = 0.15 / 12; // Monthly interest rate for reinvesting

    for (let i = 1; i <= totalMonths; i++) {
      investment += monthlyInvestment;
      const charge = investment * monthlyRate;
      totalCharge += charge;
      const etfCharge = investment * etfRate;
      totalEtfCharge += etfCharge;
      const conditionalCharge = calculateConditionalCharge(investment);
      totalConditionalCharge += conditionalCharge;
      const gst = conditionalCharge * 0.18;
      const fabTotal = etfCharge + conditionalCharge + gst;

      const monthlySaving = charge - fabTotal; // Calculate savings

      // Reinvested saving for 10 years
      const reinvestedSavingForTenYears =
        monthlySaving * Math.pow(1 + monthlyInterestRate, 120 - i + 1);
      totalReinvestedReturnForTenYears += reinvestedSavingForTenYears;

      monthlyResults.push({
        month: i,
        investment: Math.ceil(investment),
        charge: Math.ceil(charge),
        etfCharge: Math.ceil(etfCharge),
        conditionalCharge: Math.ceil(conditionalCharge),
        gst: Math.ceil(gst),
        fabTotal: Math.ceil(fabTotal),
        saving: Math.ceil(monthlySaving),
        reinvestedSavingForTenYears: Math.ceil(reinvestedSavingForTenYears),
      });
    }

    setTenYearsReinvestment(Math.ceil(totalReinvestedReturnForTenYears));

    // Calculate the sum of etfCharge and conditionalCharge
    const totalEtfAndConditional = totalEtfCharge + totalConditionalCharge;
    setTotalEtfAndConditionalCharges(Math.ceil(totalEtfAndConditional)); // Store the sum in state

    // Update the state with calculated results
    setResults(monthlyResults);
    setTotalCharges(Math.ceil(totalCharge));
    setTotalEtfCharges(Math.ceil(totalEtfCharge));
    setTotalConditionalCharges(Math.ceil(totalConditionalCharge));
    setTotalTaxes(Math.ceil(totalConditionalCharge * 0.18));

    // Calculate the total charges summary
    const calculatedTotalChargesSummary =
      parseFloat(totalEtfCharge) +
      parseFloat(totalConditionalCharge) +
      parseFloat(Math.ceil(totalConditionalCharge * 0.18));
    setTotalChargesSummary(calculatedTotalChargesSummary);

    const saving = totalCharge - calculatedTotalChargesSummary;
    setSaving(Math.ceil(saving));

    // Calculate the percentage difference
    const difference =
      Math.abs((totalCharge - calculatedTotalChargesSummary) / totalCharge) *
      100; // Absolute value
    setPercentageDifference(Math.ceil(difference)); // Set the percentage difference
  };

  const calculateInvestmentforTwentyYears = () => {
    let investment = 0;
    let totalReinvestedSavingForTwentyYears = 0;
    const totalMonths = years * 24;
    const monthlyInterestRate = 0.15 / 12; // Monthly interest rate for reinvesting

    for (let i = 1; i <= totalMonths; i++) {
      investment += monthlyInvestment;
      const charge = investment * monthlyRate;

      const etfCharge = investment * etfRate;

      const conditionalCharge = calculateConditionalCharge(investment);

      const gst = conditionalCharge * 0.18;
      const fabTotal = etfCharge + conditionalCharge + gst;

      const monthlySaving = charge - fabTotal; // Calculate savings

      // Reinvested saving for 20 years
      const reinvestedSavingForTwentyYears =
        monthlySaving * Math.pow(1 + monthlyInterestRate, 240 - i + 1);
      totalReinvestedSavingForTwentyYears += reinvestedSavingForTwentyYears;
    }

    setTwentyYearsReinvestment(Math.ceil(totalReinvestedSavingForTwentyYears));
  };

  useEffect(() => {
    calculateInvestmentforTenYears();
    calculateInvestmentforTwentyYears();
  }, [monthlyInvestment, years, monthlyRate, etfRate]);

  console.log(isMobile);

  return (
    <>
      <div className="pricingtopfirstsection">
        <Helmet>
          <title>Fabits - Pricing</title>
          <meta
            name="description"
            content="Explore affordable and flexible investment plans with Fabits, tailored to fit every budget and designed to help you achieve your financial goals."
          />
        </Helmet>
        <div className="pricingtopleftsection">
          <div className="pricingtopleftsectiontextpart">
            <h3 className="pricingtopleftsectionh3">
              Plans to make your Planning simpler
            </h3>
            <p className="pricingtopleftsectionp">
              Money management and planning are no longer expensive!
            </p>
            <a href="#planssectionpricingpage">
              <button
                className="pricingtopleftsectionbutton-mobile"
                onClick={() => {
                  clevertap.event.push("Clicked Button", {
                    Action: "Explore Plans 1 Button",
                    Page_name: "Pricing Page",
                  });
                }}
              >
                Explore Plans
              </button>
            </a>
          </div>
          <div className="pricingslidersection">
            <label className="pricingsliderlabel">Monthly investment</label>
            <input
              type="range"
              min="0"
              max="100"
              className="slider"
              id="myRange"
              value={sliderValue}
              onChange={handleSliderChange}
              onFocus={() => {
                clevertap.event.push("Clicked Input", {
                  Action: "Monthly investment Slider",
                  Page_name: "Pricing Page",
                });
              }}
            />

            <p className="pricingslidersectionbottomp">
              <span className="pricingslidersectionbottomspan">
                &#8377; 2,000
              </span>
              <span className="pricingslidersectionbottomspan1">
                &#8377; 10,00,000 +
              </span>
            </p>
          </div>
          <div className="pricingpagetopbuttensectionare">
            <a href="#planssectionpricingpage">
              <button
                className="pricingtopleftsectionbutton"
                onClick={() => {
                  clevertap.event.push("Clicked Button", {
                    Action: "Explore Plans 2 Button",
                    Page_name: "Pricing page",
                  });
                }}
              >
                Explore Plans
              </button>
            </a>
          </div>
        </div>
        <div className="pricingtoprightsection">
          <div className="checkoutyoursavingssection">
            <img src="Pricingpage/savings.svg" alt="savings" id="savingpiggy" />
            <h4 className="checkoutyoursaving">Check out your savings!</h4>
          </div>
          <p
            className="amoutyouwillinvest"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              Amount you'd like to {isMobile && <br />}
              invest monthly :
            </div>
            <p className="thesavedamount">
              &#8377; {formatIndianNumeral(value)}
            </p>
          </p>
          <div className="checkoutyoursavingssectionbelowheading">
            <div className="ifyouinvestindirectmf">
              <h4 className="checkoutyoursavingmf" id="ifyouinvsinmfs">
                If you invest in MFs*{" "}
              </h4>
              <div className="mutualfundsavingbelowheeding">
                <p className="mutualfundsavingbelowheedingp">
                  MF fees
                  <p>(10 years)</p>
                </p>
                <p className="mutualfundsavingbelowheedingp1">
                  &#8377; {formatIndianNumeral(totalCharges)}
                </p>
              </div>
            </div>
            <div className="ifyouinvestinfabits">
              <h4 className="checkoutyoursavingmf" id="ifyouinvestinfabits">
                If you invest with Fabits
              </h4>
              {/* <div className="fabitssavingbelowheeding"> */}
              {/* <div style={{ display: "flex", flexDirection: "column" }}> */}

              <div
                className="tooltiptextcontent1"
                style={{ marginTop: "10px" }}
              >
                <p
                  className="tooltiptextcontent1p"
                  style={{ fontWeight: "bold" }}
                >
                  Total Charges
                </p>
                <p className="tooltiptextcontent1p1">
                  &#8377; {formatIndianNumeral(totalChargesSummary)}
                </p>
              </div>

              <p
                className="fabitssavingbelowheedingp tooltip"
                ref={tooltipRef}
                onClick={() => {
                  handleTooltipClick();
                  clevertap.event.push("Clicked Button", {
                    Action: "Total Charges",
                    Page_name: "Pricing Page",
                  });
                }}
                style={{
                  border: "none",
                  // textAlign: "start",
                  // marginLeft: "50px",
                  fontSize: "12px",
                }}
              >
                Extra Charges{" "}
                <img src="/Pricingpage/expand_more.svg" alt="expandmore" />
                <span className="tooltiptext">
                  <div className="tooltiptextcontent1">
                    <p className="tooltiptextcontent1p">
                      Fabits Platform Charge
                    </p>
                    <p className="tooltiptextcontent1p1">
                      &#8377; {formatIndianNumeral(totalConditionalCharges)}
                    </p>
                  </div>
                  <div className="tooltiptextcontent1">
                    <p className="tooltiptextcontent1p">ETF Charges</p>
                    <p className="tooltiptextcontent1p1">
                      &#8377; {formatIndianNumeral(totalEtfCharges)}
                    </p>
                  </div>
                  <div className="tooltiptextcontent1">
                    <p className="tooltiptextcontent1p">
                      Taxes & Regulatory Charges
                    </p>
                    <p className="tooltiptextcontent1p1">
                      &#8377; {formatIndianNumeral(totalTaxes)}
                    </p>
                  </div>
                </span>
              </p>

              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
          <div className="yearsavinginfabits">
            <div className="savingyear">
              <p className="yearsavinginfabitsp" id="yesrsaving">
                Your savings{" "}
              </p>
              <p className="yearsavinginfabitsp1">
                &#8377;{" "}
                {formatIndianNumeral(totalCharges - totalChargesSummary)}
              </p>
            </div>

            <div className="savingyear">
              <p className="yearsavinginfabitsp" id="yesrsaving">
                Percentage savings{" "}
              </p>
              <p className="yearsavinginfabitsp1">
                {formatIndianNumeral(percentageDifference)}%
              </p>
            </div>
            <div className="saving10years" style={{ width: "90%" }}>
              <p className="yearsavinginfabitsp">
                Above savings invested @ 15%
                <br /> Additional wealth created
              </p>
            </div>
            <div className="saving10years">
              <p className="yearsavinginfabitsp" id="savingin10years">
                After 10 years:
              </p>
              <p className="yearsavinginfabitsp1">
                &#8377; {formatIndianNumeral(tenYearsReinvestment)}
              </p>
            </div>
            <div className="saving10years" style={{ marginTop: "20px" }}>
              <p className="yearsavinginfabitsp" id="savingin10years">
                After 20 years:
              </p>
              <p
                className="yearsavinginfabitsp1"
                style={{
                  letterSpacing: String(twentyYearsReinvestment).includes("11")
                    ? "2px"
                    : "0px",
                }}
              >
                &#8377; {formatIndianNumeral(twentyYearsReinvestment)}
              </p>
            </div>
          </div>
        </div>
        <button
          className="pricingtopleftsectionbutton1-mobile"
          onClick={() => {
            clevertap.event.push("Clicked Button", {
              Action: "How does Fabits help save money ?",
              Page_name: "Pricing Page",
            });
          }}
        >
          How does Fabits help save money?
        </button>
      </div>
      <div className="planssectionpricingpage" id="planssectionpricingpage">
        <h3 className="Checkoutourplanspricingpage">Check out our plans</h3>
        <div className="planssectionpricecomparations-mobile">
          <div className="Starterdesktoppricnig-mobile cardssectionpricingpage-mobile">
            <img
              src="Pricingpage/hiking.svg"
              alt="hiking"
              className="hikingimage"
            />
            <h3 className="Starterdesktoppricingh2">Starter</h3>
            <h4 className="Starterdesktoppricingh4">Free</h4>
            <p className="Starterdesktoppricingp">for life</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "60%",
              }}
            >
              <div className="featuressectionmobiletingwithticks">
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Invest up
                  to ₹ 15,000
                </p>
                {/* <p className="featuresidepricingpage-mobile">
                <img src="Pricingpage/check.svg" alt="tick"></img> Basic
                Financial Literary Program
              </p> */}
                {/* <p className="featuresidepricingpage-mobile">
                <img src="Pricingpage/check.svg" alt="tick"></img> Financial
                Report Card
              </p> */}
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Retirement
                  Projections & Planning
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Core
                  Planning Modules & Calculators
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Insurance
                  Counselling
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Live Chat
                  Support
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Email/
                  Ticket Support
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Referral
                  Program
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img>{" "}
                  Personalised Action Items
                </p>
              </div>
              <a
                href="https://mywealth.fabits.com/sign-up"
                // className="Starterdesktoppricinga"
              >
                <button
                  className="Starterdesktoppricingbutton firstbuttonofthestartersectionpricingpage"
                  onClick={() => {
                    clevertap.event.push("Clicked Button", {
                      Action: "Get Started",
                      Page_name: "Pricing Page",
                    });
                  }}
                >
                  Get Started
                </button>
              </a>
            </div>
          </div>
          <div className="Foundationdesktoppricing-mobile cardssectionpricingpage-mobile">
            <img
              src="Pricingpage/allergy.svg"
              alt="allergy"
              className="allergyimage"
            />
            <h3 className="Starterdesktoppricingh2">Foundation</h3>
            <h4 className="Starterdesktoppricingh4">&#8377; 49</h4>
            <p className="Starterdesktoppricingp">per month</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "60%",
              }}
            >
              <div className="featuressectionmobiletingwithticks">
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Invest up
                  to ₹ 2 Lakhs
                </p>
                {/* <p className="featuresidepricingpage-mobile">
                <img src="Pricingpage/check.svg" alt="tick"></img> Basic
                Financial Literary Program
              </p> */}
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> 1 Financial
                  Counselling Sessions
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img>{" "}
                  Personalised Action Items
                </p>
              </div>
              <a
                href="https://mywealth.fabits.com/sign-up"
                className="Starterdesktoppricinga"
              >
                <button
                  className="Starterdesktoppricingbutton"
                  onClick={() => {
                    clevertap.event.push("Clicked Button", {
                      Action: "Choose",
                      page_name: "Pricing Page",
                    });
                  }}
                >
                  Choose
                </button>
              </a>
            </div>
          </div>
          <div className="Growthdesktoppricingpage-mobile cardssectionpricingpage-mobile">
            <img
              src="Pricingpage/psychiatry.svg"
              alt="psychiatry"
              className="psychiatryimage"
            />
            <h3 className="Starterdesktoppricingh2">Growth</h3>
            <h4 className="Starterdesktoppricingh4">&#8377; 299</h4>
            <p className="Starterdesktoppricingp">per month</p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "60%",
              }}
            >
              <div className="featuressectionmobiletingwithticks">
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Invest up
                  to ₹ 10 Lakhs
                </p>
                {/* <p className="featuresidepricingpage-mobile">
                <img src="Pricingpage/check.svg" alt="tick"></img> Intermediate
                Financial Literary Program
              </p> */}
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> 2 Financial
                  Counselling Sessions
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img>{" "}
                  Personalised Financial Plan
                </p>
                {/* <p className="featuresidepricingpage-mobile">
                <img src="Pricingpage/check.svg" alt="tick"></img> Tax Planning
                Module
              </p> */}
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Automated
                  Rebalance
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Portfolio
                  Management
                </p>
                {/* <p className="featuresidepricingpage-mobile">
                <img src="Pricingpage/check.svg" alt="tick"></img> Personal
                Finance Webinars
              </p> */}
              </div>
              <a
                href="https://mywealth.fabits.com/sign-up"
                className="Starterdesktoppricinga"
              >
                <button
                  className="Starterdesktoppricingbutton Growthchoosebuttonpricingpage"
                  onClick={() => {
                    clevertap.event.push("Clicked Button", {
                      Action: "Choose",
                      page_name: "Pricing Page",
                    });
                  }}
                >
                  Choose
                </button>
              </a>
            </div>
          </div>
          <div className="Premierdesktoppricing-mobile cardssectionpricingpage-mobile">
            <img src="Pricingpage/park.svg" alt="park" className="parkimage" />
            <h3 className="Starterdesktoppricingh2">Premier</h3>
            <h4 className="Starterdesktoppricingh4">&#8377; 999</h4>
            <p className="Starterdesktoppricingp">per month</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "60%",
              }}
            >
              <div className="featuressectionmobiletingwithticks">
                <p className="featuresidepricingpage-mobile">
                  Everything in Growth, plus:
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Invest up
                  to ₹ 50 Lakhs
                </p>
                {/* <p className="featuresidepricingpage-mobile">
                <img src="Pricingpage/check.svg" alt="tick"></img> Advanced
                Financial Literary Program
              </p> */}
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> 4 Financial
                  Counselling Sessions
                </p>
                {/* <p className="featuresidepricingpage-mobile">
                <img src="Pricingpage/check.svg" alt="tick"></img> Advanced
                Gamification & Rewards
              </p> */}
              </div>
              <a
                href="https://mywealth.fabits.com/sign-up"
                className="Starterdesktoppricinga"
              >
                <button
                  className="Starterdesktoppricingbutton premiumpircingbuttonpricingpage"
                  onClick={() => {
                    clevertap.event.push("Clicked Button", {
                      Action: "Choose",
                      page_name: "Pricing Page",
                    });
                  }}
                >
                  Choose
                </button>
              </a>
            </div>
          </div>
          <div className="Elitedesktoppricing-mobile cardssectionpricingpage-mobile">
            <img
              src="Pricingpage/award_star.svg"
              alt="awardstar"
              className="awardstart"
            ></img>
            <h3 className="Starterdesktoppricingh2">Elite</h3>
            <h4 className="Starterdesktoppricingh4">&#8377; 1,999</h4>
            <p className="Starterdesktoppricingp">per month</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "60%",
              }}
            >
              <div className="featuressectionmobiletingwithticks">
                <p className="featuresidepricingpage-mobile">
                  Everything in Premier, plus:
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Unlimited
                  investments
                </p>
                <p className="featuresidepricingpage-mobile">
                  <img src="Pricingpage/check.svg" alt="tick"></img> Dedicated
                  Relationship Manager
                </p>
              </div>
              <a
                href="https://mywealth.fabits.com/sign-up"
                className="Starterdesktoppricinga"
              >
                <button
                  className="Starterdesktoppricingbutton elitechoosebuttonpricingpage elitepremiumpricingmobilebutton"
                  onClick={() => {
                    clevertap.event.push("Clicked Button", {
                      Action: "Choose",
                      page_name: "Pricing Page",
                    });
                  }}
                >
                  Choose
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="planssectionpricecomparations">
          <div className="Starterdesktoppricnig cardssectionpricingpage">
            <img
              src="Pricingpage/hiking.svg"
              alt="hiking"
              className="hikingimage"
            />
            <h3 className="Starterdesktoppricingh2">Starter</h3>
            <h4 className="Starterdesktoppricingh4">Free</h4>
            <p className="Starterdesktoppricingp">for life</p>
            <a
              href="https://mywealth.fabits.com/sign-up"
              className="Starterdesktoppricinga"
            >
              <button
                className="Starterdesktoppricingbutton"
                onClick={() => {
                  clevertap.event.push("Clicked Button", {
                    Action: "Get Started",
                    page_name: "Pricing Page",
                  });
                }}
              >
                Get Started
              </button>
            </a>
          </div>
          <div className="Foundationdesktoppricing cardssectionpricingpage">
            <img
              src="Pricingpage/allergy.svg"
              alt="allergy"
              className="allergyimage"
            />
            <h3 className="Starterdesktoppricingh2">Foundation</h3>
            <h4 className="Starterdesktoppricingh4">&#8377; 49</h4>
            <p className="Starterdesktoppricingp">per month</p>
            <a
              href="https://mywealth.fabits.com/sign-up"
              className="Starterdesktoppricinga"
            >
              <button
                className="Starterdesktoppricingbutton"
                onClick={() => {
                  clevertap.event.push("Clicked Button", {
                    Action: "Choose",
                    page_name: "Pricing Page",
                  });
                }}
              >
                Choose
              </button>
            </a>
          </div>
          <div className="Growthdesktoppricingpage cardssectionpricingpage">
            <img
              src="Pricingpage/psychiatry.svg"
              alt="psychiatry"
              className="psychiatryimage"
            />
            <h3 className="Starterdesktoppricingh2">Growth</h3>
            <h4 className="Starterdesktoppricingh4">&#8377; 299</h4>
            <p className="Starterdesktoppricingp">per month</p>
            <a
              href="https://mywealth.fabits.com/sign-up"
              className="Starterdesktoppricinga"
            >
              <button
                className="Starterdesktoppricingbutton"
                onClick={() => {
                  clevertap.event.push("Clicked Button", {
                    Action: "Choose",
                    page_name: "Pricing Page",
                  });
                }}
              >
                Choose
              </button>
            </a>
          </div>
          <div className="Premierdesktoppricing cardssectionpricingpage">
            <img src="Pricingpage/park.svg" alt="park" className="parkimage" />
            <h3 className="Starterdesktoppricingh2">Premier</h3>
            <h4 className="Starterdesktoppricingh4">&#8377; 999</h4>
            <p className="Starterdesktoppricingp">per month</p>
            <a
              href="https://mywealth.fabits.com/sign-up"
              className="Starterdesktoppricinga"
            >
              <button
                className="Starterdesktoppricingbutton"
                onClick={() => {
                  clevertap.event.push("Clicked Button", {
                    Action: "Choose",
                    page_name: "Pricing Page",
                  });
                }}
              >
                Choose
              </button>
            </a>
          </div>
          <div className="Elitedesktoppricing cardssectionpricingpage">
            <img
              src="Pricingpage/award_star.svg"
              alt="awardstar"
              className="awardstart"
            ></img>
            <h3 className="Starterdesktoppricingh2">Elite</h3>
            <h4 className="Starterdesktoppricingh4">&#8377; 1,999</h4>
            <p className="Starterdesktoppricingp">per month</p>
            <a
              href="https://mywealth.fabits.com/sign-up"
              className="Starterdesktoppricinga"
            >
              <button
                className="Starterdesktoppricingbutton"
                onClick={() => {
                  clevertap.event.push("Clicked Button", {
                    Action: "Choose",
                    page_name: "Pricing Page",
                  });
                }}
              >
                Choose
              </button>
            </a>
          </div>
        </div>
        <div className="conparationsectionpricingpage">
          <table className="comparationtablepricingpage">
            <tr>
              <th className="featuresheedingpricingpage1">Features</th>
              <th>Starter</th>
              <th>Foundation</th>
              <th>Growth</th>
              <th>Premier</th>
              <th>Elite</th>
            </tr>
            <tr>
              <td className="featuresidepricingpage">
                Monthly Subscription for AMC
              </td>
              <td>
                <strong>Free</strong>
              </td>
              <td>
                <strong>&#8377; 49</strong>
              </td>
              <td>
                <strong>&#8377; 299</strong>
              </td>
              <td>
                <strong>&#8377; 999</strong>
              </td>
              <td>
                <strong>&#8377; 1,999</strong>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">Investment Limit</td>
              <td>Up to ₹ 15,000</td>
              <td>Up to ₹ 2 Lakhs</td>
              <td>Up to ₹ 10 Lakhs</td>
              <td>Up to ₹ 50 Lakhs</td>
              <td>No limit</td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">Financial Report Card</td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">
                Retirement Projections & Planning
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">
                Personalised Action Items
              </td>
              <td></td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">
                Personalised Financial Plan
              </td>
              <td></td>
              <td></td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">Tax Planning Module</td>
              <td></td>
              <td></td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">Automated Rebalance</td>
              <td></td>
              <td></td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">Portfolio Management</td>
              <td></td>
              <td></td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">
                Personal Finance Webinars
              </td>
              <td></td>
              <td></td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">
                Advanced Gamification & Rewards
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
            <tr>
              <td className="featuresidepricingpage">
                Dedicated Relationship Manager
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <img src="Pricingpage/check_circle.svg" alt="tick"></img>
              </td>
            </tr>
          </table>
        </div>
        <div className="pricingpagebottomsection">
          <h2 className="Additioinalinfoheadingpricingpage">
            Additional Information
          </h2>
          <div className="additionalinformationandtextbeside">
            <div className="additionalinformationtablesection">
              <table className="additionnalinformationtablesectiontable">
                <tr>
                  <th
                    colSpan={2}
                    className="tableheedingadditionalinformationsection
                  "
                  >
                    Detailed Pricing
                  </th>
                </tr>
                <tr>
                  <td className="tabledataoftheadditionalsection leftpartthing">
                    Brokerage
                  </td>
                  <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                    Nil
                  </td>
                </tr>
                <tr>
                  <td className="tabledataoftheadditionalsection leftpartthing">
                    Charges
                  </td>
                  <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                    <strong>Resident Indian:</strong> Equity Delivery<br></br>
                    <strong>NRI: &nbsp; </strong>
                    PIS
                  </td>
                </tr>
                <tr>
                  <td className="tabledataoftheadditionalsection leftpartthing">
                    STT/ CTT
                  </td>
                  <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                    0.1% on Buy & Sell
                  </td>
                </tr>
                <tr>
                  <td className="tabledataoftheadditionalsection leftpartthing">
                    Transaction Charges
                  </td>
                  <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                    NSE: 0.00345%
                  </td>
                </tr>
                <tr>
                  <td className="tabledataoftheadditionalsection leftpartthing">
                    GST
                  </td>
                  <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                    18% on transaction charges
                  </td>
                </tr>
                <tr>
                  <td className="tabledataoftheadditionalsection leftpartthing">
                    SEBI Charges
                  </td>
                  <td className="tabledataoftheadditionalsection rightanswertothequestionthing">
                    &#8377; 10 per crore
                  </td>
                </tr>
                <tr className="lasttableitempricepage">
                  <td className="tabledataoftheadditionalsection leftpartthing lastboxofadditionalinformation">
                    Stamp Charges
                  </td>
                  <td className="tabledataoftheadditionalsection lastboxofadditionalinformation rightanswertothequestionthing">
                    0.015% or ₹ 1500/crore on buy side
                  </td>
                </tr>
              </table>
            </div>
            <div className="textsectionbesideadditionalinformation">
              <h4 className="textsectionbesideadditionalinformationh4">
                Securities Transaction Tax (STT)/ Commodities Transaction Tax
                (CTT)
              </h4>
              <p className="textsectionbesideadditionalinformationp">
                STT is the tax levied by the Government when transacting on the
                securities market through any of the stock exchanges. It is
                levied on both “Buy” and “Sell” side when trading results in
                delivery and only on sell side when trading does not result in
                Delivery (like intraday or in F&O). Trading in the currency
                segment is exempt from STT.
              </p>
              <h4 className="textsectionbesideadditionalinformationh4">
                Exchange Transaction Charges
              </h4>
              <p className="textsectionbesideadditionalinformationp">
                These charges are levied by Exchanges on the traded volume of
                the customer. This is generally specified in terms of Rupees per
                Crore of Turnover. The charge includes:
              </p>
              <h4 className="textsectionbesideadditionalinformationh4">
                Exchange transaction charges + Clearing Member charges
              </h4>
              <p className="textsectionbesideadditionalinformationp">
                Charged by exchanges (NSE, BSE, MCX) and Clearing members.
              </p>
              <h4 className="textsectionbesideadditionalinformationh4">GST</h4>
              <p className="textsectionbesideadditionalinformationp">
                Tax levied by the government on the services rendered is
                calculated presently @ <strong>18%</strong> on brokerage,
                transaction charges and any other charges for services rendered
                by the broker or the exchange.
              </p>
              <h4 className="textsectionbesideadditionalinformationh4">
                SEBI Charges
              </h4>
              <p className="textsectionbesideadditionalinformationp lastparaoftheadditionalsection">
                The SEBI charges are calculated presently @ ₹ 10/ 1 crore
                (w.e.f. 01.06.2021) for both equity and commodity trades on the
                traded volume of the customer in all segments.
              </p>
              <p className="textsectionbesideadditionalsectionlastphrase">
                * Regular Mutual Funds
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
