import React from "react";
import styles from "./InvestmentDetails.module.css";
import Tabs from "../Tabs/Tabs";
import Slider from "../Slider/Slider";
import DoughnutChart from "../Doughnut/Doughnut";
import redirectTo from "../../../../utils/RedirectTo";

// Function to format number with Indian system commas
const formatIndianCurrency = (number) => {
  const parts = number.toString().split(".");
  let integerPart = parts[0];
  const decimalPart = parts[1] ? `.${parts[1]}` : "";

  // Format integer part with Indian commas
  const lastThree = integerPart.slice(-3);
  const rest = integerPart.slice(0, -3);
  if (rest) {
    integerPart = `${rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",")},${lastThree}`;
  }

  return integerPart + decimalPart;
};

const InvestmentDetails = ({
  setInvestmentAmount,
  setExpectedReturns,
  setInvestmentYears,
  investmentAmount,
  expectedReturns,
  investmentYears,
  setActiveTab,
  activeTab,
}) => {
  const calculateSIPAmount = (P, r, n, t) => {
    const monthlyRate = r / (n * 100);
    const months = n * t;
    return (
      P *
      ((Math.pow(1 + monthlyRate, months) - 1) / monthlyRate) *
      (1 + monthlyRate)
    );
  };

  const calculateLumpsumAmount = (P, r, t) => {
    return P * Math.pow(1 + r / 100, t);
  };

  // Total amount based on Monthly Mutual Fund SIP
  const totalAmountSIP = calculateSIPAmount(
    investmentAmount,
    expectedReturns,
    12,
    investmentYears
  );
  const interestEarnedSIP =
    totalAmountSIP - investmentAmount * 12 * investmentYears;

  // Total amount based on Annual Lump Sum Investment
  const totalAmountLumpsum = calculateLumpsumAmount(
    investmentAmount,
    expectedReturns,
    investmentYears
  );
  const interestEarnedLumpsum = totalAmountLumpsum - investmentAmount;

  // Determine values based on the selected tab
  const totalAmount = isNaN(
    activeTab === 1 ? totalAmountSIP : totalAmountLumpsum
  )
    ? 0
    : activeTab === 1
    ? totalAmountSIP
    : totalAmountLumpsum;

  const interestEarned = isNaN(
    activeTab === 1 ? interestEarnedSIP : interestEarnedLumpsum
  )
    ? 0
    : activeTab === 1
    ? interestEarnedSIP
    : interestEarnedLumpsum;

  const formattedInterestEarned = formatIndianCurrency(
    interestEarned.toFixed(2)
  );
  const formattedFinalAmount = formatIndianCurrency(totalAmount.toFixed(2));

  const data = {
    datasets: [
      {
        data: [
          activeTab === 1
            ? investmentAmount * 12 * investmentYears
            : investmentAmount,
          interestEarned,
        ],
        backgroundColor: ["#41558D", "#82B770"],
      },
    ],
  };

  const tabs = [
    {
      id: 1,
      name: "MutualFund",
      content: (
        <div>
          <div>
            <p className={styles.sliderTitle}>Yearly investment amount</p>
            <Slider
              endRange={200000}
              startRange={500}
              price
              step={100}
              onChange={setInvestmentAmount}
            />
          </div>

          <div style={{ margin: "24px 0px" }}>
            <p className={styles.sliderTitle}>Expected returns</p>
            <Slider
              endRange={50}
              startRange={1}
              percentage
              step={1}
              onChange={setExpectedReturns}
            />
          </div>

          <div>
            <p className={styles.sliderTitle}>Investment period</p>
            <Slider
              endRange={40}
              startRange={1}
              year
              step={1}
              onChange={setInvestmentYears}
            />
          </div>
        </div>
      ),
    },
    {
      id: 2,
      name: "Lumpsum",
      content: (
        <div>
          <div>
            <p className={styles.sliderTitle}>Lumpsum amount</p>
            <Slider
              endRange={200000}
              startRange={100}
              price
              step={100}
              onChange={setInvestmentAmount}
            />
          </div>

          <div style={{ margin: "24px 0px" }}>
            <p className={styles.sliderTitle}>Expected returns</p>
            <Slider
              endRange={20}
              startRange={0}
              percentage
              step={1}
              onChange={setExpectedReturns}
            />
          </div>

          <div>
            <p className={styles.sliderTitle}>Investment period</p>
            <Slider
              endRange={50}
              startRange={1}
              year
              step={1}
              onChange={setInvestmentYears}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.margin_container}>
        <div className={styles.inner_container1}>
          <h1>Investment Details</h1>
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

          <div className={styles.consistency} style={{ marginTop: "24px" }}>
            <p className={styles.spanContent}>
              A <span>{activeTab === 1 ? "yearly sip" : "Lumpsum Amount"}</span>{" "}
              of <span>₹ {formatIndianCurrency(investmentAmount)}</span> over
              <span> {investmentYears} years</span>, at an{" "}
              <span>{expectedReturns} %</span> rate of return would yield a
              total amount of <span>₹ {formattedFinalAmount}</span>
            </p>
          </div>
        </div>

        <div className={styles.inner_container2}>
          <div className={styles.graph_container}>
            <h1>Investment Breakdown</h1>

            <div className={styles.donut}>
              <DoughnutChart data={data} />
            </div>

            <div className={styles.legend}>
              <div className={styles.l1}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend1}></div>
                  <p className={styles.legendTitle}>Total investment</p>
                </div>
                <p className={styles.legendPrice}>
                  ₹{" "}
                  {formatIndianCurrency(
                    activeTab === 1
                      ? investmentAmount * 12 * investmentYears
                      : investmentAmount
                  )}
                </p>
              </div>

              <div className={styles.l2}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend2}></div>
                  <p className={styles.legendTitle}>Interest earned</p>
                </div>
                <p className={styles.legendPrice}>
                  ₹ {formattedInterestEarned}
                </p>
              </div>
            </div>

            <hr className={styles.horizontalRule} />

            <div className={styles.FinalAmount}>
              <p className={styles.FinalAmountTitle}>Final amount</p>
              <p className={styles.legendPrice}>₹ {formattedFinalAmount}</p>
            </div>
          </div>

          <button
            className={styles.startInvesting}
            onClick={() => redirectTo()}
          >
            <span className={styles.btnContent}>Start investing</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetails;
