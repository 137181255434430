import React from "react";
import "./KRA.css";

const KRA = () => {
  return (
    <div className="kra-container">
      <h1>KRA Status: On hold/Rejected</h1>
      <p>
        Your application could be put on hold by KYC Registration Agencies
        (KRA). Just follow the instructions given below to complete the
        validation:
      </p>

      <ol>
        <li>
          Visit this site to find your KRA:{" "}
          <a target="_blank" href="https://www.cvlkra.com/ ">
            Find your KRA
          </a>
        </li>
        <li>Enter your PAN and the captcha and click ‘Submit’</li>
        <li>
          Your KRA will be visible along with the reason, “EMAIL ID VALIDATION
          FAILED” or “MOBILE NUMBER VALIDATION FAILED”.
        </li>
      </ol>

      <h2>
        If your KRA is <span>CVL</span>, <span>NDML</span> or <span>KARVY</span>
        :
      </h2>
      <ol>
        <li>
          Click the link:{" "}
          <a
            target="_blank"
            href="https://validate.cvlindia.com/CVLKRAVerification_V1/"
          >
            CVL
          </a>
          ,{" "}
          <a
            target="_blank"
            href="https://kra.ndml.in/ClientInitiatedKYC-webApp/#/ClientinitiatedKYC"
          >
            NDML
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            href="https://www.karvykra.com/KYC_Validation/Default.aspx "
          >
            KARVY
          </a>
        </li>
        <li>Enter your PAN and click ‘Submit and Exit’</li>
        <li>
          Click “Get OTP” to receive the validation OTP to the mobile number
          and/or E-mail ID mentioned there.
        </li>
        <li>Enter the OTP and click ‘Submit’ to complete the validation.</li>
      </ol>

      <h2>
        If your KRA is <span>DOTEX</span> or <span>CAMS</span>:
      </h2>
      <ol>
        <li>
          You will receive either an e-mail to your registered mail ID or an SMS
          to your registered mobile number.
        </li>
        <li>
          Click the link provided in the E-mail or SMS and complete the
          following steps:
          <ol type="a">
            <li>Enter your PAN and click ‘Submit and Exit’</li>
            <li>
              Click “Get OTP” to receive the validation OTP to the mobile number
              or/and E-mail ID mentioned there.
            </li>
            <li>
              Enter the OTP and click ‘Submit’ to complete the validation.
            </li>
          </ol>
        </li>
      </ol>

      <p>
        If the application is rejected or put on hold due to any other reason,
        kindly contact us at:
      </p>
      <ul>
        <li>
          Email:{" "}
          <a target="_blank" href="mailto:supports@fabits.com">
            supports@fabits.com
          </a>
        </li>
        <li>Mobile: +91 8217801975</li>
      </ul>
    </div>
  );
};

export default KRA;
