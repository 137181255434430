import React from "react";
import "./riskDisclosure.css"

const RiskDisclosure = () => {
  return (
    <>
      <ul id="risk-points">
        <li>
          9 out of 10 individual traders in equity futures and Options Segment,
          incurred net losses.
        </li>
        <li>
          On an average, loss makers registered net trade loss close to 50,000.
        </li>
        <li>
          Over and above the net trading losses incurred, loss makers expended
          an additional 28% of net trading losses as transaction costs.
        </li>
        <li>
          Those making net trading profits, incurred between 15% to 50% such
          profits- as transaction cost.
        </li>
      </ul>

      <div>
        Source: <br />
        <a
          href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html"
          target="_blank"
          rel="noreferrer"
        >
          SEBI | Study – Analysis of Profit and Loss of Individual Traders
          dealing in Equity F&O Segment
        </a>
      </div>
    </>
  );
};

export default RiskDisclosure;
