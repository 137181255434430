import React, { useEffect } from "react";
import "./Termsandconditions.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import { AccountClosureForm } from "../assets/account_closure";
function AccountClosure() {
  const location = useLocation();
  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
  }, []);
  return (
    <>
      <div className="termsandconditionspage">
        <Helmet>
          <title>Fabits - Account Closure</title>
          <meta
            name="description"
            content="Fabits accounts can be closed online and offline."
          />
        </Helmet>
        <div className="termsandconditionsheeding">
          <h1 className="headingoftandc" style={{ textAlign: "center" }}>
            Account Closure
          </h1>
          <h3 style={{ textAlign: "center" }}>
            Fabits accounts can be closed online and offline.
          </h3>
        </div>
        <div>
          <h1 style={{ margin: "10px auto" }}>
            Who can close a Fabits account?
          </h1>

          <ol className="olol">
            <li>Resident Indians</li>
            <li>Non-resident Indians (NRIs) </li>
            <li>Minors with Fabits access </li>
          </ol>

          <h2 style={{ margin: "10px auto" }}>Online Closure</h2>

          <h2 style={{ margin: "10px auto" }}>
            Steps to close your account online:
          </h2>

          <ol className="olol">
            <li>
              <p>
                Visits Fabits Backoffice using the following link -{" "}
                <a
                  href="https://backoffice.fabits.app/shrdbms/userlogin.ss"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  https://backoffice.fabits.app/shrdbms/userlogin.ss
                </a>
              </p>

              <img
                style={{ width: "80%", margin: "10px auto" }}
                src="/AccountClosure/image1.png"
                alt="image-1"
              />
            </li>
            <li>
              Login with your UCCID as User ID and PAN card number in ALL CAPS
              as password.
            </li>
            <li>
              <p> Click on "Account Closure" under Re-KYC.</p>
              <img
                style={{ width: "auto", margin: "10px auto" }}
                src="/AccountClosure/image3.png"
                alt="image-1"
              />
            </li>
            <li>
              <p> Choose the account you want to close.</p>
              <img
                style={{ width: "80%", margin: "10px auto" }}
                src="/AccountClosure/image2.png"
                alt="image-1"
              />
            </li>
            <li>Click on "Next Step".</li>
            <li>
              <p>Enter ‘Reason for Closure’</p>
              <img
                style={{ width: "80%", margin: "10px auto" }}
                src="/AccountClosure/image5.png"
                alt="image-1"
              />
            </li>
            <li>Click on "Next Step".</li>
            <li>
              For users that have holdings with Fabits, enter DPID and Client ID
              of the trading member that you want to transfer funds to.
            </li>
            <li>
              Upload the Consolidated Master Report of your target Trading
              Member and a photo of your signature.
              <img
                style={{ width: "80%", margin: "10px auto" }}
                src="/AccountClosure/image4.png"
                alt="image-1"
              />
            </li>
            <li>
              Click "Final Step".
              <img
                style={{ width: "80%", margin: "10px auto" }}
                src="/AccountClosure/image7.png"
                alt="image-1"
              />
            </li>
            <li>
              Check the “I Agree Terms and Conditions” checkbox and click on
              “Continue to Close”
              <img
                style={{ width: "80%", margin: "10px auto" }}
                src="/AccountClosure/image6.png"
                alt="image-1"
              />
            </li>
            <li>
              Enter the OTP received on your email/phone number.
              <img
                style={{ width: "80%", margin: "10px auto" }}
                src="/AccountClosure/image8.png"
                alt="image-1"
              />
            </li>
          </ol>
        </div>

        <div classNameName="contentstartshere">
          <h2 style={{ margin: "20px auto" }}>Offline Closure</h2>

          <h2>Steps to close your account offline:</h2>

          <div>
            <h3 style={{ margin: "20px auto" }}>
              Download the closure form:{" "}
              <a href={AccountClosureForm} target="_blank" rel="noreferrer">
                Download
              </a>
            </h3>
          </div>

          <div>
            <h3 style={{ margin: "10px auto" }}>Courier the signed form to:</h3>
            <address style={{ marginBottom: "30px" }}>
              Fabits 294/1 First Floor,
              <br />
              7th Cross, Domlur 1st Stage, - 560071
            </address>
          </div>

          <div>
            <h3 style={{ margin: "10px auto" }}>Closure Processing Time:</h3>
            <p style={{ marginBottom: "30px" }}>
              Your Fabits account will be closed within 7 business days. You'll
              receive a confirmation email upon completion.
            </p>
          </div>

          <div>
            <h3 style={{ margin: "10px auto" }}>Important Note:</h3>
            <p>
              Account closure requests may be rejected if your holdings have
              undergone corporate actions (like bonuses or splits). The account
              can only be closed after these shares are credited to your demat
              account.
              <br />
              According to SEBI regulation, all data will be retained for a
              period of 8 years prior to deletion.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountClosure;
