const Account_Closure_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Account Closer Form.pdf";
const Demat_Debit_and_Pledge_Instruction =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Demat Debit and Pledge Instruction (DDPI).pdf";
const Dematerialisation_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Dematerialisation Request Form.pdf";
const DIS_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/DIS Request Form.pdf";
const Dormant_Account_Reactivation_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Dormant Account Reactivation Form.pdf";
const Freeze_Unfreeze_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Freeze-Unfreeze Request Form.pdf";
const Invocation_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Invocation Request Form (IRF).pdf";
const KYC_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/KYC Form.pdf";
const Margin_Invocation_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Margin Invocation Request Form.pdf";
const Margin_Pledge_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Margin Pledge Request Form.pdf";
const Margin_Un_pledge_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Margin Un-pledge Request Form.pdf";
const Nomination_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Nomination Form.pdf";
const Nomination_Opting_Out_Declaration_form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Nomination Opting Out Declaration form.pdf";
const Pledge_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Pledge Request Form.pdf";
const Rejection_Letter_for_Dematerialisation_Request_Number_DRN_BO_TO_DP =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Rejection Letter for Dematerialisation Request Number (DRN) (BO-to-DP).pdf";
const Rejection_Letter_for_Dematerialisation_Request_Number_DRN =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Rejection Letter for Dematerialisation Request Number (DRN).pdf";
const Trading_Demat_Account_Details_Addition_Modification_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Trading  Demat Account Details Addition  Modification Form.pdf";
const Transmission_cum_Dematerialisation =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Transmission cum Dematerialisation.pdf";
const Transmission_Form_Sole_Holder =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Transmission Form Sole Holder.pdf";
const Transmission_With_and_Without_Nominee =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Transmission With and Without Nominee.pdf";
const Transposition_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Transposition Request Form.pdf";
const Unpledge_Request_Form =
  "https://d3lgi69rk7563.cloudfront.net/assets/Policy_forms/Unpledge Request Form.pdf";

export {
  Account_Closure_Form,
  Demat_Debit_and_Pledge_Instruction,
  Dematerialisation_Request_Form,
  DIS_Request_Form,
  Dormant_Account_Reactivation_Form,
  Freeze_Unfreeze_Request_Form,
  Invocation_Request_Form,
  KYC_Form,
  Margin_Invocation_Request_Form,
  Margin_Pledge_Request_Form,
  Margin_Un_pledge_Request_Form,
  Nomination_Form,
  Nomination_Opting_Out_Declaration_form,
  Pledge_Request_Form,
  Rejection_Letter_for_Dematerialisation_Request_Number_DRN_BO_TO_DP,
  Rejection_Letter_for_Dematerialisation_Request_Number_DRN,
  Trading_Demat_Account_Details_Addition_Modification_Form,
  Transmission_cum_Dematerialisation,
  Transmission_Form_Sole_Holder,
  Transmission_With_and_Without_Nominee,
  Transposition_Request_Form,
  Unpledge_Request_Form,
};
