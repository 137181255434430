import React from "react";
import styles from "./InvestmentDetails.module.css";
import Slider from "../Slider/Slider";
import DoughnutChart from "../Doughnut/Doughnut";
import redirectTo from "../../../../utils/RedirectTo";

const InvestmentDetails = ({
  setInvestmentAmount,
  setExpectedReturns,
  setInvestmentYears,
  investmentAmount,
  expectedReturns,
  investmentYears,
  setActiveTab,
  activeTab,
}) => {
  // Function to calculate EMI
  const calculateEMI = (P, R, N) => {
    const monthlyRate = R / 12 / 100; // Convert annual interest rate to a monthly rate
    const totalPayments = N * 12; // Convert loan tenure from years to months
    return (
      (P * monthlyRate * Math.pow(1 + monthlyRate, totalPayments)) /
      (Math.pow(1 + monthlyRate, totalPayments) - 1)
    );
  };

  // Helper function to format currency with Indian numbering system
  const formatCurrency = (value) => {
    if (isNaN(value)) return "0.00";
    let [integerPart, decimalPart] = value.toFixed(2).split(".");
    let lastThree = integerPart.substring(integerPart.length - 3);
    let otherNumbers = integerPart.substring(0, integerPart.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let formatted =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return formatted + "." + decimalPart;
  };

  // Ensure input values are numbers and default to 0 if not
  const investmentAmountNum = isNaN(Number(investmentAmount))
    ? 0
    : Number(investmentAmount);
  const expectedReturnsNum = isNaN(Number(expectedReturns))
    ? 0
    : Number(expectedReturns);
  const investmentYearsNum = isNaN(Number(investmentYears))
    ? 0
    : Number(investmentYears);

  // EMI Calculation
  const emiAmount = calculateEMI(
    investmentAmountNum,
    expectedReturnsNum,
    investmentYearsNum
  );
  const investedMonths = investmentYearsNum * 12;
  const totalPaymentEMI = emiAmount * investedMonths;
  const interestEarned = totalPaymentEMI - investmentAmountNum;
  const finalAmount = investmentAmountNum + interestEarned;

  const formattedEMI = formatCurrency(emiAmount);
  const formattedInterestEarned = formatCurrency(interestEarned);
  const formattedFinalAmount = formatCurrency(finalAmount);
  const formattedInvestmentAmount = formatCurrency(investmentAmountNum);

  const data = {
    datasets: [
      {
        data: [investmentAmountNum, interestEarned],
        backgroundColor: ["#41558D", "#82B770"],
      },
    ],
  };

  React.useEffect(() => {
    setActiveTab(2); // Ensure Lumpsum tab is always active
  }, [setActiveTab]);

  const tab = {
    id: 2,
    name: "Lumpsum",
    content: (
      <div>
        <div>
          <p className={styles.sliderTitle}>Lumpsum amount</p>
          <Slider
            endRange={10000000}
            startRange={100000}
            price
            step={100}
            onChange={setInvestmentAmount}
          />
        </div>

        <div style={{ margin: "24px 0px" }}>
          <p className={styles.sliderTitle}>Expected returns</p>
          <Slider
            endRange={20}
            startRange={0}
            percentage
            step={1}
            onChange={setExpectedReturns}
          />
        </div>

        <div>
          <p className={styles.sliderTitle}>Investment period</p>
          <Slider
            endRange={50}
            startRange={1}
            year
            step={1}
            onChange={setInvestmentYears}
          />
        </div>
      </div>
    ),
  };

  return (
    <div className={styles.container}>
      <div className={styles.margin_container}>
        <div className={styles.inner_container1}>
          <h1>Investment Details</h1>
          <div>{tab.content}</div>

          <div className={styles.consistency} style={{ marginTop: "24px" }}>
            <p className={styles.spanContent}>
              A <span>lumpsum amount</span> of{" "}
              <span>₹ {formattedInvestmentAmount}</span> over
              <span> {investmentYearsNum} years</span>, at an{" "}
              <span>{expectedReturnsNum} %</span> rate of return would yield a
              total amount of <span>₹ {formattedFinalAmount}</span>, with a
              Monthly EMI of <span>₹ {formattedEMI}</span>.
            </p>
          </div>
        </div>

        <div className={styles.inner_container2}>
          <div className={styles.graph_container}>
            <h1>Investment Breakdown</h1>

            <div className={styles.donut}>
              <DoughnutChart data={data} />
            </div>

            <div className={styles.legend}>
              <div className={styles.l1}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend1}></div>
                  <p className={styles.legendTitle}>Total investment</p>
                </div>
                <p className={styles.legendPrice}>
                  ₹ {formattedInvestmentAmount}
                </p>
              </div>

              <div className={styles.l2}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend2}></div>
                  <p className={styles.legendTitle}>Interest earned</p>
                </div>
                <p className={styles.legendPrice}>
                  ₹ {formattedInterestEarned}
                </p>
              </div>

              <div className={styles.l1}>
                <div className={styles.l1_left}>
                  {/* <div className={styles.colorLegend3}></div> */}
                  <p className={styles.legendTitle}>Monthly EMI</p>
                </div>
                <p className={styles.legendPrice}>₹ {formattedEMI}</p>
              </div>
            </div>

            <hr className={styles.horizontalRule} />

            <div className={styles.FinalAmount}>
              <p className={styles.FinalAmountTitle}>Final amount</p>
              <p className={styles.legendPrice}>₹ {formattedFinalAmount}</p>
            </div>
          </div>

          <button
            className={styles.startInvesting}
            onClick={() => redirectTo()}
          >
            <span className={styles.btnContent}>Start investing </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetails;
