const Account_closure_policy =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Account closure policy.pdf";
const Fabits_Guidance_note_for_investors_16_07_2024 =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Fabits _Guidance_note for investors 16.07.2024.pdf";
const Fabits_CDSL_Do_and_Donts_for_investor_16_07_2024 =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Fabits CDSL Do & Don'ts for investor_ 16.07.2024.pdf";
const Fabits_General_Rules_or_regulations_of_investing_16_07_2024 =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Fabits General Rules, or regulations of investing 16.07.2024.pdf";
const Fabits_NSE_Dos_and_Donts_for_investor_16_07_2024 =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Fabits NSE Dos and Don'ts for investor 16.07.2024.pdf";
const Fabits_Rights_and_Obligations_of_stock_brokers_16_07_2024 =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Fabits Rights & Obligations of stock brokers 16.07.2024.pdf";
  
const Fabits_Rights_and_Obligations_DP =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Fabits_Rights_and_Obligations_DP.pdf";


const Fabits_Risk_Disclosure_Document_RDD_16_07_2024 =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Fabits Risk Disclosure Doument(RDD) 16.07.2024.pdf";
const General_Rules_or_regulations_of_investing =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/General Rules, or regulations of investing.pdf";
const Most_Important_Terms_and_Conditions_MITC =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Most_Important_Terms_and_Conditions_MITC.pdf";
const Voluntary_Freezing_or_blocking_of_online_access_of_Trading_Account =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/Voluntary Freezing or blocking  of online access of Trading Account.pdf";
const PNP_in_Diff_Languages =
  "https://d3lgi69rk7563.cloudfront.net/assets/Investor_Awareness/PNP_in_Diff__Languages.zip";

export {
  Account_closure_policy,
  Fabits_Guidance_note_for_investors_16_07_2024,
  Fabits_CDSL_Do_and_Donts_for_investor_16_07_2024,
  Fabits_General_Rules_or_regulations_of_investing_16_07_2024,
  Fabits_NSE_Dos_and_Donts_for_investor_16_07_2024,
  Fabits_Rights_and_Obligations_of_stock_brokers_16_07_2024,
  Fabits_Rights_and_Obligations_DP,
  Fabits_Risk_Disclosure_Document_RDD_16_07_2024,
  General_Rules_or_regulations_of_investing,
  Most_Important_Terms_and_Conditions_MITC,
  Voluntary_Freezing_or_blocking_of_online_access_of_Trading_Account,
  PNP_in_Diff_Languages,
};
