import React from "react";

const Table = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Year</th>
          <th>Total Investment</th>
          <th>Interest Earned</th>
          <th>Total Value</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.year}</td>
            <td>{row.totalInvestment}</td>
            <td>{row.interestEarned}</td>
            <td>{row.totalValue}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
