import React, { useEffect, useState } from "react";
import styles from "./calculator.module.css";
import InvestmentDetails from "./InvestmentDetails/InvestmentDetails";
import DetailGraph from "./DetailedBreakdown/DetailedBreakDown";

import { useNavigate } from "react-router-dom";
import KnowledgeHub from "./KnowledgeHub/KnowledgeHub";

const Ppf = () => {
  const navigate = useNavigate();
  const [investmentAmount, setInvestmentAmount] = useState(500);
  const [expectedReturns, setExpectedReturns] = useState(7.1);
  const [investmentYears, setInvestmentYears] = useState(15);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={styles.breadCrumb}
        onClick={() => navigate("/calculator")}
      >
        <span class="material-symbols-rounded">chevron_left</span>
        <h1>PPF Calculator</h1>
      </div>

      <InvestmentDetails
        investmentAmount={investmentAmount}
        setInvestmentAmount={setInvestmentAmount}
        expectedReturns={expectedReturns}
        setExpectedReturns={setExpectedReturns}
        investmentYears={investmentYears}
        setInvestmentYears={setInvestmentYears}
      />

      <DetailGraph
        investmentAmount={investmentAmount}
        expectedReturns={expectedReturns}
        investmentYears={investmentYears}
      />

      <KnowledgeHub />
    </div>
  );
};

export default Ppf;
