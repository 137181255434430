import React, { useRef } from "react";
import styles from "./knowledgeHub.module.css";

const KnowledgeHub = () => {
  // Create refs for each FAQ answer section
  const faqRefs = {
    faq1: useRef(null),
    faq2: useRef(null),
    faq3: useRef(null),
    faq4: useRef(null),
    faq5: useRef(null),
  };

  // Scroll to the corresponding FAQ answer
  const scrollToFaq = (faqKey) => {
    if (faqRefs[faqKey] && faqRefs[faqKey].current) {
      // Add the offset class before scrolling
      faqRefs[faqKey].current.classList.add(styles.scrollOffset);

      // Scroll into view with smooth behavior
      faqRefs[faqKey].current.scrollIntoView({ behavior: "smooth" });

      // Remove the offset class after a short delay
      setTimeout(() => {
        faqRefs[faqKey].current.classList.remove(styles.scrollOffset);
      }, 500); // Adjust delay if needed
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.topDiv}>
          <h1>FD Calculator - Knowledge Hub</h1>
          <p>
            A term investment option that is provided by numerous banks and
            NBFCs is a fixed deposit. Higher interest rates are often available
            on these deposits, however there are usually restrictions. Your
            deposit amount is locked in these accounts for a set amount of time,
            which can be anywhere from seven days to ten years. The option of
            choice for stability-oriented investors is a fixed deposit.
          </p>
        </div>
      </div>
      <div className={styles.subInnerContainer}>
        <div className={styles.FAQside}>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq1")}>
            <h2>What is a Fixed Deposit Calculator?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq2")}>
            <h2> How to Use a Fixed Deposit Calculator</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq3")}>
            <h2> Tax Benefits on FD</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq4")}>
            <h2>How is premature FD penalty calculated?</h2>
          </div>
          <div className={styles.FAQques1} onClick={() => scrollToFaq("faq5")}>
            <h2>Frequently Asked Questions(FAQ)</h2>
          </div>
        </div>

        <div className={styles.FAQquesAns}>
          <div className={styles.FAQ1} ref={faqRefs.faq1}>
            <h1>What is a Fixed Deposit Calculator?</h1>
            <p>
              The interest and total amount that will accrue at the time of
              maturity can be found using an FD calculator.
            </p>
            <p>The FD calculator has a number of benefits, including:</p>
            <ul>
              <li>
                Precise computations of maturity amounts assist you in selecting
                the ideal deposit tenure.
              </li>
              <li>
                Enter interest rates from several banks and evaluate them to
                determine which is the best option for your deposit.
              </li>
              <li>
                Make sure all computations are precise and steer clear of
                errors.
              </li>
              <li>
                Determine maturity amounts far more quickly than with a
                spreadsheet or digital calculator.
              </li>
              <li>
                Compare the maturity amounts to make more informed decisions
                when it comes to renewing fixed deposits.
              </li>
            </ul>
            <p>
              There are two types of FD that you may avail of – simple interest
              FD and compound interest FD.
            </p>
            <p>
              The fixed deposit calculator for simple interest FD uses the
              following formula –
            </p>
            <p>
              M = P + (P × r × t / 100), where –
              <br />
              P is the principal amount that you deposit
              <br />
              r is the rate of interest per annum
              <br />t is the tenure in years
            </p>
            <p>
              For example, if you deposit a sum of Rs. 1,00,000 for 5 years at
              10% interest, the equation reads –
            </p>
            <p>
              M = Rs. 1,00,000 + (1,00,000 × 10 × 5 / 100)
              <br />= Rs. 1,50,000
            </p>
            <p>
              For compound interest FD, the FD return calculator uses the
              following formula –
            </p>
            <p>
              M = P × (1 + i / 100)<sup>t</sup>, where –
              <br />
              P is the principal amount
              <br />
              i is the rate of interest per period
              <br />t is the tenure
            </p>
            <p>
              For example, if you take the same variables, the compound interest
              FD will accrue,
            </p>
            <p>
              M = Rs. 1,00,000 × (1 + 10 / 100)<sup>5</sup>
              <br />= Rs. 1,61,051
            </p>
          </div>

          <div className={styles.FAQ2} ref={faqRefs.faq2}>
            <h1>How to Use a Fixed Deposit Calculator</h1>
            <p>
              The amount you're depositing, the duration of time you intend to
              keep your money in the FD, and the interest rate—which might be
              compounded monthly, half-yearly, or annually—are the three
              essential inputs you need to enter into an FD (Fixed Deposit)
              calculator correctly.
            </p>
            <ul className={styles["no-bullets"]}>
              <li>1. Make sure you have access to all relevant data.</li>
              <li>2 Make sure you have access to all relevant data.</li>
              <li>3. Make sure you have access to all relevant data.</li>
            </ul>
          </div>

          <div className={styles.FAQ3} ref={faqRefs.faq3}>
            <h1>Tax Benefits on FD</h1>
            <p>
              As with other investing and tax-saving options for individuals,
              Fixed Deposit programs are subject to taxes. In the event that an
              FD's total interest surpasses Rs. 10,000 in a single fiscal year,
              a TDS of 10% is withheld from the returns. You can see that SIPs
              are more advantageous in the long run when we compare this to the
              SIP plan.
            </p>
          </div>

          <div className={styles.FAQ4} ref={faqRefs.faq4}>
            <h1>How is premature FD penalty calculated?</h1>
            <p>
              A penalty of 1% is imposed for early withdrawals of fixed deposits
              (FDs) totaling more than ₹5 lakh. For the period that the deposit
              was kept with the bank, the interest rate will be either 0.50% or
              1% less than the agreed rate, whichever is lower.
            </p>
            <p>
              The amount of the FD early withdrawal penalty is determined using
              compound interest. The formula for calculating the maturity amount
              after the penalty is applied is:
            </p>
            <p>
              <code>
                A = P &times;{" "}
                <i>
                  (1 + r/n)<sup>nt</sup>
                </i>
              </code>
            </p>
            <p>
              Where:
              <ul>
                <li>
                  <b>A</b> represents the final maturity amount after the
                  penalty.
                </li>
                <li>
                  <b>P</b> is the principal amount.
                </li>
                <li>
                  <b>r</b> is the reduced interest rate.
                </li>
                <li>
                  <b>n</b> is the compound interest frequency.
                </li>
                <li>
                  <b>t</b> is the tenure.
                </li>
              </ul>
            </p>
          </div>

          <div className={styles.FAQ4} ref={faqRefs.faq5}>
            <h1>Frequently Asked Questions</h1>
            <ul>
              <li>
                <span className={styles.boldText}>
                  What are the factors that affect FD interest rates?
                </span>
                <br />A number of variables, including the length of the
                deposit, the applicant's age (older people usually receive
                higher interest rates), and the state of the economy, can impact
                FD interest rates.
              </li>
              <li>
                <span className={styles.boldText}>
                  What is the minimum tenure for fixed deposits?
                </span>
                <br />
                The minimum tenure for fixed deposits is 7 days.
              </li>
              <li>
                <span className={styles.boldText}>
                  How is FD amount calculated?
                </span>
                <br />
                Both cumulative and non-cumulative fixed deposits exist. When a
                depositor receives their money back at maturity, cumulative FDs
                are computed throughout the whole term of the FD. On the other
                hand, non-cumulative FDs are computed on a monthly, semi-annual,
                and annual basis.
              </li>
              <li>
                <span className={styles.boldText}>
                  Is it necessary to furnish any documents for using the online
                  FD calculator?
                </span>
                <br />
                No, there is no need for any documents when using the FD
                calculator.
              </li>
              <li>
                <span className={styles.boldText}>
                  How many years will FD double?
                </span>
                <br />
                The number of years in which FD will double is calculated by the
                formula which is 72 divided by the annual interest rate. If
                Rs.20,000 is deposited as FD in a bank for a rate of interest of
                8%, then the FD will double in 9 years (72/8 = 9).
              </li>
              <li>
                <span className={styles.boldText}>
                  Does FD offer compound interest?
                </span>
                <br />
                Whether the FD offers compound interest or not depends on the
                type of financial institution, FD amount, tenure, and FD type.
              </li>
              <li>
                <span className={styles.boldText}>
                  How much is the FD interest rate for senior citizens?
                </span>
                <br />
                The exact FD interest rate for FD depends on the bank policy as
                the rates vary from bank to bank. But generally, the FD rates
                for senior citizens (above 60 years of age) are around 0.25% to
                0.50% more than regular FD rates.
              </li>
              <li>
                <span className={styles.boldText}>
                  How does the FD calculator help in future financial planning?
                </span>
                <br />
                Once you know how much your Fixed Deposit will fetch you after
                the tenure ends, you can either choose to reinvest or withdraw
                the amount.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeHub;
