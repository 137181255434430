import React from "react";

const Table = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Year</th>
          <th>Total Investment</th>
          <th>Interest Earned</th>
          <th>Total Amount</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.year}</td>
            <td>
              {row.totalInvestment.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })}
            </td>
            <td>
              {row.interestEarned.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })}
            </td>
            <td>
              {row.totalAmount.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
