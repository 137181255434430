import React, { useEffect } from "react";
import styles from "./InvestmentDetails.module.css";
import Slider from "../Slider/Slider";
import DoughnutChart from "../Doughnut/Doughnut";
import { calculateRDMaturity } from "../utils/calculateInvestment";
import redirectTo from "../../../../utils/RedirectTo";

const InvestmentDetails = ({
  setInvestmentAmount,
  setExpectedReturns,
  setInvestmentYears,
  setTotalAmount,
  setInterestEarned,
  setTotalInvestment,
  investmentAmount,
  expectedReturns,
  investmentYears,
  totalAmount,
  interestEarned,
  totalInvestment,
}) => {
  useEffect(() => {
    const totalMaturity = calculateRDMaturity(
      investmentAmount,
      expectedReturns,
      investmentYears
    );
    const totalInvested = investmentAmount * investmentYears * 12;
    setTotalAmount(totalMaturity);
    setTotalInvestment(totalInvested);
    setInterestEarned(totalMaturity - totalInvested);
  }, [investmentAmount, expectedReturns, investmentYears]);

  const data = {
    datasets: [
      {
        data: [totalInvestment, interestEarned],
        backgroundColor: ["#41558D", "#82B770"],
        rotation: 90,
      },
    ],
  };

  return (
    <div className={styles.container}>
      <div className={styles.margin_container}>
        <div className={styles.inner_container1}>
          <h1>Investment Details</h1>
          <div>
            <div>
              <p style={{ marginTop: "50px" }} className={styles.sliderTitle}>
                Monthly investment amount
              </p>
              <Slider
                endRange={1000000}
                startRange={500}
                price
                step={100}
                onChange={setInvestmentAmount}
              />
            </div>

            <div style={{ margin: "24px 0px" }}>
              <p className={styles.sliderTitle}>Expected returns</p>
              <Slider
                endRange={20}
                startRange={1}
                percentage
                step={1}
                onChange={setExpectedReturns}
              />
            </div>

            <div>
              <p className={styles.sliderTitle}>Investment period</p>
              <Slider
                endRange={50}
                startRange={1}
                year
                step={1}
                onChange={setInvestmentYears}
              />
            </div>
          </div>

          <div className={styles.consistency} style={{ marginTop: "24px" }}>
            <p className={styles.spanContent}>
              A <span>monthly sip</span> of{" "}
              <span>
                {investmentAmount.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })}
              </span>{" "}
              over
              <span> {investmentYears} years</span>, at an{" "}
              <span>{expectedReturns}%</span> rate of return would yield a total
              amount of over{" "}
              <span>
                {totalAmount.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })}
              </span>
            </p>
          </div>
        </div>

        <div className={styles.inner_container2}>
          <div className={styles.graph_container}>
            <h1>Investment Breakdown</h1>

            <div className={styles.donut}>
              <DoughnutChart data={data} />
            </div>

            <div className={styles.legend}>
              <div className={styles.l1}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend1}></div>
                  <p className={styles.legendTitle}>Total investment</p>
                </div>
                <p className={styles.legendPrice}>
                  {totalInvestment.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </div>

              <div className={styles.l2}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend2}></div>
                  <p className={styles.legendTitle}>Interest earned</p>
                </div>
                <p className={styles.legendPrice}>
                  {interestEarned.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
              </div>
            </div>

            <hr className={styles.horizontalRule} />

            <div className={styles.FinalAmount}>
              <p className={styles.FinalAmountTitle}>Final amount</p>
              <p className={styles.legendPrice}>
                {totalAmount.toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })}
              </p>
            </div>
          </div>

          <button
            className={styles.startInvesting}
            onClick={() => redirectTo()}
          >
            <span className={styles.btnContent}>Start investing</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetails;
