import React from "react";
import styles from "./table.module.css";

const Table = ({ data }) => {
  console.log(data, "dsdsfd");
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Year</th>
          <th>Total Investment</th>
          <th>Interest Earned</th>
          <th>Total Value</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((row, index) => (
          <tr key={index}>
            <td>{row.year}</td>
            <td>{row.totalInvestment}</td>
            <td>{row.interestEarned}</td>
            <td>{row.finalAmount}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
