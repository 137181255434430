import React, { useState, useEffect } from "react";
import styles from "./DetailedBreakDown.module.css";
import { CircleMinus, CirclePlus } from "lucide-react";
import StackedBarGraph from "../StackedBarGraph/StackedBarGraph";
import Table from "../Table/Table";

const DetailGraph = ({
  investmentAmount,
  expectedReturns,
  investmentYears,
  compoundingFrequency = 1, // compounding annually by default
}) => {
  const [toggle, setToggle] = useState(true);
  const [graphData, setGraphData] = useState({});
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const generateData = () => {
      const labels = [];
      const totalInvestmentData = [];
      const interestEarnedData = [];
      const currentYear = new Date().getFullYear();
      const tableRows = [];

      for (let year = 1; year <= investmentYears; year++) {
        labels.push(currentYear + year - 1);
        const totalAmount =
          investmentAmount *
          Math.pow(
            1 + expectedReturns / (compoundingFrequency * 100),
            compoundingFrequency * year
          );
        const interestEarned = totalAmount - investmentAmount;

        totalInvestmentData.push(investmentAmount);
        interestEarnedData.push(interestEarned);

        tableRows.push({
          year: currentYear + year - 1,
          totalInvestment: investmentAmount.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          }),
          interestEarned: interestEarned.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          }),
          totalValue: totalAmount.toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            style: "currency",
            currency: "INR",
          }),
        });
      }

      setGraphData({
        labels: labels,
        datasets: [
          {
            label: "Principal Amount",
            data: totalInvestmentData,
            backgroundColor: "#41558d",
          },
          {
            label: "Interest Earned",
            data: interestEarnedData,
            backgroundColor: "#82b770",
          },
        ],
      });

      setTableData(tableRows);
    };

    generateData();
  }, [
    investmentAmount,
    expectedReturns,
    investmentYears,
    compoundingFrequency,
  ]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        min: 0,
        ticks: {
          stepSize: 10,
          callback: function (value) {
            return value.toLocaleString("en-IN", {
              maximumFractionDigits: 2,
            });
          },
        },
      },
    },
  };

  return (
    <div className={styles.DetailedBreakdownContainer}>
      <div>
        {toggle ? (
          <CircleMinus onClick={() => setToggle(!toggle)} />
        ) : (
          <CirclePlus onClick={() => setToggle(!toggle)} />
        )}
        <p className={styles.Div2Title}>Detailed Investment Breakdown</p>
      </div>

      {toggle && (
        <>
          <div className={styles.graphDiv}>
            <div className={styles.whatInGraph}>
              <div>
                <div className={styles.colorBox1}></div>
                <p>Principal Amount</p>
              </div>

              <div>
                <div className={styles.colorBox2}></div>
                <p>Interest Earned</p>
              </div>
            </div>
            <div style={{ marginTop: "48px" }}>
              {graphData.labels && (
                <StackedBarGraph data={graphData} options={options} />
              )}
            </div>
          </div>

          <div
            style={{
              marginTop: "48px",
              maxHeight: "262px",
              overflowY: "scroll",
            }}
          >
            {tableData.length > 0 && <Table data={tableData} />}
          </div>
        </>
      )}
    </div>
  );
};

export default DetailGraph;
