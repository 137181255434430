import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./module.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Table from "./Table/Table";
import clevertap from "clevertap-web-sdk";
import useMediaQuery from "../hooks/useMediaQuery";

function ContactUs() {
  const recaptcha = useRef();
  const isMobile = useMediaQuery('(max-width: "676px")');
  const location = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [cooldown, setCooldown] = useState(false);

  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    if (cooldown) {
      // Start the cooldown timer
      const cooldownTimer = setTimeout(() => {
        setCooldown(false);
      }, 10000); // 1 Minute cooldown

      // Clear the timer when the component unmounts or cooldown is reset
      return () => clearTimeout(cooldownTimer);
    }
  }, [cooldown]);

  useEffect(() => {
    clevertap.event.push("Page Viewed", {
      Page_name: "ContactUs Page",
    });
  }, []);

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      const res = await fetch(
        "https://devapi.fabits.com/customerservice/api/captcha/verify",
        {
          method: "POST",
          body: JSON.stringify({ captchaValue }),
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const data = await res.json();

      if (data.success) {
        // Prevent submission during cooldown period
        if (cooldown) {
          return;
        }

        emailjs
          .sendForm("service_1znzuh7", "template_wq66geb", form.current, {
            publicKey: "Tkc_DuArwUPCZZqsR",
          })
          .then(
            () => {
              console.log("SUCCESS!");
              setSubmitted(true);
              setCooldown(true);

              // Reset the form after 10 seconds
              setTimeout(() => {
                setSubmitted(false);
                setCooldown(false);
                form.current.reset();
                recaptcha.current.reset();
              }, 100000); // 1 Minute cooldown
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
      } else {
        alert("reCAPTCHA validation failed!");
      }
    }
  };

  return (
    <>
      <div className="contact">
        <Helmet>
          <title>Fabits - Contact Us</title>
          <meta
            name="description"
            content="Contact Fabits for support via chat, call, or scheduled call; we're here to help with all your financial planning needs."
          />
        </Helmet>
        <h2 id="wish">Wish to Speak with Us?</h2>
        <p id="belowwish">We are always happy to help you!</p>
        <img src="/ContactUs/Group 32.svg" alt="Group 32" id="group39" />
        <img
          src="/ContactUs/righttopmobile.svg"
          alt="righttopmobile"
          id="righttopmobile"
        />
        <img src="/ContactUs/leftmiddle.svg" alt="leftmiddle" id="leftmiddle" />
        <img
          src="/ContactUs/rightbottem.svg"
          alt="rightbottem"
          id="rightbottem"
        />
        <img src="/ContactUs/Frame.svg" alt="frame" id="frame1" />
        <div className="buttons">
          <div id="chatwithus">
            <img src="/ContactUs/Chatwithus.svg" alt="chat" id="chat" />
            <div id="chatwithusdiv">
              <a
                href="https://wa.me/+8217801975?text=Hi!+I+am+looking+to+get+some+help+regarding+Fabits."
                id="chatlink"
                onClick={() => {
                  clevertap.event.push("Clicked Button", {
                    Action: "Chat with us",
                    Page_name: "Contact us page",
                  });
                }}
              >
                {/* LINK TO CHAT WITH US */}
                <h3>Chat with us </h3>
                <img
                  src="/ContactUs/arrowlink.svg"
                  alt="arrow"
                  id="arrowlink"
                />
              </a>
              <p id="sec">Est. wait time 100 secs.</p>
            </div>
          </div>

          <div id="call">
            <img src="/ContactUs/Callicon.svg" alt="call" id="callicon" />
            <div id="calldiv">
              {/* LINK TO call link */}
              <a href="tel:+918217801975" id="calllink">
                <h3>Call Us</h3>
                <img
                  src="/ContactUs/arrowlink.svg"
                  alt="arrow"
                  id="arrowlink"
                />
              </a>
              <p id="mins">Est. wait time 3 mins.</p>
            </div>
          </div>

          <div id="shedulediv">
            <img src="/ContactUs/shedule.svg" alt="shedule" id="shedule" />
            {/* LINK TO shedule a call link */}
            <div id="sheduledivin">
              <a
                href="https://calendly.com/fabits"
                id="shedulelink"
                onClick={() => {
                  clevertap.event.push("Clicked Button", {
                    Action: "Schedule a call",
                    Page_name: "ContactUs Page",
                  });
                }}
              >
                <h3>Schedule a call</h3>
                <img
                  src="/ContactUs/arrowlink.svg"
                  alt="arrow"
                  id="arrowlink"
                />
              </a>
              <p>Est. wait time 24 working hrs.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="contactus">
        <img src="/ContactUs/Vector.png" alt="vector" id="vector" />
        <img src="/ContactUs/Vectorleft.svg" alt="vectorleft" id="vectorleft" />
        <h3>Got more questions?</h3>
        <p>Get in touch with us today!</p>
        <div className="contactusform">
          <form id="contactusforminner" onSubmit={sendEmail} ref={form}>
            <div className="form1and2">
              <div className="form1">
                <label htmlFor="name" id="namelabel">
                  Full name
                </label>
                <input
                  type="text"
                  id="name"
                  name="from_name"
                  maxLength={100}
                  placeholder="e.g.Surya Karthik"
                />
              </div>
              <div className="form2">
                <label htmlFor="email" id="emaillabel">
                  Email address*
                </label>
                <br />
                <input
                  required
                  type="email"
                  id="email"
                  maxLength={50}
                  name="from_email"
                  placeholder="e.g.G.Suryakarthik@mail.com"
                />
              </div>
            </div>
            <div className="form3">
              <div className="phonenumberformcontactus">
                <label htmlFor="query" id="querylabel">
                  What is your query regarding?
                </label>
                <br />
                <select name="query" id="query">
                  <option value="">Select a reason</option>
                  <option value="general">General</option>
                  <option value="payment">Payment</option>
                  <option value="technical">Technical</option>
                  <option value="others">Others</option>
                </select>
              </div>
              <div className="phonenumberformcontactus">
                <label htmlFor="phone" id="emaillabel">
                  Phone number
                </label>
                <br />
                <input
                  type="tel"
                  id="email"
                  name="phone_number"
                  maxLength={10}
                  placeholder="e.g.9876543210"
                />
              </div>
            </div>
            <div className="form4">
              <label htmlFor="message" id="describelabel">
                Describe your query
              </label>
              <br />
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                maxLength={600}
                placeholder="Query description..."
              ></textarea>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={`6LdJW-spAAAAANJ9T03r-2mv0bOy1dTL05_Wm3rh`}
              />
            </div>

            <button
              id="submit"
              type="submit"
              value="Send"
              disabled={submitted || cooldown}
              className={submitted ? "submitted" : ""}
              onClick={() => {
                clevertap.event.push("Clicked Button", {
                  Action: "Submit Query",
                  Page_name: "Contact us page",
                });
              }}
            >
              {submitted
                ? "Your query is submitted Please wait 1 Minute"
                : "Submit Query"}
            </button>
          </form>
        </div>
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", margin: "20px" }}
      >
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "10px",
            width: `${isMobile ? "300px" : "500px"}`,
            textAlign: "center",
            padding: "30px",
          }}
        >
          <h1>Company Timings</h1>

          <p style={{ fontWeight: "700" }}>
            Days : <span style={{ fontWeight: "400" }}>Mon - Fri</span>
          </p>
          <p style={{ fontWeight: "700" }}>
            Timings :
            <span style={{ fontWeight: "400" }}> 09:00 am - 06:00 pm</span>
          </p>
        </div>
      </div>

      <Table />

      <p
        style={{
          // width: "100vw",
          textAlign: "center",
          margin: "0px 20px 20px 20px",
        }}
      >
        In absence of response/complaint not addressed to your satisfaction, you
        may lodge a complaint with&nbsp;
        <a href="https://www.sebi.gov.in/" target="_blank" rel="noreferrer">
          SEBI
        </a>
        &nbsp;or Exchange – &nbsp;
        <a
          href="https://investorhelpline.nseindia.com/NICEPLUS/"
          target="_blank"
          rel="noreferrer"
        >
          NSE
        </a>
        &nbsp;|&nbsp;
        <a
          href="https://www.cdslindia.com/Footer/grievances.aspx"
          target="_blank"
          rel="noreferrer"
        >
          CDSL
        </a>
        &nbsp;Please quote your Service Ticket/Complaint Ref No. while raising
        your complaint at SEBI SCORES/Exchange portal.
      </p>
    </>
  );
}

export default ContactUs;
