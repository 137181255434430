import React, { useState } from "react";
import "./accordian.css";

const Accordion = ({ Data, sm }) => {
  const [activeTab, setActiveTab] = useState("");

  const openFY = (year) => {
    setActiveTab(year);
  };

  return (
    <div className="tab">
      <div className="tabBtn">
        {Data?.map((tab) => (
          <button
            key={tab.id}
            className={`tablinks ${activeTab === tab.id ? "active" : ""}`}
            onClick={() => openFY(tab.id)}
            style={sm && {width: "30%"}}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div>
        {Data?.map((tab) => {
          return (
            <div
              key={tab.id}
              id={tab.id}
              className={`tabcontent ${activeTab === tab.id ? "active" : ""}`}
            >
              <p>{tab.content}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Accordion;
