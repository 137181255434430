import React from "react";
import styles from "./calculator.module.css";
import { useNavigate } from "react-router-dom";

// Import your SVG images
import SipIcon from "../../assets/calculator/SIP.svg";
import NpsIcon from "../../assets/calculator/NPS.svg";
import PpfIcon from "../../assets/calculator/PPF.svg";
import FdIcon from "../../assets/calculator/FD.svg";
import CiIcon from "../../assets/calculator/CI.svg";
import SiIcon from "../../assets/calculator/SI.svg";
import RdIcon from "../../assets/calculator/RD.svg";
import MfIcon from "../../assets/calculator/MF.svg";
import EmiIcon from "../../assets/calculator/EMI.svg";

const calculators = [
  {
    id: 1,
    title: "SIP Calculator",
    to: "/calculator/sip",
    icon: SipIcon,
    description: (
      <p>
        Calculate your potential returns and plan your investments effectively
        with our easy-to-use SIP calculator
      </p>
    ),
  },
  {
    id: 2,
    title: "NPS Calculator",
    to: "/calculator/nps",
    icon: NpsIcon,
    description: (
      <p>
        Estimate your retirement corpus and plan your financial future with our
        extensive NPS calculator
      </p>
    ),
  },
  {
    id: 4,
    title: "PPF Calculator",
    to: "/calculator/ppf",
    icon: PpfIcon,
    description: (
      <p>
        Calculate your potential returns and track your savings growth with our
        user-friendly PPF calculator
      </p>
    ),
  },
  {
    id: 6,
    title: "FD Calculator",
    to: "/calculator/fd",
    icon: FdIcon,
    description:
      "Determine your maturity amount and plan your investments with our efficient FD calculator",
  },
  {
    id: 7,
    title: "Compound Interest Calculator",
    to: "/calculator/compound-interest-calculator",
    icon: CiIcon,
    description: (
      <p>
        Estimate your potential returns and make informed decisions with our
        extensive mutual fund calculator
      </p>
    ),
  },
  {
    id: 9,
    title: "Simple Interest Calculator",
    to: "/calculator/simple-interest-calculator",
    icon: SiIcon,
    description: (
      <p>
        Quickly calculate simple interest and plan your finances with our
        straightforward simple interest calculator
      </p>
    ),
  },
  {
    id: 10,
    title: "RD Calculator",
    to: "/calculator/rd-calculator",
    icon: RdIcon,
    description: (
      <p>
        Plan your recurring deposits and forecast your returns effortlessly with
        our user-friendly RD calculator
      </p>
    ),
  },
  {
    id: 11,
    title: "Mutual Fund Calculator",
    to: "/calculator/mutual-fund-returns-calculator",
    icon: MfIcon,
    description: (
      <p>
        Estimate your potential returns and make informed decisions with our
        extensive mutual fund calculator
      </p>
    ),
  },
  {
    id: 12,
    title: "EMI Calculator",
    to: "/calculator/emi-calculator",
    icon: EmiIcon,
    description: (
      <p>
        Calculate your monthly EMI payments and manage your loans effectively
        with our easy-to-use EMI calculator
      </p>
    ),
  },
];

const Calculator = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      {calculators.map((calc) => {
        const { id, title, to, icon, description } = calc;

        return (
          <div onClick={() => navigate(to)} key={id} className={styles.card}>
            <div>
              <p>{title}</p>
              <p>{description}</p>
            </div>
            <div>
              <img
                src={icon}
                alt={title}
                className={styles.icon}
                height={100}
                width={100}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Calculator;
