import React from "react";
import styles from "./InvestmentDetails.module.css";
import Tabs from "../Tabs/Tabs";
import Slider from "../Slider/Slider";
import DoughnutChart from "../Doughnut/Doughnut";
import redirectTo from "../../../../utils/RedirectTo";

const InvestmentDetails = ({
  setInvestmentAmount,
  setExpectedReturns,
  setInvestmentYears,
  investmentAmount,
  expectedReturns,
  investmentYears,
  setActiveTab,
  activeTab,
}) => {
  const calculateCompoundInterest = (P, i, n) => {
    return P * ((Math.pow(1 + i, n) - 1) / i) * (1 + i);
  };

  const yearlyInterest = expectedReturns / 100;
  const investedMonths = investmentYears * 12;

  // Total amount based on Monthly SIP
  const totalAmountSIP = calculateCompoundInterest(
    investmentAmount,
    yearlyInterest / 12,
    investedMonths
  );
  const interestEarnedSIP = totalAmountSIP - investmentAmount * investedMonths;

  // Total amount based on Lumpsum
  const totalAmountLumpsum =
    investmentAmount * Math.pow(1 + yearlyInterest, investmentYears);
  const interestEarnedLumpsum = totalAmountLumpsum - investmentAmount;

  // Determine values based on the selected tab
  const totalAmount = isNaN(
    activeTab === 1 ? totalAmountSIP : totalAmountLumpsum
  )
    ? 0
    : activeTab === 1
    ? totalAmountSIP
    : totalAmountLumpsum;

  const interestEarned = isNaN(
    activeTab === 1 ? interestEarnedSIP : interestEarnedLumpsum
  )
    ? 0
    : activeTab === 1
    ? interestEarnedSIP
    : interestEarnedLumpsum;

  // Format numbers with Indian commas and two decimal places
  const formattedInterestEarned = interestEarned.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  });
  const finalAmount = totalAmount.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  });

  const data = {
    datasets: [
      {
        data: [
          activeTab === 1
            ? investmentAmount * investedMonths
            : investmentAmount,
          interestEarned,
        ],
        backgroundColor: ["#41558D", "#82B770"],
      },
    ],
  };

  const tabs = [
    {
      id: 1,
      name: "Monthly SIP",
      content: (
        <div>
          <div>
            <p className={styles.sliderTitle}>Monthly investment amount</p>
            <Slider
              endRange={200000}
              startRange={100}
              price
              step={100}
              onChange={setInvestmentAmount}
            />
          </div>

          <div style={{ margin: "24px 0px" }}>
            <p className={styles.sliderTitle}>Expected returns</p>
            <Slider
              endRange={20}
              startRange={0}
              percentage
              step={1}
              onChange={setExpectedReturns}
            />
          </div>

          <div>
            <p className={styles.sliderTitle}>Investment period</p>
            <Slider
              endRange={50}
              startRange={1}
              year
              step={1}
              onChange={setInvestmentYears}
            />
          </div>
        </div>
      ),
    },
    {
      id: 2,
      name: "Lumpsum",
      content: (
        <div>
          <div>
            <p className={styles.sliderTitle}>Lumpsum amount</p>
            <Slider
              endRange={200000}
              startRange={100}
              price
              step={100}
              onChange={setInvestmentAmount}
            />
          </div>

          <div style={{ margin: "24px 0px" }}>
            <p className={styles.sliderTitle}>Expected returns</p>
            <Slider
              endRange={20}
              startRange={0}
              percentage
              step={1}
              onChange={setExpectedReturns}
            />
          </div>

          <div>
            <p className={styles.sliderTitle}>Investment period</p>
            <Slider
              endRange={50}
              startRange={1}
              year
              step={1}
              onChange={setInvestmentYears}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.margin_container}>
        <div className={styles.inner_container1}>
          <h1>Investment Details</h1>
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

          <div className={styles.consistency} style={{ marginTop: "24px" }}>
            <p className={styles.spanContent}>
              A{" "}
              <span>{activeTab === 1 ? "monthly SIP" : "Lumpsum Amount"}</span>{" "}
              of{" "}
              <span>
                {Number(investmentAmount).toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "INR",
                })}
              </span>{" "}
              over
              <span> {investmentYears} years</span>, at an{" "}
              <span>{expectedReturns} %</span> rate of return would yield a
              total amount of <span>{finalAmount}</span>
            </p>
          </div>
        </div>

        <div className={styles.inner_container2}>
          <div className={styles.graph_container}>
            <h1>Investment Breakdown</h1>

            <div className={styles.donut}>
              <DoughnutChart data={data} />
            </div>

            <div className={styles.legend}>
              <div className={styles.l1}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend1}></div>
                  <p className={styles.legendTitle}>Total investment</p>
                </div>
                <p className={styles.legendPrice}>
                  {activeTab === 1
                    ? (investmentAmount * investedMonths).toLocaleString(
                        "en-IN",
                        {
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "INR",
                        }
                      )
                    : investmentAmount.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                </p>
              </div>

              <div className={styles.l2}>
                <div className={styles.l1_left}>
                  <div className={styles.colorLegend2}></div>
                  <p className={styles.legendTitle}>Interest earned</p>
                </div>
                <p className={styles.legendPrice}>{formattedInterestEarned}</p>
              </div>
            </div>

            <hr className={styles.horizontalRule} />

            <div className={styles.FinalAmount}>
              <p className={styles.FinalAmountTitle}>Final amount</p>
              <p className={styles.legendPrice}>{finalAmount}</p>
            </div>
          </div>

          <button
            className={styles.startInvesting}
            onClick={() => redirectTo()}
          >
            <span className={styles.btnContent}>Start investing </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvestmentDetails;
